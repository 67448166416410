:root {
  --blue: #092ace;
  --indigo: #5c51e0;
  --purple: #9b51e0;
  --pink: #ff4081;
  --red: #f44336;
  --orange: #ff5a2c;
  --yellow: #f2c94c;
  --green: #27ae60;
  --teal: #00c9a7;
  --cyan: #00c9db;
  --white: #ffffff;
  --gray: #8c98a4;
  --gray-dark: #71869d;
  --primary: #092ace;
  --secondary: #71869d;
  --success: #27ae60;
  --info: #9b51e0;
  --warning: #ff5a2c;
  --danger: #f44336;
  --light: #f9fafc;
  --dark: #111b51;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --menu-width: 6.5rem;
  --cbBorderColor: rgb(228 228 231/ 1);
  --cbTextBlack: rgb(9 9 11/1);
  --cbTextGray: rgb(82 82 91/1);
  --home-page-width: 1200px;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--black);
  text-align: left;
  background-color: #fff;
}
[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 600;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #092ace;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #061b85;
  text-decoration: none;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
pre {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #677788;
  text-align: left;
  caption-side: bottom;
}
th {
  font-weight: 400;
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role='button'] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  margin-left: 0%;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.4;
  color: #1e2022;
}
.h1,
h1 {
  font-size: 1.4109375rem;
}
@media (max-width: 1200px) {
  .h1,
  h1 {
    font-size: calc(1.26609375rem + 0.193125vw);
  }
}
.h2,
h2 {
  font-size: 1.3125rem;
}
@media (max-width: 1200px) {
  .h2,
  h2 {
    font-size: calc(1.25625rem + 0.075vw);
  }
}
.h3,
h3 {
  font-size: 1.1484375rem;
}
.h4,
h4 {
  font-size: 0.984375rem;
}
.h5,
h5 {
  font-size: 0.875rem;
}
.h6,
h6 {
  font-size: 0.765625rem;
}
.lead {
  font-size: 1.09375rem;
  font-weight: 400;
}
.display-3 {
  font-size: 3.0625rem;
  font-weight: 600;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.43125rem + 2.175vw);
  }
}
.display-4 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.3rem + 0.6vw);
  }
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 0.0625rem solid var(--cbBorderColor);
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.mark,
mark {
  padding: 0.2em;
  background-color: rgba(9, 42, 206, 0.1);
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 0.875rem;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
code {
  font-size: 87.5%;
  color: #ff4081;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #1e2022;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.container,
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.order-0 {
  -ms-flex-order: 0;
  order: 0;
}
.order-1 {
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -ms-flex-order: 4;
  order: 4;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 0.125rem solid rgba(231, 234, 243, 0.7);
}
.table tbody + tbody {
  border-top: 0.125rem solid rgba(231, 234, 243, 0.7);
}
.table-bordered {
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.table-bordered td,
.table-bordered th {
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 0.125rem;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(231, 234, 243, 0.4);
}
.table .thead-light th {
  color: #677788;
  background-color: #f8fafd;
  border-color: rgba(231, 234, 243, 0.7);
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  padding: 0.546875rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid var(--cbBorderColor);
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1e2022;
}
.form-control:focus {
  color: #1e2022;
  background-color: #fff;
  border-color: rgba(9, 42, 206, 0.4);
  outline: 0;
  box-shadow: 0 0 10px rgba(9, 42, 206, 0.1);
}
.form-control::-webkit-input-placeholder {
  color: #97a4af;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #97a4af;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #97a4af;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #97a4af;
  opacity: 1;
}
.form-control::placeholder {
  color: #97a4af;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa;
  opacity: 1;
}
input[type='date'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control,
input[type='time'].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.form-control:focus::-ms-value {
  color: #1e2022;
  background-color: #fff;
}
.col-form-label {
  padding-top: 0.609375rem;
  padding-bottom: 0.609375rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}
.form-control-lg {
  height: calc(1.5em + 1.875rem);
  padding: 0.875rem 1.09375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1.5rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  line-height: 1.6;
  color: #fff;
  background-color: #27ae60;
  border-radius: 0.3125rem;
}
.form-row > .col > .valid-tooltip,
.form-row > [class*='col-'] > .valid-tooltip {
  left: 5px;
}
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #27ae60;
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #27ae60;
  box-shadow: 0 0 0 0.2rem rgba(39, 174, 96, 0.25);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #27ae60;
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #27ae60;
  box-shadow: 0 0 0 0.2rem rgba(39, 174, 96, 0.25);
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #27ae60;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #27ae60;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: #36d278;
  background-color: #36d278;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(39, 174, 96, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #27ae60;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44336;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  line-height: 1.6;
  color: #fff;
  background-color: #f44336;
  border-radius: 0.3125rem;
}
.form-row > .col > .invalid-tooltip,
.form-row > [class*='col-'] > .invalid-tooltip {
  left: 5px;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #f44336;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #f44336;
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #f44336;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #f44336;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  border-color: #f77066;
  background-color: #f77066;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #f44336;
}
/* .btn {
  display: inline-block;
  font-weight: 400;
  color: #000;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.546875rem 0.875rem;
  font-size: 0.875rem;
  line-height: 1.6;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #000;
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 42, 206, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}*/
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #092ace;
  border-color: #092ace;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0723a9;
  border-color: #07209d;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #0723a9;
  border-color: #07209d;
  box-shadow: 0 0 0 0.2rem rgba(46, 74, 213, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #092ace;
  border-color: #092ace;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #07209d;
  border-color: #061e91;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 74, 213, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5f7389;
  border-color: #596d82;
}
.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5f7389;
  border-color: #596d82;
  box-shadow: 0 0 0 0.2rem rgba(134, 152, 172, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #596d82;
  border-color: #54667a;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 152, 172, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #27ae60;
  border-color: #27ae60;
}
.btn-success:hover {
  color: #fff;
  background-color: #208f4f;
  border-color: #1e8449;
}
.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #208f4f;
  border-color: #1e8449;
  box-shadow: 0 0 0 0.2rem rgba(71, 186, 120, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #27ae60;
  border-color: #27ae60;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e8449;
  border-color: #1b7a43;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 186, 120, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #9b51e0;
  border-color: #9b51e0;
}
.btn-info:hover {
  color: #fff;
  background-color: #8831da;
  border-color: #8226d8;
}
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #8831da;
  border-color: #8226d8;
  box-shadow: 0 0 0 0.2rem rgba(170, 107, 229, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #9b51e0;
  border-color: #9b51e0;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #8226d8;
  border-color: #7c24cd;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 107, 229, 0.5);
}
.btn-warning {
  color: #fff;
  background-color: #ff5a2c;
  border-color: #ff5a2c;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ff3c06;
  border-color: #f83600;
}
.btn-warning.focus,
.btn-warning:focus {
  color: #fff;
  background-color: #ff3c06;
  border-color: #f83600;
  box-shadow: 0 0 0 0.2rem rgba(255, 115, 76, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #ff5a2c;
  border-color: #ff5a2c;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f83600;
  border-color: #eb3300;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 115, 76, 0.5);
}
.btn-outline-primary {
  color: #092ace;
  border-color: #092ace;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #092ace;
  border-color: #092ace;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 42, 206, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #092ace;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: var(--secondaryColor) !important;
  border-color: var(--secondaryColor) !important;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 42, 206, 0.5);
}
.btn-outline-danger {
  color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f44336;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-light {
  color: #f9fafc;
  border-color: #f9fafc;
}
.btn-outline-light:hover {
  color: #1e2022;
  background-color: #f9fafc;
  border-color: #f9fafc;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f9fafc;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #1e2022;
  background-color: #f9fafc;
  border-color: #f9fafc;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5);
}
.btn-sm {
  padding: 0.4375rem 0.65625rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type='button'].btn-block,
input[type='reset'].btn-block,
input[type='submit'].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 98;
  display: none;
  float: left;
  min-width: 8.4375rem;
  padding: 1rem 0;
  margin: 0.625rem 0 0;
  font-size: 0.875rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.625rem;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.625rem;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.625rem;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--cbBorderColor);
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1e2022;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #121314;
  text-decoration: none;
  background-color: rgba(189, 197, 209, 0.3);
}
.dropdown-item.active,
.dropdown-item:active {
  color: #1e2022;
  text-decoration: none;
  background-color: rgba(189, 197, 209, 0.3);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #97a4af;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: #8c98a4;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: #1e2022;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -0.0625rem;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -0.0625rem;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-select,
.input-group > .form-control {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control {
  margin-left: -0.0625rem;
}
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -0.0625rem;
}
.input-group-prepend {
  margin-right: -0.0625rem;
}
.input-group-append {
  margin-left: -0.0625rem;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.546875rem 0.875rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 0.0625rem solid var(--cbBorderColor);
  border-radius: 0.3125rem;
}
.input-group-text input[type='checkbox'],
.input-group-text input[type='radio'] {
  margin-top: 0;
}
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.4rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.2rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #092ace;
  background-color: #092ace;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 10px rgba(9, 42, 206, 0.1);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(9, 42, 206, 0.4);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #092ace;
  border-color: #092ace;
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  color: #8c98a4;
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #f8fafd;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.2rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #d6dbeb solid 0.0625rem;
}
.custom-control-label::after {
  position: absolute;
  top: 0.2rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: 50%/50% 50% no-repeat;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='110' height='110' viewBox='0 0 110 110'%3e%3cpath fill='%23ffffff' d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1  l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #092ace;
  background-color: #092ace;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(9, 42, 206, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(9, 42, 206, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(9, 42, 206, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: 0.325rem;
  left: -2.125rem;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #d6dbeb;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(9, 42, 206, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  padding: 0.546875rem 1.875rem 0.546875rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right 0.5rem center/1rem 1rem;
  border: 0.0625rem solid var(--cbBorderColor);
  border-radius: 0.3125rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: rgba(9, 42, 206, 0.4);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 42, 206, 0.25);
}
.custom-select:focus::-ms-value {
  color: #1e2022;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 0.875rem;
  background-image: none;
}
.custom-select:disabled {
  color: #8c98a4;
  background-color: #f8fafd;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1e2022;
}
.custom-select-sm {
  height: calc(1.5em + 1rem);
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  padding-left: 0.65625rem;
  font-size: 0.8125rem;
}
.custom-control-label::before,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-select {
    transition: none;
  }
}
.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.75rem 0.75rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #8c98a4;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 0.0625rem solid var(--cbBorderColor);
}
.nav-tabs .nav-link {
  margin-bottom: -0.0625rem;
  border: 0.0625rem solid transparent;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.nav-tabs .nav-link.disabled {
  color: #8c98a4;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #092ace;
  background-color: transparent;
  border-color: var(--secondaryColor);
}
.nav-tabs .dropdown-menu {
  margin-top: -0.0625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.3125rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #092ace;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 1rem;
}
.navbar .container,
.navbar .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggler {
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: #8489a5;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: #8489a5;
  border-color: rgba(255, 255, 255, 0.1);
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid var(--cbBorderColor);
  border-radius: 0.75rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.6875rem;
  border-bottom-left-radius: 0.6875rem;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.3125rem;
}
.card-title {
  margin-bottom: 1.3125rem;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-header {
  padding: 1.3125rem 1.3125rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.card-header:first-child {
  border-radius: 0.6875rem 0.6875rem 0 0;
}
.card-footer {
  padding: 1.3125rem 1.3125rem;
  background-color: #fff;
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.card-footer:last-child {
  border-radius: 0 0 0.6875rem 0.6875rem;
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -0.0625rem;
}
.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.3125rem;
}
.breadcrumb .breadcrumb {
  margin-bottom: 0;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #677788;
  content: '/';
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #1e2022;
}
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3125rem;
  justify-content: flex-end;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: 0 solid var(--cbBorderColor);
}
.page-link:hover {
  z-index: 2;
  color: #092ace;
  text-decoration: none;
  background-color: rgba(9, 42, 206, 0.1);
  border-color: rgba(9, 42, 206, 0.1);
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 42, 206, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #092ace;
  border-color: #092ace;
}
.page-item.disabled .page-link {
  color: #8c98a4;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: var(--cbBorderColor);
}
.badge {
  display: inline-block;
  padding: 0.3125em 0.5em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:focus,
a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #092ace;
}
a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #07209d;
}
a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 42, 206, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #71869d;
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #596d82;
}
a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(113, 134, 157, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #27ae60;
}
a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e8449;
}
a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(39, 174, 96, 0.5);
}
.badge-warning {
  color: #fff;
  background-color: #ff5a2c;
}
a.badge-warning:focus,
a.badge-warning:hover {
  color: #fff;
  background-color: #f83600;
}
a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 44, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #f44336;
}
a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #ea1c0d;
}
a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
  position: fixed;
  right: 50px;
  top: 80px;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 0.5rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 0.5rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: var(--cbBorderColor);
  border-radius: 0.3125rem;
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #092ace;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media-body {
  -ms-flex: 1;
  flex: 1;
}
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.3125rem;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 0.0625rem solid var(--cbBorderColor);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #8c98a4;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #092ace;
  border-color: #092ace;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -0.0625rem;
  border-top-width: 0.0625rem;
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 0.0625rem;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.close {
  float: right;
  font-size: 1.09375rem;
  font-weight: 600;
  line-height: 1;
  color: #71869d;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #71869d;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid var(--cbBorderColor);
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075);
  opacity: 0;
  border-radius: 0.75rem;
}
.toast:not(:last-child) {
  margin-bottom: 1rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: '';
}
.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.75rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  width: 100vw;
  height: 100vh;
  background-color: rgba(17, 27, 81, 0.25);
}
body:has(.confirmation-modal) .modal-backdrop {
  z-index: 99999 !important;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 1;
}
.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 0.0625rem solid var(--cbBorderColor);
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}
.modal-header .close {
  padding: 1rem 1.5rem;
  margin: -1rem -1.5rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem;
}
.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 0.0625rem solid var(--cbBorderColor);
  border-bottom-right-radius: 0.6875rem;
  border-bottom-left-radius: 0.6875rem;
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 9999;
  display: block;
  margin: 0;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^='top'],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^='top'] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #111b51;
}
.bs-tooltip-auto[x-placement^='right'],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #111b51;
}
.bs-tooltip-auto[x-placement^='bottom'],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #111b51;
}
.bs-tooltip-auto[x-placement^='left'],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^='left'] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #111b51;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #111b51;
  border-radius: 0.3125rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.75rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.75rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}
.popover-header {
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #1e2022;
  background-color: #f8fafd;
  border-bottom: 0.0625rem solid #e4ecf7;
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 1.5rem 1.5rem;
  color: #000;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
/*
.bg-primary {
  background-color: #092ace !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #07209d !important;
}
.bg-success {
  background-color: #27ae60 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e8449 !important;
}
.bg-info {
  background-color: #9b51e0 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #8226d8 !important;
}
.bg-warning {
  background-color: #ff5a2c !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #f83600 !important;
}
.bg-danger {
  background-color: #f44336 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #ea1c0d !important;
}
.bg-light {
  background-color: #f9fafc !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #d7deeb !important;
}
.bg-dark {
  background-color: #111b51 !important;
}
*/
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #080d27 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 0.0625rem solid var(--cbBorderColor) !important;
}
.border-left {
  border-left: 0.0625rem solid var(--cbBorderColor) !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-danger {
  border-color: #f44336 !important;
}
.rounded {
  border-radius: 0.3125rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-full {
  border-radius: 50rem !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.d-none {
  display: none !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
}
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
@media (min-width: 576px) {
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.shadow {
  box-shadow: 0 6px 24px 0 rgba(140, 152, 164, 0.125) !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-60 {
  width: 60% !important;
}
.w-15 {
  width: 15% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-65 {
  width: 65% !important;
}
.h-100 {
  height: 100% !important;
}
.h-unset {
  height: unset !important;
}
.h-10 {
  height: 10% !important;
}
.h-20 {
  height: 20% !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
/* .mt-3,
.my-3 {
  margin-top: 1rem !important;
} */
.mr-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.mt-5,
.my-5 {
  margin-top: 2rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}
.mt-6 {
  margin-top: 2.5rem !important;
}
.mb-6 {
  margin-bottom: 2.5rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
/* .pl-0,
.px-0 {
  padding-left: 0 !important;
} */
.p-1 {
  padding: 0.25rem !important;
}
.px-1 {
  padding-right: 0.25rem !important;
}
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.p-5 {
  padding: 2rem !important;
}
.pt-5,
.py-5 {
  padding-top: 2rem !important;
}
.px-5 {
  padding-right: 2rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}
.px-5 {
  padding-left: 2rem !important;
}
.py-6 {
  padding-top: 2.5rem !important;
}
.px-6 {
  padding-right: 2.5rem !important;
}
.py-6 {
  padding-bottom: 2.5rem !important;
}
.px-6 {
  padding-left: 2.5rem !important;
}
.py-7 {
  padding-top: 3rem !important;
}
.py-7 {
  padding-bottom: 3rem !important;
}
.p-8 {
  padding: 3.5rem !important;
}
.pt-8 {
  padding-top: 3.5rem !important;
}
.mt-n1 {
  margin-top: -0.25rem !important;
}
.mb-n2 {
  margin-bottom: -0.5rem !important;
}
.mt-n5 {
  margin-top: -2rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .py-sm-10 {
    padding-top: 4.5rem !important;
  }
  .py-sm-10 {
    padding-bottom: 4.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .mr-md-3 {
    margin-right: 1rem !important;
  }
  .mt-md-5 {
    margin-top: 2rem !important;
  }
  .ml-md-5 {
    margin-left: 2rem !important;
  }
  .mt-md-6 {
    margin-top: 2.5rem !important;
  }
  .mt-md-7 {
    margin-top: 3rem !important;
  }
  .mt-md-10 {
    margin-top: 4.5rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .py-md-6 {
    padding-top: 2.5rem !important;
  }
  .px-md-6 {
    padding-right: 2.5rem !important;
  }
  .py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .px-md-6 {
    padding-left: 2.5rem !important;
  }
  .px-md-8 {
    padding-right: 3.5rem !important;
  }
  .px-md-8 {
    padding-left: 3.5rem !important;
  }
}
@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}
.text-wrap {
  white-space: normal !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
.text-uppercase {
  text-transform: uppercase !important;
}
.font-weight-bold {
  font-weight: 600 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #092ace !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #061b85 !important;
}
.text-secondary {
  color: var(--secondaryColor) !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #4f6072 !important;
}
.text-success {
  color: var(--wonDarkColor) !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #19703e !important;
}
.text-danger {
  color: #f44336 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #d2190b !important;
}
.text-dark {
  color: #111b51 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #040612 !important;
}
.text-body {
  color: #000 !important;
}
.text-muted {
  color: var(--cbTextGray) !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 0.0625rem solid #97a4af;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 0.0625rem solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid var(--cbBorderColor) !important;
  }
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-60 {
  width: 60% !important;
}
.w-15 {
  width: 15% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-65 {
  width: 65% !important;
}
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-60 {
    width: 60% !important;
  }
}
html {
  position: relative;
  min-height: 100%;
}
main {
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e2022;
}
figure {
  margin-bottom: 0;
}
sup {
  right: -0.5rem;
}
dt {
  color: #1e2022;
}
dd {
  margin-bottom: 0.75rem;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
::selection {
  color: #fff;
  background-color: #092ace;
}
.bg-primary ::-moz-selection {
  color: #092ace;
  background-color: #fff;
}
.bg-primary ::selection {
  color: #092ace;
  background-color: #fff;
}
a:focus,
button:focus {
  outline: 0;
}
.form-control:focus {
  box-shadow: 0 0 0 0 transparent;
}
.btn.focus,
.btn:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not([disabled]):not(.disabled).active,
.btn:not([disabled]):not(.disabled):active {
  box-shadow: 0 0 0 0 transparent;
}
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show > .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent;
}
.custom-select:focus,
.page-link:focus {
  box-shadow: 0 0 0 0 transparent;
}
.custom-control-input:active ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 transparent;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.navbar {
  background-color: #fff;
}
.navbar-nav-wrap-content-left .navbar-nav > .nav-item:not(:last-child),
.navbar-nav-wrap-content-right .navbar-nav > .nav-item:not(:last-child) {
  margin-right: 0.5rem;
}
.navbar:not(.navbar-vertical).nav-item:not(:last-child) {
  margin-right: 0.5rem;
}
.navbar .nav-link {
  color: #111b51;
}
.navbar .nav-link:hover {
  color: #061b85;
}
.navbar .nav-link.active {
  color: #092ace;
}
.navbar .active > .nav-link,
.navbar .nav-link.active,
.navbar .nav-link.show,
.navbar .show > .nav-link {
  color: #092ace;
}
.navbar .nav-pills .show > .nav-link {
  color: #fff;
}
.navbar:not(.navbar-vertical) .nav-link-toggle::after,
.navbar:not(.navbar-vertical) .nav-link-toggle[data-toggle='collapse']::after {
  padding-left: 1.5rem;
}
.navbar .navbar-dropdown-menu .dropdown-menu:not(.navbar-dropdown-sub-menu) {
  position: absolute;
}
.navbar .dropdown-menu.hs-mega-menu {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar .hs-sub-menu.dropdown-menu .dropdown-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99 !important;
}
.navbar-fixed ~ .main {
  padding-top: 3.75rem;
}
.navbar-flush .navbar {
  padding-right: 1rem;
}
.navbar-bordered {
  border-width: 0 0 0.0625rem 0;
  border-style: solid;
  border-color: var(--cbBorderColor);
}
.navbar-bordered.navbar-vertical {
  border-width: 0 0.0625rem 0 0;
}
.navbar-brand {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-brand-logo {
  width: 100%;
  min-width: 8rem;
  max-width: 8rem;
  margin-right: 1rem;
}
.navbar-brand-wrapper {
  position: relative;
  overflow-x: hidden;
}
.navbar-toggler {
  background-color: #f8fafd;
  border-color: transparent;
}
.navbar-toggler:hover {
  background-color: #f0f4fb;
}
.navbar-toggler.btn-white {
  border-color: var(--cbBorderColor);
}
.navbar-toggler.btn-white.active,
.navbar-toggler.btn-white:active,
.navbar-toggler.btn-white:focus,
.navbar-toggler.btn-white:hover {
  color: #061b85;
  background-color: var(--setting-menu-color);
  /*box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);*/
}
.navbar-toggler .navbar-toggle-default {
  display: -ms-flexbox;
  display: flex;
}
.navbar-toggler .navbar-toggle-toggled {
  display: none;
}
.navbar-toggler[aria-expanded='true'] .navbar-toggle-default {
  display: none;
}
.navbar-toggler[aria-expanded='true'] .navbar-toggle-toggled {
  display: -ms-flexbox;
  display: flex;
}
.navbar-collapse .navbar-nav {
  margin-top: 0;
}
.navbar-input-group {
  min-width: 21rem;
}
.navbar-input-group input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.navbar-input-group input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.navbar-input-group input[type='search']::-webkit-search-cancel-button,
.navbar-input-group input[type='search']::-webkit-search-decoration,
.navbar-input-group input[type='search']::-webkit-search-results-button,
.navbar-input-group input[type='search']::-webkit-search-results-decoration {
  display: none;
}
.navbar-dropdown-menu {
  position: absolute !important;
  margin-top: 1.25rem;
}
.navbar-dropdown-menu:not(.navbar-dropdown-account) {
  padding: 0;
}
.navbar-dropdown-account {
  margin-top: 0.5rem;
}
.navbar-dropdown-account-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #1e2022;
  background-color: transparent;
  padding: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 6.1875rem;
  transition: 0.3s;
}
.navbar-dropdown-account-wrapper:focus,
.navbar-dropdown-account-wrapper:hover {
  background-color: rgba(9, 42, 206, 0.1);
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-lg .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-lg .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-lg.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }
  .navbar-expand-lg .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-xl .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-xl .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-xl.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }
  .navbar-expand-xl .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-lg .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-lg .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-lg.navbar-dark .navbar-nav .nav-link {
    color: #8489a5;
  }
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #152266;
  }
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-lg .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-lg .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-lg .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-lg .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-lg .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-lg
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-lg .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-lg .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-lg
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 2.25rem;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-xl .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xl .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xl.navbar-dark .navbar-nav .nav-link {
    color: #8489a5;
  }
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #152266;
  }
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-xl .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-xl .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-xl .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-xl .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xl .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xl
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xl .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xl .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-xl
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 2.25rem;
  }
}
.navbar.navbar-container {
  padding-right: 2rem;
  padding-left: 2rem;
}
@media (max-width: 575.98px) {
  [class*='navbar-expand'].navbar {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.navbar-nav-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-nav-wrap-content-left {
  -ms-flex-align: center;
  align-items: center;
}
.navbar-nav-wrap-content-right {
  margin-left: auto;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-lg .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-xl .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}
.navbar-vertical.navbar {
  display: block;
  padding: 0;
  z-index: 99;
}
.navbar-vertical.navbar.collapse:not(.show) {
  display: none;
}
.navbar-vertical.navbar .nav-item {
  white-space: nowrap;
}
.navbar-vertical.navbar .nav-link {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-vertical-container {
  position: relative;
  height: 100%;
  min-height: 100%;
}
.navbar-vertical-fixed .navbar-vertical-container {
  max-height: 100%;
}
.navbar-vertical-content {
  overflow: hidden;
}
.navbar-vertical .nav-link {
  color: #111b51;
}
.navbar-vertical .nav-link.disabled {
  color: #8c98a4;
}
.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 3.25rem;
}
.navbar-vertical .nav-sub {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: left;
}
.navbar-vertical .nav-sub .nav-link {
  font-size: 0.875rem;
}
.navbar-vertical .nav-sub.nav .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-vertical .nav-subtitle {
  padding: 0.75rem 0.8125rem;
}
.navbar-vertical .navbar-nav.nav-tabs {
  border-bottom-width: 0;
}
.navbar-vertical .navbar-nav.nav-tabs .nav-item:not(:last-child) {
  margin: 0;
}
.navbar-vertical .navbar-nav.nav-tabs .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-vertical .navbar-nav.nav-tabs .nav-link,
.navbar-vertical .navbar-nav.nav-tabs .nav-link.active {
  border-bottom-width: 0;
  border-radius: 0;
}
.navbar-vertical .navbar-nav.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.navbar-vertical .navbar-nav.nav-tabs .nav-link {
  border-width: 0 0 0 0.2rem;
  margin-bottom: 0;
}
.navbar-vertical .navbar-nav.nav-tabs .active .nav-link,
.navbar-vertical .navbar-nav.nav-tabs .active.nav-link {
  /* border-left-color: #092ace; */
  border-left-color: transparent !important;
}
.navbar-vertical
  .navbar-nav.nav-tabs
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  margin-right: 0;
}
.navbar-vertical .nav-link-toggle::after,
.navbar-vertical .nav-link-toggle[data-toggle='collapse']::after {
  transition: 0.3s ease;
}
.navbar-vertical .nav-link-toggle[aria-expanded='true']::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.navbar-vertical .nav-compact-icon {
  -ms-flex-direction: column;
  flex-direction: column;
}
.navbar-vertical .nav-compact-icon .nav-link {
  display: block;
}
.navbar-vertical .nav-compact-icon .nav-sub .nav-link {
  display: -ms-flexbox;
  display: flex;
}
.navbar-vertical-fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: var(--menu-width);
}
.navbar-vertical-fixed .navbar-vertical-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.navbar-vertical-fixed .navbar-vertical-footer-offset {
  height: 100%;
  padding-bottom: 1.25rem;
}
.navbar-vertical:not([class*='container']) .navbar-nav.navbar-nav-lg .nav-link {
  margin-top: 0.375rem;
  margin-bottom: 0rem;
}
.navbar-vertical:not([class*='container']) .navbar-nav .nav-link {
  padding: 0.375rem 1.75rem;
}
.navbar-vertical:not([class*='container']) .navbar-nav .nav .nav-link {
  padding-right: 1.75rem;
  padding-left: 3.75rem;
  margin-top: 0;
  margin-bottom: 0;
}
.navbar-vertical:not([class*='container']) .navbar-nav .nav .nav .nav-link {
  padding-right: 1.75rem;
  padding-left: 4.75rem;
}
.navbar-vertical:not([class*='container'])
  .navbar-nav
  .nav
  .nav
  .nav
  .nav-link {
  padding-left: 5.75rem;
}
.navbar-vertical:not([class*='container']) .navbar-nav .nav-subtitle {
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}
.navbar-vertical:not([class*='container']) .navbar-nav.nav-compact-icon {
  margin-left: 0;
}
.navbar-vertical:not([class*='container'])
  .navbar-nav.nav-compact-icon
  .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-vertical-footer {
  border-top: 0.0625rem solid var(--cbBorderColor);
  padding: 0.75rem 2rem;
}
.navbar-vertical-aside-transition-on .footer,
.navbar-vertical-aside-transition-on .main,
.navbar-vertical-aside-transition-on .navbar,
.navbar-vertical-aside-transition-on .navbar-vertical-aside {
  transition: 0.2s ease-in-out;
}
.navbar-vertical-aside-transition-on
  .navbar-vertical-aside
  .navbar-vertical-footer {
  opacity: 0;
}
.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode .main {
  padding-left: 0;
}
.navbar-fixed ~ .main .navbar-vertical-aside {
  top: 0;
}
[class*='navbar-vertical-aside-show'] .navbar-nav-wrap-content-left {
  padding-left: 0;
}
.navbar-vertical-aside-mini-mode-hidden-elements {
  -ms-flex: 1;
  flex: 1;
}
.navbar-vertical-aside .navbar-brand-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 3.75rem;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}
.navbar-vertical-aside .navbar-brand-logo {
  display: block;
}
.navbar-vertical-aside .navbar-brand-logo-mini {
  width: 2.25rem;
  display: none;
}
.navbar-vertical-content {
  height: calc(100% - 3.75rem);
  overflow-y: auto;
  padding-top: 3.6rem;
}
.navbar-vertical-content::-webkit-scrollbar {
  width: 0.6125rem;
}
.navbar-vertical-content::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}
.nav-footer-item {
  display: none;
}
.nav-footer-item:last-child {
  margin-bottom: 2rem;
}
.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
  position: fixed;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-content {
  height: auto;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer {
  position: static;
  margin-top: -0.0625rem;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-offset {
  padding-bottom: 0;
}
.navbar-vertical-aside.navbar-vertical-aside-initialized {
  margin-left: 0;
}
.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside.navbar-vertical-aside-initialized {
  margin-left: -15rem;
}
.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
  margin-left: -15rem;
}
.navbar-vertical-aside-mini-mode
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  opacity: 1;
}
.navbar-vertical-aside-has-menu > .nav-link-toggle {
  position: relative;
}
.navbar-vertical-aside-has-menu > .nav-link-toggle::before {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  content: '';
  width: 5px;
  height: 5px;
  background-color: #bdc5d1;
  opacity: 0;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: opacity 0.3s;
}
.navbar-vertical-aside
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::before,
.navbar-vertical-aside
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle.active::before,
.navbar-vertical-aside
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle:hover::before {
  background-color: #092ace;
}
.has-navbar-vertical-aside:not(.navbar-vertical-aside-mini-mode)
  .navbar-vertical
  .nav-tabs
  .navbar-vertical-aside-has-menu
  > .active.nav-link {
  border-color: transparent;
}
.has-navbar-vertical-aside:not(.navbar-vertical-aside-mini-mode)
  .navbar-vertical-aside-has-menu.show
  > .nav-sub {
  display: block;
}
.navbar-vertical-aside-has-menu {
  position: relative;
}
.navbar-vertical-aside-has-menu > .nav {
  display: none;
}
.navbar-vertical-aside-has-menu > .nav-link-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: '';
  margin-left: auto;
  margin-right: 0.1875rem;
  transition: 0.3s ease;
}
.navbar-vertical-aside-has-menu.show > .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-initialized
  .navbar-vertical-container {
  overflow-y: scroll;
  overflow-y: overlay;
}
.navbar-vertical-aside-mini-mode .js-navbar-vertical-aside-toggle-invoker {
  display: block;
}
.navbar-vertical-aside-compact-mode .navbar-vertical-aside {
  width: 9rem !important;
}
.navbar-vertical-aside-compact-mode .navbar-vertical .navbar-brand {
  margin-right: 0;
}
.navbar-vertical-aside-compact-mode .js-navbar-vertical-aside-toggle-invoker {
  display: block;
}
.navbar-vertical-aside-compact-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 13rem;
  background-color: #fff;
  padding-left: 0;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.3125rem;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-mini-mode-hidden-elements {
  display: none;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after,
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  display: none;
}
.navbar-vertical-aside-compact-mini-mode
  .nav-compact-icon
  .navbar-vertical-aside-has-menu.show
  > .nav-link
  .nav-icon {
  color: #061b85;
  background-color: rgba(9, 42, 206, 0.1);
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  left: -0.5rem;
  opacity: 1;
}
.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside .nav-subtitle {
  display: none;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 13rem;
  background-color: #fff;
  padding-left: 0;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.3125rem;
}
@media (max-width: 575.98px) {
  .navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    min-width: 100%;
  }
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav::before {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1.75rem;
  height: 100%;
  content: '';
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  display: none;
}
.navbar-vertical-aside-compact-mini-mode .navbar-vertical-container,
.navbar-vertical-aside-mini-mode .navbar-vertical-container {
  position: static;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-container::-webkit-scrollbar,
.navbar-vertical-aside-mini-mode .navbar-vertical-container::-webkit-scrollbar {
  width: 0.6125rem;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-container::-webkit-scrollbar-thumb,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-container::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}
.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-has-menu,
.navbar-vertical-aside-mini-mode .navbar-vertical-aside-has-menu {
  position: static;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  display: inline-block;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu.show
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu.show
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-fixed:not([class*='container'])
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-fixed:not([class*='container'])
  .nav
  .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-fixed:not([class*='container'])
  .nav
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-fixed:not([class*='container'])
  .nav
  .nav
  .nav-link {
  padding-left: 2.25rem;
}
.navbar-vertical-aside .splitted-content-mini {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
}
.navbar-vertical-aside-mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.2s ease;
}
.navbar-vertical-aside-mobile-overlay {
  background-color: rgba(17, 27, 81, 0.25);
}
.has-navbar-vertical-aside:not(.navbar-vertical-aside-closed-mode)
  .navbar-vertical-aside-mobile-overlay {
  z-index: 9998;
  opacity: 1;
  width: 100%;
  height: 100%;
}
@media (min-width: 576px) {
  .navbar-vertical-aside-show-sm .main {
    padding-left: 15rem;
  }
  .navbar-vertical-aside-show-sm .footer {
    margin-left: 15rem;
  }
  .navbar-vertical-aside-show-sm .navbar:not(.navbar-vertical) {
    margin-left: 15rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-sm .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-sm
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-sm:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-sm .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 13rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: '';
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-vertical-aside-show-md .main {
    padding-left: 15rem;
  }
  .navbar-vertical-aside-show-md .footer {
    margin-left: 15rem;
  }
  .navbar-vertical-aside-show-md .navbar:not(.navbar-vertical) {
    margin-left: 15rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-md .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-md
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-md:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-md .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 13rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: '';
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-lg .main {
    padding-left: 15rem;
  }
  .navbar-vertical-aside-show-lg .footer {
    margin-left: 15rem;
  }
  .navbar-vertical-aside-show-lg .navbar:not(.navbar-vertical) {
    margin-left: 15rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-lg .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-lg
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-lg:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-lg .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 13rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: '';
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-xl .main {
    padding-left: var(--menu-width);
  }
  .navbar-vertical-aside-show-xl .footer {
    margin-left: var(--menu-width);
  }
  .navbar-vertical-aside-show-xl .navbar:not(.navbar-vertical) {
    margin-left: 0rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-xl .navbar-vertical-aside {
    margin-left: 0;
    width: var(--menu-width) !important;
  }
  .navbar-vertical-aside-show-xl
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-xl:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-xl .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 13rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: '';
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar-vertical-aside-show-sm .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-sm.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-sm.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}
@media (max-width: 767.98px) {
  .navbar-vertical-aside-show-md .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-md.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-md.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}
@media (max-width: 991.98px) {
  .navbar-vertical-aside-show-lg .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-lg.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-lg.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}
@media (max-width: 1199.98px) {
  .navbar-vertical-aside-show-xl .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-xl.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-xl.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}
.has-navbar-vertical-aside .navbar-vertical-aside-toggle-short-align {
  display: block;
}
.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside-toggle-short-align,
.has-navbar-vertical-aside.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-toggle-short-align {
  display: none;
}
.has-navbar-vertical-aside .navbar-vertical-aside-toggle-full-align {
  display: none;
}
.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside-toggle-full-align,
.has-navbar-vertical-aside.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-toggle-full-align {
  display: block;
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.navbar-dark {
  background-color: #111b51 !important;
}
.navbar-dark .navbar-collapse {
  background-color: transparent;
}
.navbar-dark .navbar-toggler {
  border-color: transparent;
}
.navbar-dark .navbar-vertical-aside-has-menu.show > .nav-link-toggle::before,
.navbar-dark .navbar-vertical-aside-has-menu > .nav-link-toggle.active::before,
.navbar-dark .navbar-vertical-aside-has-menu > .nav-link-toggle:hover::before {
  background-color: #fff;
}
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .active
  > .nav-link,
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .nav-link.active,
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .nav-link.show,
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .show
  > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: #8489a5;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .dropdown-item.active,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link {
  color: #111b51;
}
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link.active,
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link:hover {
  color: #061b85;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-sub
  .nav-link,
.navbar-vertical-aside-mini-mode .navbar-dark .navbar-nav .nav-sub .nav-link {
  color: #000;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-sub
  .nav-link:hover,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-sub
  .nav-link:hover {
  color: #092ace;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .active
  > .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .nav-link.show,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .show
  > .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu.show
  .nav-sub
  .nav-link.active,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .active
  > .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .nav-link.show,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .show
  > .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu.show
  .nav-sub
  .nav-link.active {
  color: #092ace;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .nav-compact-icon
  .navbar-vertical-aside-has-menu.show
  > .nav-link
  .nav-icon {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .nav-compact-icon .nav-icon {
  color: #8489a5;
}
.navbar-dark .nav-compact-icon .nav-icon.active,
.navbar-dark .nav-compact-icon .nav-icon:focus,
.navbar-dark .nav-compact-icon .nav-icon:hover {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .nav-compact-icon .nav-link.active .nav-icon {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .nav-compact-icon > .show > .nav-link .nav-icon {
  color: #8489a5;
}
.navbar-dark.navbar-bordered,
.navbar-dark.navbar-bordered .navbar-vertical-footer {
  border-color: #152266;
}
.navbar-dark .input-group-hover-light .form-control,
.navbar-dark .input-group-hover-light .form-control:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .input-group-hover-light .form-control:focus {
  background-color: #fff;
}
.search-fullwidth {
  position: fixed;
  top: 0;
  left: 0 !important;
  width: 100%;
  z-index: 101;
}
.search-fullwidth .form-control {
  height: 3.75rem;
}
.avatar-status-success {
  color: #fff;
  background-color: #27ae60;
}
.avatar-soft-primary .avatar-initials {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
}
.avatar-soft-dark .avatar-initials {
  color: #111b51;
  background-color: rgba(17, 27, 81, 0.1);
}
.avatar {
  position: relative;
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.3125rem;
}
.avatar:not(img) {
  background-color: #fff;
}
.avatar-img {
  display: block;
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  border-radius: 0.3125rem;
}
.avatar-initials {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.09375rem;
  font-weight: 600;
  pointer-events: none;
  text-transform: uppercase;
  border-radius: 0.3125rem;
}
.avatar-circle {
  border-radius: 50%;
}
.avatar-circle .avatar-img,
.avatar-circle .avatar-initials {
  border-radius: 50%;
}
.avatar-border-lg {
  border: 0.1875rem solid #fff;
}
.avatar-border-dashed {
  border: 0.0625rem dashed var(--cbBorderColor);
}
.avatar-border-dashed .avatar-initials {
  color: #677788;
  background-color: #fff;
  transition: 0.3s;
}
.avatar-border-dashed:hover .avatar-initials {
  color: #092ace;
}
.avatar-group {
  position: relative;
  z-index: 2;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.avatar-group .avatar:hover {
  z-index: 2;
}
.avatar-group .avatar,
.avatar-group .avatar-sm,
.avatar-group .avatar-xs {
  padding: 0.125rem 0.125rem;
}
.avatar-group .avatar-lg,
.avatar-group .avatar-xl,
.avatar-group .avatar-xxl {
  padding: 0.25rem 0.25rem;
}
.avatar-group.avatar-circle .avatar {
  border-radius: 50%;
}
.avatar-group.avatar-circle .avatar .avatar-img,
.avatar-group.avatar-circle .avatar .avatar-initials {
  border-radius: 50%;
}
.avatar-group .avatar {
  width: 2.625rem;
  height: 2.625rem;
}
.avatar-group .avatar .avatar-initials {
  font-size: 1.09375rem;
}
.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}
.avatar-group-xs .avatar {
  width: 1.53125rem;
  height: 1.53125rem;
}
.avatar-group-xs .avatar .avatar-initials {
  font-size: 0.875rem;
}
.avatar-group-xs .avatar + .avatar {
  margin-left: -0.6125rem;
}
.avatar.avatar-4by3 {
  width: 3.5rem;
}
.avatar-xss.avatar-4by3 {
  width: 1.33333333rem;
}
.avatar-xs.avatar-4by3 {
  width: 2.04166667rem;
}
.avatar-sm.avatar-4by3 {
  width: 2.91666667rem;
}
.avatar-lg.avatar-4by3 {
  width: 4.49166667rem;
}
.avatar-xl.avatar-4by3 {
  width: 6.5625rem;
}
.avatar-xxl.avatar-4by3 {
  width: 10.5rem;
}
.avatar.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}
.avatar.avatar-circle .avatar-sm-status {
  bottom: 0;
  right: 0;
}
.avatar-xss {
  width: 1rem;
  height: 1rem;
}
.avatar-xss .avatar-initials {
  font-size: 0.875rem;
}
.avatar-xs {
  width: 1.53125rem;
  height: 1.53125rem;
}
.avatar-xs .avatar-initials {
  font-size: 0.6rem;
}
.avatar-xs.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}
.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -1.5px;
  right: -1.5px;
}
.avatar-sm {
  width: 2.1875rem;
  height: 2.1875rem;
}
.avatar-sm .avatar-initials {
  font-size: 0.684375rem;
}
.avatar-sm.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}
.avatar-sm.avatar-circle .avatar-sm-status {
  bottom: -1.2px;
  right: -1.2px;
}
.avatar-lg {
  width: 3.36875rem;
  height: 3.36875rem;
}
.avatar-lg .avatar-initials {
  font-size: 1.53125rem;
}
.avatar-lg.avatar-circle .avatar-status {
  bottom: -2.1px;
  right: -2.1px;
}
.avatar-lg.avatar-circle .avatar-sm-status {
  bottom: 1.2px;
  right: 1.2px;
}
.avatar-lg_2 {
  width: 4rem;
  height: 4rem;
}
.avatar-lg_2 .avatar-initials {
  font-size: 1.73rem;
}
.avatar-xl {
  width: 4.921875rem;
  height: 4.921875rem;
}
.avatar-xl .avatar-initials {
  font-size: 1.96875rem;
}
.avatar-xl.avatar-circle .avatar-status {
  bottom: 2.625px;
  right: 2.625px;
}
.avatar-xl.avatar-circle .avatar-sm-status {
  bottom: 3px;
  right: 3px;
}
.avatar-xxl {
  width: 7.875rem;
  height: 7.875rem;
}
.avatar-xxl .avatar-initials {
  font-size: 2.625rem;
}
.avatar-xxl.avatar-circle .avatar-status {
  bottom: 6px;
  right: 6px;
}
.avatar-xxl.avatar-circle .avatar-sm-status {
  bottom: 12px;
  right: 12px;
}
.avatar-status {
  position: absolute;
  bottom: -8.4px;
  right: -8.4px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  font-weight: 600;
  border: 0.125rem solid #fff;
  width: 21px;
  height: 21px;
  line-height: 1;
  font-size: 0.7109375rem;
  border-radius: 50%;
}
.avatar-sm-status {
  bottom: -4.2px;
  right: -4.2px;
  width: 12px;
  height: 12px;
  font-size: 0.4375rem;
}
.avatar-uploader {
  cursor: pointer;
  display: inline-block;
  transition: 0.2s;
  margin-bottom: 0;
}
.avatar-uploader:hover {
  border-color: #092ace;
}
.avatar-uploader-input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 27, 81, 0.25);
  border-radius: 50%;
  transition: 0.2s;
}
.avatar-uploader-trigger {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
}
.avatar-uploader-trigger.upload-profile {
  right: -7px;
  bottom: -7px;
}
.avatar-uploader-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.2s;
}
.avatar-uploader:hover .avatar-uploader-icon {
  color: #fff;
  background-color: #092ace;
}
.avatar-lg .avatar-uploader-icon {
  font-size: 0.75rem;
  width: 1.3125rem;
  height: 1.3125rem;
}
.avatar-xl .avatar-uploader-icon {
  font-size: 0.8125rem;
  width: 1.53125rem;
  height: 1.53125rem;
}
.avatar-xxl .avatar-uploader-icon {
  width: 2.1875rem;
  height: 2.1875rem;
}
.badge-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}
a.badge-soft-secondary:focus,
a.badge-soft-secondary:hover {
  color: #fff;
  background-color: #71869d;
}
.badge-soft-success {
  color: #27ae60;
  background-color: rgba(39, 174, 96, 0.1);
}
a.badge-soft-success:focus,
a.badge-soft-success:hover {
  color: #fff;
  background-color: #27ae60;
}
.badge-soft-danger {
  color: #f44336;
  background-color: rgba(244, 67, 54, 0.1);
}
a.badge-soft-danger:focus,
a.badge-soft-danger:hover {
  color: #fff;
  background-color: #f44336;
}
.badge-soft-dark {
  color: #111b51;
  background-color: rgba(17, 27, 81, 0.1);
}
a.badge-soft-dark:focus,
a.badge-soft-dark:hover {
  color: #fff;
  background-color: #111b51;
}
.breadcrumb .breadcrumb-item {
  color: #1e2022;
  font-size: 0.8125rem;
}
.breadcrumb .breadcrumb-link {
  color: #677788;
}
.breadcrumb .breadcrumb-link:hover {
  color: #061b85;
}
.breadcrumb-no-gutter {
  padding: 0;
}
.blockquote {
  font-size: 0.875rem;
  border-left: 0.1875rem solid var(--cbBorderColor);
  padding-left: 0.75rem;
  margin-bottom: 0;
}
.btn.dropdown-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}
.btn-white {
  background-color: #fff;
  border-color: var(--cbBorderColor);
}
.btn-white.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-white.active,
.btn-white.focus,
.btn-white:active,
.btn-white:focus,
.btn-white:hover {
  color: #061b85;
  box-shadow: none;
  background-color: var(--setting-menu-color);
}
.btn-white.active.dropdown-toggle::after,
.btn-white.focus.dropdown-toggle::after,
.btn-white:active.dropdown-toggle::after,
.btn-white:focus.dropdown-toggle::after,
.btn-white:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23092ACE' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-white.disabled,
.btn-white:disabled {
  color: #8c98a4;
  background-color: rgba(231, 234, 243, 0.5);
}
.btn-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23092ACE' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-primary.active.dropdown-toggle::after,
.btn-outline-primary:active.dropdown-toggle::after,
.btn-outline-primary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23F44336' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-danger.active.dropdown-toggle::after,
.btn-outline-danger:active.dropdown-toggle::after,
.btn-outline-danger:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-light.active.dropdown-toggle::after,
.btn-outline-light:active.dropdown-toggle::after,
.btn-outline-light:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-xs {
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.240625rem 0.65625rem;
  border-radius: 0.25rem;
}
.btn-ghost-primary {
  color: #092ace;
  background-color: transparent;
}
.btn-ghost-primary.active,
.btn-ghost-primary.focus,
.btn-ghost-primary:active,
.btn-ghost-primary:focus,
.btn-ghost-primary:hover {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
}
.btn-ghost-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23092ACE' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-primary.btn-no-focus[href].focus,
.btn-ghost-primary.btn-no-focus[href]:focus,
.btn-ghost-primary.btn-no-focus[type].focus,
.btn-ghost-primary.btn-no-focus[type]:focus {
  color: #092ace;
  background-color: unset;
}
.btn-ghost-primary.btn-no-focus[href].focus:hover,
.btn-ghost-primary.btn-no-focus[href]:focus:hover,
.btn-ghost-primary.btn-no-focus[type].focus:hover,
.btn-ghost-primary.btn-no-focus[type]:focus:hover {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
}
.btn-ghost-secondary {
  color: #71869d;
  background-color: transparent;
}
.btn-ghost-secondary.active,
.btn-ghost-secondary.focus,
.btn-ghost-secondary:active,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover {
  color: #71869d;
  background-color: rgba(9, 42, 206, 0.1);
}
.btn-ghost-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-secondary.btn-no-focus[href].focus,
.btn-ghost-secondary.btn-no-focus[href]:focus,
.btn-ghost-secondary.btn-no-focus[type].focus,
.btn-ghost-secondary.btn-no-focus[type]:focus {
  color: #71869d;
  background-color: unset;
}
.btn-ghost-secondary.btn-no-focus[href].focus:hover,
.btn-ghost-secondary.btn-no-focus[href]:focus:hover,
.btn-ghost-secondary.btn-no-focus[type].focus:hover,
.btn-ghost-secondary.btn-no-focus[type]:focus:hover {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}
.btn-ghost-success {
  color: #27ae60;
  background-color: transparent;
}
.btn-ghost-success.active,
.btn-ghost-success.focus,
.btn-ghost-success:active,
.btn-ghost-success:focus,
.btn-ghost-success:hover {
  color: #27ae60;
  background-color: rgba(39, 174, 96, 0.1);
}
.btn-ghost-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2327ae60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-success.btn-no-focus[href].focus,
.btn-ghost-success.btn-no-focus[href]:focus,
.btn-ghost-success.btn-no-focus[type].focus,
.btn-ghost-success.btn-no-focus[type]:focus {
  color: #27ae60;
  background-color: unset;
}
.btn-ghost-success.btn-no-focus[href].focus:hover,
.btn-ghost-success.btn-no-focus[href]:focus:hover,
.btn-ghost-success.btn-no-focus[type].focus:hover,
.btn-ghost-success.btn-no-focus[type]:focus:hover {
  color: #27ae60;
  background-color: rgba(39, 174, 96, 0.1);
}
.btn-ghost-danger {
  color: #f44336;
  background-color: transparent;
}
.btn-ghost-danger.active,
.btn-ghost-danger.focus,
.btn-ghost-danger:active,
.btn-ghost-danger:focus,
.btn-ghost-danger:hover {
  color: #f44336;
  background-color: rgba(244, 67, 54, 0.1);
}
.btn-ghost-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23F44336' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-danger.btn-no-focus[href].focus,
.btn-ghost-danger.btn-no-focus[href]:focus,
.btn-ghost-danger.btn-no-focus[type].focus,
.btn-ghost-danger.btn-no-focus[type]:focus {
  color: #f44336;
  background-color: unset;
}
.btn-ghost-danger.btn-no-focus[href].focus:hover,
.btn-ghost-danger.btn-no-focus[href]:focus:hover,
.btn-ghost-danger.btn-no-focus[type].focus:hover,
.btn-ghost-danger.btn-no-focus[type]:focus:hover {
  color: #f44336;
  background-color: rgba(244, 67, 54, 0.1);
}
.btn-ghost-light {
  color: #f9fafc;
  background-color: transparent;
}
.btn-ghost-light.active,
.btn-ghost-light.focus,
.btn-ghost-light:active,
.btn-ghost-light:focus,
.btn-ghost-light:hover {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}
.btn-ghost-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-light.btn-no-focus[href].focus,
.btn-ghost-light.btn-no-focus[href]:focus,
.btn-ghost-light.btn-no-focus[type].focus,
.btn-ghost-light.btn-no-focus[type]:focus {
  color: #f9fafc;
  background-color: unset;
}
.btn-ghost-light.btn-no-focus[href].focus:hover,
.btn-ghost-light.btn-no-focus[href]:focus:hover,
.btn-ghost-light.btn-no-focus[type].focus:hover,
.btn-ghost-light.btn-no-focus[type]:focus:hover {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}
.btn-ghost-dark {
  color: #111b51;
  background-color: transparent;
}
.btn-ghost-dark.active,
.btn-ghost-dark.focus,
.btn-ghost-dark:active,
.btn-ghost-dark:focus,
.btn-ghost-dark:hover {
  color: #111b51;
  background-color: rgba(17, 27, 81, 0.1);
}
.btn-ghost-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23111B51' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-dark.btn-no-focus[href].focus,
.btn-ghost-dark.btn-no-focus[href]:focus,
.btn-ghost-dark.btn-no-focus[type].focus,
.btn-ghost-dark.btn-no-focus[type]:focus {
  color: #111b51;
  background-color: unset;
}
.btn-ghost-dark.btn-no-focus[href].focus:hover,
.btn-ghost-dark.btn-no-focus[href]:focus:hover,
.btn-ghost-dark.btn-no-focus[type].focus:hover,
.btn-ghost-dark.btn-no-focus[type]:focus:hover {
  color: #111b51;
  background-color: rgba(17, 27, 81, 0.1);
}
.btn-ghost-secondary.focus,
.btn-ghost-secondary:active,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover {
  color: #092ace;
}
.btn-ghost-secondary.focus.dropdown-toggle::after,
.btn-ghost-secondary:active.dropdown-toggle::after,
.btn-ghost-secondary:focus.dropdown-toggle::after,
.btn-ghost-secondary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23092ACE' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-secondary.btn-no-focus[href].focus:hover,
.btn-ghost-secondary.btn-no-focus[href]:focus:hover,
.btn-ghost-secondary.btn-no-focus[type].focus:hover,
.btn-ghost-secondary.btn-no-focus[type]:focus:hover {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
}
.btn-soft-primary {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
  border-color: transparent;
}
.btn-soft-primary.active,
.btn-soft-primary:active,
.btn-soft-primary:focus,
.btn-soft-primary:hover {
  color: #fff;
  background-color: #092ace;
  box-shadow: 0 4px 11px rgba(9, 42, 206, 0.1);
}
.btn-soft-primary.disabled,
.btn-soft-primary:disabled {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
  box-shadow: none;
}
.btn-soft-primary:not(:disabled):not(.disabled).active,
.btn-soft-primary:not(:disabled):not(.disabled):active,
.show > .btn-soft-primary.dropdown-toggle {
  color: #fff;
  background-color: #092ace;
}
.btn-soft-dark {
  color: #111b51;
  background-color: rgba(17, 27, 81, 0.1);
  border-color: transparent;
}
.btn-soft-dark.active,
.btn-soft-dark:active,
.btn-soft-dark:focus,
.btn-soft-dark:hover {
  color: #fff;
  background-color: #111b51;
  box-shadow: 0 4px 11px rgba(17, 27, 81, 0.1);
}
.btn-soft-dark.disabled,
.btn-soft-dark:disabled {
  color: #111b51;
  background-color: rgba(17, 27, 81, 0.1);
  box-shadow: none;
}
.btn-soft-dark:not(:disabled):not(.disabled).active,
.btn-soft-dark:not(:disabled):not(.disabled):active,
.show > .btn-soft-dark.dropdown-toggle {
  color: #fff;
  background-color: #111b51;
}
.btn-soft-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23092ACE' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  transition: all 0.2s ease-in-out;
}
.btn-soft-primary.active.dropdown-toggle::after,
.btn-soft-primary.focus.dropdown-toggle::after,
.btn-soft-primary:active.dropdown-toggle::after,
.btn-soft-primary:focus.dropdown-toggle::after,
.btn-soft-primary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-soft-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23111B51' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  transition: all 0.2s ease-in-out;
}
.btn-soft-dark.active.dropdown-toggle::after,
.btn-soft-dark.focus.dropdown-toggle::after,
.btn-soft-dark:active.dropdown-toggle::after,
.btn-soft-dark:focus.dropdown-toggle::after,
.btn-soft-dark:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-icon {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.9296875rem;
  font-weight: 400;
  width: 2.625rem;
  height: 2.625rem;
  padding: 0;
}
.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.53125rem;
  height: 1.53125rem;
}
.btn-icon.btn-sm2 {
  font-size: 0.975rem;
  width: 2.1rem;
  height: 2.1rem;
}
.btn {
  position: relative;
}
label.btn {
  cursor: pointer !important;
}
.btn.dropdown-toggle::after {
  transition: all 0.2s ease-in-out;
}
.btn-pill {
  border-radius: 6.1875rem;
}
.content-space {
  padding: 2rem;
}
@media (max-width: 575.98px) {
  .content-space {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 15rem;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 15rem;
  }
}
.main .content {
  padding-top: 1.75rem;
  /* padding-bottom: 1.75rem; */
}
.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}
@media (max-width: 575.98px) {
  .container-fluid {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.kanban-board {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.kanban-board::-webkit-scrollbar {
  height: 0.6125rem;
}
.kanban-board::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}
.kanban-board .kanban-board-row {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.kanban-board .kanban-board-row > [class*='col'] {
  max-width: 350px;
}
.splitted-content-main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.splitted-content-bordered:not(:last-child) {
  border-right: 0.0625rem solid var(--cbBorderColor);
}
.splitted-content-bordered:not(:first-child) {
  border-left: 0.0625rem solid var(--cbBorderColor);
}
.splitted-content-mini {
  background-color: #fff;
}
.splitted-content-mini {
  width: 5.25rem;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.splitted-content-mini .navbar-brand {
  margin-right: 0;
}
.splitted-content-fluid {
  min-height: 100vh;
}
.splitted-content-fluid {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}
.header ~ .main .splitted-content-fluid,
.header ~ .main .splitted-content-mini {
  min-height: calc(100vh - 3.75rem);
}
.splitted-content-mini.hs-unfold-content-initialized {
  display: -ms-flexbox !important;
  display: flex !important;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1199.98px) {
  .splitted-content-mini.hs-unfold-hidden {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
  .splitted-content-mini-right {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 101;
  }
}
.page-header {
  padding-bottom: 1.75rem;
  margin-bottom: 2.25rem;
}
.page-header .breadcrumb {
  margin-bottom: 0.25rem;
}
.page-header-title {
  margin-bottom: 0.25rem;
}
.page-header-tabs {
  margin-bottom: -1.75rem;
  border-bottom-width: 0;
}
.card {
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075);
}
.card[href]:hover .card-header-title,
.card[href]:hover .card-title {
  color: #061b85;
}
.card-title {
  display: block;
  color: #1e2022;
  margin-bottom: 0;
}
.card-text {
  display: block;
  color: #000;
}
.card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.card-header-title {
  margin-bottom: 0;
}
.card-footer {
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.card-body-height {
  height: 21.25rem;
  overflow: hidden;
  overflow-y: auto;
}
.card-body-height::-webkit-scrollbar {
  width: 0.6125rem;
}
.card-body-height::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}
.card-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  white-space: inherit;
}
.card-btn-toggle {
  display: inline-block;
  color: #092ace;
}
.card-btn-toggle-default {
  display: none;
}
.card-btn-toggle-active {
  display: block;
}
.card-btn.collapsed .card-btn-toggle-default {
  display: block;
}
.card-btn.collapsed .card-btn-toggle-active {
  display: none;
}
.card-table {
  margin-bottom: 0;
}
.card-table td,
.card-table th {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}
.card-table .table-thead-bordered {
  margin-top: -0.0625rem;
}
@media (max-width: 575.98px) {
  .card-dropdown-filter-centered {
    left: -8rem;
    min-width: 18rem !important;
  }
}
.card-unfold {
  z-index: 2;
}
.card-progress-wrap {
  overflow: hidden;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.card-progress {
  height: 0.25rem;
}
.card-progress:first-child .progress-bar {
  border-top-left-radius: 0.75rem;
}
.card-progress:first-child .progress-bar[aria-valuenow='100'] {
  border-top-right-radius: 0.75rem;
}
.card-navbar-nav {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.card-navbar-nav .nav-icon {
  margin-right: 0.5rem;
}
.close {
  opacity: 1;
}
.close:hover {
  color: #061b85;
}
.column-divider {
  position: relative;
}
.column-divider::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left: 0.0625rem solid var(--cbBorderColor);
  content: '';
}
@media (min-width: 768px) {
  .column-divider-md {
    position: relative;
  }
  .column-divider-md::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid var(--cbBorderColor);
    content: '';
  }
}
@media (min-width: 992px) {
  .column-divider-lg {
    position: relative;
  }
  .column-divider-lg::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid var(--cbBorderColor);
    content: '';
  }
}
.divider {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.divider::after,
.divider::before {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: 0.0625rem solid var(--cbBorderColor);
  content: '';
  margin-top: 0.0625rem;
}
.divider::before {
  margin-right: 1.5rem;
}
.divider::after {
  margin-left: 1.5rem;
}
.link {
  font-size: 0.8125rem;
}
.list-unstyled-py-2 > li:not(:last-child) {
  padding-bottom: 0.5rem;
}
.list-unstyled-py-2 .list-unstyled-py-2 > li:first-child {
  padding-top: 0.5rem;
}
.list-unstyled-py-2 .list-unstyled-py-2 > li:last-child {
  padding-bottom: 0;
}
.list-unstyled-py-3 > li:not(:last-child) {
  padding-bottom: 1rem;
}
.list-unstyled-py-3 .list-unstyled-py-3 > li:first-child {
  padding-top: 1rem;
}
.list-unstyled-py-3 .list-unstyled-py-3 > li:last-child {
  padding-bottom: 0;
}
.list-unstyled-py-4 > li:not(:last-child) {
  padding-bottom: 1.5rem;
}
.list-unstyled-py-4 .list-unstyled-py-4 > li:first-child {
  padding-top: 1.5rem;
}
.list-unstyled-py-4 .list-unstyled-py-4 > li:last-child {
  padding-bottom: 0;
}
.list-separator {
  margin-bottom: 0;
}
.list-separator .list-inline-item {
  position: relative;
  margin-left: 0;
  margin-right: -0.25rem;
}
.list-separator .list-inline-item:not(:last-child) {
  padding-right: 2rem;
}
.list-separator .list-inline-item:not(:last-child)::after {
  position: absolute;
  top: calc(50% - 0.15625rem);
  right: 0.84375rem;
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: #bdc5d1;
  border-radius: 0.3125rem;
  content: '';
}
.list-separator .list-separator-link {
  color: #000;
}
.list-separator .list-separator-link:hover {
  color: #061b85;
}
.list-group-item:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.list-group-no-gutters .list-group-item {
  padding-right: 0;
  padding-left: 0;
}
.list-checked {
  padding-left: 0;
  list-style: none;
}
.list-checked-item {
  position: relative;
  display: block;
  color: #000;
  padding-left: 1.75rem;
  margin: 0.5rem;
}
.list-checked-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A")
    no-repeat right center/1rem 1rem;
  content: '';
  margin-top: 0.125rem;
}
.list-checked-item[hover]:hover {
  color: #092ace;
}
[class*='list-checked-soft-bg-'] .list-checked-item::before {
  margin-top: 0.25rem;
}
.list-checked-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23092ACE' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}
.list-checked-soft-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23111B51' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23111B51' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  width: 23rem;
  height: 100%;
  background-color: #fff;
}
.table th {
  font-weight: 400;
}
.table thead th {
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 600;
}
.table tfoot td,
.table tfoot th {
  color: #1e2022;
  font-weight: 600;
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.table .btn {
  white-space: nowrap;
}
.table-lg td,
.table-lg th {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}
.table-align-middle tbody td {
  vertical-align: middle;
}
.table-text-center td,
.table-text-center th {
  text-align: center;
}
.table-column-pr-0 {
  padding-right: 0 !important;
}
.table-column-pl-0 {
  padding-left: 0 !important;
}
.table-thead-bordered thead th {
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.dropdown-menu {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
}
.dropdown-item {
  font-size: 0.8125rem;
}
.dropdown-item:active {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}
.dropdown-item.active:not(:focus):not(:active) {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}
.dropdown-item[href]:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23092ACE' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.dropdown-header {
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  font-size: 80%;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.dropdown-item-icon {
  display: inline-block;
  opacity: 0.7;
  font-size: 1rem;
  width: 1.5rem;
  color: #000;
}
.dropdown-toggle {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000000' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: '';
}
.dropdown-toggle:not(.dropdown-toggle-empty)::after {
  margin-left: 0.3125rem;
}
.dropup .dropdown-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.dropright .dropdown-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.dropleft .dropdown-toggle::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.dropdown-card {
  padding-top: 0;
  padding-bottom: 0;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3.5rem;
}
.footer:not([class*='container']) {
  padding-right: 2rem;
  padding-left: 2rem;
}
@media (max-width: 575.98px) {
  .footer:not([class*='container']) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.custom-select:focus,
.form-control:focus {
  box-shadow: 0 0 10px rgba(9, 42, 206, 0.1);
}
.form-text {
  color: #677788;
}
.form-link {
  display: inline-block;
  margin-top: 0.75rem;
}
.custom-control-label,
.custom-select {
  cursor: pointer;
}
.form-control .custom-control-label {
  width: 100%;
}
.custom-control-input:checked:disabled ~ .custom-control-label::before {
  border-color: rgba(9, 42, 206, 0);
}
.custom-select-sm {
  border-radius: 0.25rem;
}
.input-group-text {
  border-color: var(--cbBorderColor);
}
.input-label {
  display: block;
  color: #1e2022;
  font-size: 0.875rem;
}
.input-label-secondary {
  color: #677788;
  font-size: 0.8125rem;
  margin-left: 0.25rem;
}
.input-group .input-group-append,
.input-group .input-group-prepend {
  z-index: 4;
}
.input-group > .input-group-prepend .custom-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append .custom-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group
  > .input-group-prepend:first-child
  .select2-custom:not(:first-child)
  .custom-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group
  > .input-group-append:last-child
  .select2-custom:not(:last-child)
  .custom-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-add-field {
  position: relative;
  margin-top: 1rem;
}
.input-group-add-field:hover .input-group-add-field-delete {
  opacity: 1;
}
.input-group-add-field-delete {
  position: absolute;
  top: 0.5rem;
  right: 0;
  width: 1rem;
  color: #f44336;
  opacity: 0;
  margin-right: -1rem;
  padding-left: 0.25rem;
}
.input-group-add-field-delete:hover {
  color: #f22314;
}
@media (max-width: 1199.98px) {
  .input-group-add-field-delete {
    opacity: 1;
  }
}
.input-group-borderless .form-control,
.input-group-borderless .input-group-text {
  border: none;
}
.input-group-borderless .form-control:focus,
.input-group-borderless .input-group-text:focus {
  box-shadow: none;
}
.custom-select-borderless {
  border-color: transparent;
}
.custom-select-borderless:focus {
  box-shadow: none;
  border-color: transparent;
}
@media (max-width: 575.98px) {
  .input-group-sm-down-break {
    display: block;
  }
  .input-group-sm-down-break > .custom-select,
  .input-group-sm-down-break > .form-control {
    width: 100%;
  }
  .input-group-sm-down-break .select2-custom,
  .input-group-sm-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-sm-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-sm-down-break .select2-custom:first-child .select2-selection,
  .input-group-sm-down-break > .custom-select:first-child,
  .input-group-sm-down-break > .form-control:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-sm-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection,
  .input-group-sm-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-sm-down-break
    > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-sm-down-break .select2-custom:last-child .select2-selection,
  .input-group-sm-down-break > .custom-select:last-child,
  .input-group-sm-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-sm-down-break .custom-select + .custom-select,
  .input-group-sm-down-break .custom-select + .form-control,
  .input-group-sm-down-break .form-control + .custom-select,
  .input-group-sm-down-break .form-control + .form-control,
  .input-group-sm-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}
.form-control-light {
  background-color: #f6f7fa;
  box-shadow: none;
}
.form-control-light:disabled,
.form-control-light[readonly] {
  background-color: transparent;
}
.input-group-hover-light .custom-select.focus,
.input-group-hover-light .custom-select:focus,
.input-group-hover-light .custom-select:hover,
.input-group-hover-light .form-control.focus,
.input-group-hover-light .form-control:focus,
.input-group-hover-light .form-control:hover {
  background-color: #f6f7fa;
  box-shadow: none;
}
.input-group-hover-light .custom-select:disabled,
.input-group-hover-light .custom-select[readonly],
.input-group-hover-light .form-control:disabled,
.input-group-hover-light .form-control[readonly] {
  background-color: transparent;
}
.input-group-merge {
  position: relative;
}
.input-group-merge .input-group-append,
.input-group-merge .input-group-prepend {
  position: absolute;
  top: 0;
  bottom: 0;
}
.input-group-merge .input-group-prepend {
  left: 0;
}
.input-group-merge .input-group-prepend .input-group-text {
  padding: 0 0 0 0.875rem;
}
.input-group-merge .input-group-prepend .input-group-text:first-child {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-merge .input-group-append {
  right: 0;
}
.input-group-merge .input-group-append .input-group-text {
  padding: 0 0.875rem 0 0;
}
.input-group-merge .input-group-append .input-group-text:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-merge .input-group-text {
  z-index: 4;
  border: none;
}
.input-group-merge .custom-select:not(:first-child),
.input-group-merge .form-control:not(:first-child) {
  padding-left: 2.625rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.input-group-merge .custom-select:not(:last-child),
.input-group-merge .form-control:not(:last-child) {
  padding-right: 2.625rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.input-group-flush .custom-select,
.input-group-flush .form-control {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.input-group-flush .custom-select.is-invalid,
.input-group-flush .custom-select.is-invalid:focus,
.input-group-flush .custom-select.is-valid,
.input-group-flush .custom-select.is-valid:focus,
.input-group-flush .form-control.is-invalid,
.input-group-flush .form-control.is-invalid:focus,
.input-group-flush .form-control.is-valid,
.input-group-flush .form-control.is-valid:focus {
  box-shadow: none;
}
.input-group-flush .input-group-append .input-group-text,
.input-group-flush .input-group-prepend .input-group-text {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}
.input-group-flush .input-group-prepend .input-group-text {
  padding-left: 0;
}
.input-group-flush .input-group-append .input-group-text {
  padding-left: 0;
}
.input-group-flush.input-group-merge .input-group-append .input-group-text,
.input-group-flush.input-group-merge .input-group-prepend .input-group-text {
  padding: 0;
}
.input-group-flush.input-group-merge .custom-select:not(:first-child),
.input-group-flush.input-group-merge .form-control:not(:first-child) {
  padding-left: 1.75rem;
}
.input-group-flush.input-group-merge .custom-select:not(:last-child),
.input-group-flush.input-group-merge .form-control:not(:last-child) {
  padding-right: 1.75rem;
}
.input-group-flush.input-group .custom-select:not(:first-child),
.input-group-flush.input-group .custom-select:not(:last-child),
.input-group-flush.input-group .form-control:not(:first-child),
.input-group-flush.input-group .form-control:not(:last-child),
.input-group-flush.input-group
  .input-group-append
  .input-group-text:first-child,
.input-group-flush.input-group .input-group-append .input-group-text:last-child,
.input-group-flush.input-group-merge .input-group-append .input-group-text,
.input-group-flush.input-group-merge .input-group-prepend .input-group-text {
  border-radius: 0;
}
.form-control.is-valid {
  background-size: 1rem 1rem;
}
.form-control.is-valid,
.form-control.is-valid:focus {
  box-shadow: 0 0 10px rgba(39, 174, 96, 0.1);
}
.form-control.is-invalid {
  background-size: 1rem 1rem;
}
.form-control.is-invalid,
.form-control.is-invalid:focus {
  box-shadow: 0 0 10px rgba(244, 67, 54, 0.1);
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: #27ae60;
  background-color: #27ae60;
}
.is-valid .custom-select {
  background-size: 1rem 1rem;
  border-color: #27ae60;
}
.is-valid .custom-select,
.is-valid .custom-select:focus {
  box-shadow: 0 0 10px rgba(39, 174, 96, 0.1);
}
.is-invalid .custom-select {
  background-size: 1rem 1rem;
  border-color: #f44336;
}
.is-invalid .custom-select,
.is-invalid .custom-select:focus {
  box-shadow: 0 0 10px rgba(244, 67, 54, 0.1);
}
.toggle-switch {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.toggle-switch:not(.form-group) {
  margin-bottom: 0;
}
.toggle-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.toggle-switch-content {
  -ms-flex: 1;
  flex: 1;
  margin-left: 0.5rem;
}
.toggle-switch-label {
  position: relative;
  display: block;
  width: 3rem;
  height: 2rem;
  background-color: var(--cbBorderColor);
  background-clip: content-box;
  border: 0.125rem solid transparent;
  border-radius: 6.1875rem;
  transition: 0.3s;
}
.toggle-switch-indicator {
  position: absolute;
  left: 0.125rem;
  bottom: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  -webkit-transform: initial;
  transform: initial;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  border-radius: 50%;
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  transition: 0.3s;
}
.toggle-switch-input:checked + .toggle-switch-label {
  background-color: #092ace;
}
.toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
  -webkit-transform: translate3d(1.025rem, 50%, 0);
  transform: translate3d(1.025rem, 50%, 0);
}
.toggle-switch-input.is-valid + .toggle-switch-label {
  background-color: #27ae60;
}
.toggle-switch-input.is-invalid + .toggle-switch-label {
  background-color: #f44336;
}
.toggle-switch-input:disabled + .toggle-switch-label {
  background-color: rgba(231, 234, 243, 0.5);
}
.toggle-switch-input:checked:disabled + .toggle-switch-label {
  background-color: rgba(9, 42, 206, 0.5);
}
.custom-file-boxed {
  position: relative;
  width: 100%;
  text-align: center;
  border: 0.1rem dashed var(--cbBorderColor);
  cursor: pointer;
  padding: 3rem 3rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}
.custom-file-boxed:hover {
  background-color: #f8fafd;
}
.custom-file-boxed-label {
  text-align: center;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
}
.custom-file-boxed h5,
.custom-file-boxed p {
  font-weight: normal !important;
}
.icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.09375rem;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.3125rem;
}
.icon-circle {
  border-radius: 50%;
}
.icon.icon-xs {
  font-size: 0.875rem;
  width: 1.53125rem;
  height: 1.53125rem;
}
.icon-soft-dark {
  color: #111b51;
  background: rgba(17, 27, 81, 0.1);
}
.nav-link {
  color: #000;
}
.nav-link:hover {
  color: #061b85;
}
.nav-link.active {
  color: #092ace;
}
.nav-subtitle {
  display: block;
  color: #677788;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
}
.nav-tabs .nav-link {
  border-bottom-width: 0.0625rem;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  padding: 1rem 1rem;
  margin-bottom: 0;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom-width: 0.2rem;
  margin-bottom: -2px;
}
.nav-pills .nav-item:not(:first-child) {
  margin-left: 0.25rem;
}
.nav-pills .nav-item:not(:last-child) {
  margin-right: 0.25rem;
}
.nav-pills:not(.nav-segment) .nav-link:hover {
  color: #000;
  background-color: rgba(189, 197, 209, 0.3);
}
.nav-pills:not(.nav-segment) .nav-link.active {
  color: #fff;
  background-color: #092ace;
}
.nav-link-toggle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.nav-link-toggle::after,
.nav-link-toggle[data-toggle='collapse']::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: '';
  margin-left: auto;
}
.nav-pills .show > .nav-link-toggle:not(:hover)::after,
.nav-pills .show > .nav-link-toggle:not(:hover)[data-toggle='collapse']::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.nav-item.active .nav-link {
  color: #092ace;
}
.nav-icon {
  font-size: 1.5rem;
  line-height: 1.4;
  opacity: 0.7;
  -ms-flex: 0 0 2.25rem;
  flex: 0 0 2.25rem;
}
.nav-compact-icon {
  text-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.nav-compact-icon > .nav-item {
  margin: 0.25rem 0;
}
.nav-compact-icon > .nav-item:not(:last-child) {
  margin-right: 0;
}
.nav-compact-icon .nav-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 2.625rem;
  height: 2.625rem;
  font-size: 0.9296875rem;
  color: #000;
  background-color: transparent;
  opacity: 1;
  margin-right: 0;
  border-radius: 0.3125rem;
  text-align: center;
}
.nav-compact-icon .nav-icon.active,
.nav-compact-icon .nav-icon:hover {
  color: #061b85;
  background-color: rgba(9, 42, 206, 0.1);
}
.nav-compact-icon .nav-link.active .nav-icon {
  color: #061b85;
  background-color: rgba(9, 42, 206, 0.1);
}
.nav-compact-icon-circle .nav-icon {
  border-radius: 50%;
}
.nav-segment {
  position: relative;
  background-color: #f8fafd;
  padding: 0.25rem 0.25rem;
  border-radius: 0.3125rem;
}
.nav-segment:not(.nav-fill) {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.nav-segment .nav-link {
  color: #000;
  font-size: 0.8125rem;
  padding: 0.4375rem 0.65625rem;
  border-radius: 0.3125rem;
}
.nav-segment .nav-link:hover {
  color: #092ace;
}
.nav-segment .nav-link.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}
.nav-segment.nav-pills {
  border-radius: 6.1875rem;
}
.nav-segment.nav-pills .nav-link {
  border-radius: 6.1875rem;
}
@media (max-width: 575.98px) {
  .nav-sm-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.legend-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  margin-right: 0.4375rem;
}
.popover {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
}
.popover-header {
  font-size: 1rem;
  font-weight: 600;
}
.popover-dark {
  background-color: #111b51;
}
.popover-dark .popover-header {
  color: #fff;
  background-color: #111b51;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}
.popover-dark .popover-body {
  color: #bdc5d1;
}
.page-link {
  cursor: pointer;
  text-align: center;
  /*min-width: 2.25rem;*/
}
.page-item:not(.active) .page-link:hover {
  color: #092ace;
}
.page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.page-item .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.page-item .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.progress-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #f8fafd;
  width: 0.5rem;
  height: 12.5rem;
}
.modal {
  padding-right: 0 !important;
}
.modal-header {
  -ms-flex-align: center;
  align-items: center;
  border-width: 0;
  padding-bottom: 0;
}
.modal-header .close {
  padding: 0.25rem 0.25rem;
  margin: 0 0 0 auto;
}
.modal-footer > * {
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .modal-lg .modal-header {
    padding-top: 2.25rem;
  }
  .modal-lg .modal-footer,
  .modal-lg .modal-header {
    padding-right: 2.25rem;
    padding-left: 2.25rem;
  }
  .modal-lg .modal-body {
    padding: 2.25rem;
  }
  .modal-lg .modal-footer {
    padding-bottom: 2.25rem;
  }
}
.step {
  position: relative;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}
.step-text:last-child {
  color: #000;
  margin-bottom: 0;
}
.step .step-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1.5rem;
}
.step .step-content-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.step .step-content {
  -ms-flex: 1;
  flex: 1;
}
.step-item.collapse:not(.show) {
  display: none;
}
.step-divider {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  height: 1rem;
  font-weight: 600;
}
.step-divider::after {
  position: absolute;
  top: 1.46875rem;
  left: 1.3125rem;
  height: calc(100% - 0.53125rem);
  border-left: 0.125rem solid var(--cbBorderColor);
  content: '';
}
.step .step-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.09375rem;
  font-weight: 600;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.step .step-icon::after {
  position: absolute;
  top: 3.09375rem;
  left: 1.3125rem;
  height: calc(100% - 2.15625rem);
  border-left: 0.125rem solid var(--cbBorderColor);
  content: '';
}
.step .step-icon-pseudo::before {
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #97a4af;
  border-radius: 50%;
  content: '';
}
.step-icon-sm .step-icon,
.step-icon-sm.step-icon {
  font-size: 0.984375rem;
  width: 2.1875rem;
  height: 2.1875rem;
}
.step-icon-sm .step-icon::after,
.step-icon-sm.step-icon::after {
  top: 2.65625rem;
  left: 1.03125rem;
  width: 1.0625rem;
  height: calc(100% - 1.71875rem);
}
.step-icon-sm .step-divider::after {
  left: 1.09375rem;
}
.step .active .step-icon,
.step .active.is-valid .step-icon {
  color: #fff;
  background-color: #092ace;
}
.step .is-valid .step-icon {
  color: #fff;
  background-color: #092ace;
}
.step .is-invalid .step-icon {
  color: #fff;
  background-color: #f44336;
}
.step-icon-soft-primary {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
}
.step-icon-soft-primary.step-icon-pseudo::before {
  background-color: #092ace;
}
.step-icon-soft-info {
  color: #9b51e0;
  background-color: rgba(155, 81, 224, 0.1);
}
.step-icon-soft-info.step-icon-pseudo::before {
  background-color: #9b51e0;
}
.step-icon-soft-warning {
  color: #ff5a2c;
  background-color: rgba(255, 90, 44, 0.1);
}
.step-icon-soft-warning.step-icon-pseudo::before {
  background-color: #ff5a2c;
}
.step-icon-soft-dark {
  color: #111b51;
  background-color: rgba(17, 27, 81, 0.1);
}
.step-icon-soft-dark.step-icon-pseudo::before {
  background-color: #111b51;
}
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .table-nowrap td,
  .table-nowrap th {
    white-space: normal;
  }
}
.bg-soft-primary {
  background-color: rgba(9, 42, 206, 0.1);
}
.g-1 {
  margin-left: -0.1875rem;
  margin-right: -0.1875rem;
}
.g-1 > * {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.g-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.g-1 > * {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.gx-2 {
  margin-left: -0.4375rem;
  margin-right: -0.4375rem;
}
.gx-2 > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.opacity {
  opacity: 0.6;
}
.min-h-100 {
  min-height: 100%;
}
.shadow-soft {
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25) !important;
}
.text-cap {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.03125rem;
}
.font-size-sm {
  font-size: 0.8125rem;
}
.text-dark {
  color: #1e2022 !important;
}
.text-dark[href]:hover {
  color: #092ace !important;
}
.text-secondary[href]:hover {
  color: #092ace !important;
}
.text-body[href]:hover,
.text-muted[href]:hover {
  color: #061b85 !important;
}
a:hover .text-hover-primary {
  color: #061b85 !important;
}
.z-index-2 {
  z-index: 2;
}
.chartjs-custom {
  position: relative;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}
.hs-chartjs-tooltip-wrap {
  position: absolute;
  z-index: 3;
  transition: opacity 0.2s ease-in-out, left 0.2s ease, top 0.2s ease;
}
.hs-chartjs-tooltip {
  position: relative;
  font-size: 0.75rem;
  background-color: #111b51;
  border-radius: 0.3125rem;
  padding: 0.546875rem 0.875rem;
  transition: opacity 0.2s ease-in-out, left 0.2s ease, top 0.2s ease, top 0s;
}
.hs-chartjs-tooltip::before {
  position: absolute;
  left: calc(50% - 0.5rem);
  bottom: -0.4375rem;
  width: 1rem;
  height: 0.5rem;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='1rem' height='0.5rem' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 50 22.49'%3E%3Cpath fill='%23111B51' d='M0,0h50L31.87,19.65c-3.45,3.73-9.33,3.79-12.85,0.13L0,0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem 0.5rem;
}
.hs-chartjs-tooltip-left {
  left: -130%;
}
.hs-chartjs-tooltip-left::before {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -0.6875rem;
  left: auto;
  -webkit-transform: translateY(-50%) rotate(270deg);
  transform: translateY(-50%) rotate(270deg);
}
.hs-chartjs-tooltip-right {
  left: 30%;
}
.hs-chartjs-tooltip-right::before {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -0.6875rem;
  right: auto;
  -webkit-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}
.hs-chartjs-tooltip-header {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  white-space: nowrap;
}
.hs-chartjs-tooltip-body {
  color: #fff;
}
.chartjs-doughnut-custom {
  position: relative;
}
.chartjs-doughnut-custom-stat {
  position: absolute;
  top: 8rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.hs-chartjs-matrix-legend {
  display: inline-block;
  position: relative;
  height: 2.5rem;
  list-style: none;
  padding-left: 0;
}
.hs-chartjs-matrix-legend-item {
  width: 0.625rem;
  height: 0.625rem;
  display: inline-block;
}
.hs-chartjs-matrix-legend-min {
  position: absolute;
  left: 0;
  bottom: 0;
}
.hs-chartjs-matrix-legend-max {
  position: absolute;
  right: 0;
  bottom: 0;
}
.circle-custom-text {
  z-index: 1;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.datatable-custom .dataTables_filter,
.datatable-custom .dataTables_info,
.datatable-custom .dataTables_length,
.datatable-custom .dataTables_paginate {
  display: none;
}
.datatable-custom .sorting,
.datatable-custom .sorting_asc,
.datatable-custom .sorting_desc {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.datatable-custom .sorting:after,
.datatable-custom .sorting_asc:after,
.datatable-custom .sorting_desc:after {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  width: 0.7rem;
  height: 0.7rem;
  background: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23dde1ee'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23dde1ee'/%3E%3C/svg%3E%0A");
  content: '';
  margin-left: 0.5rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.datatable-custom .sorting_asc:after {
  background: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23dde1ee'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23092ACE'/%3E%3C/svg%3E%0A");
  content: '';
}
.datatable-custom .sorting_desc:after {
  background: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23092ACE'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23dde1ee'/%3E%3C/svg%3E%0A");
  content: '';
}
.datatable-custom-pagination {
  margin-bottom: 0;
}
.daterangepicker-custom-input {
  cursor: pointer;
}
.daterangepicker {
  border: none;
  font-family: Inter, sans-serif;
  z-index: 98;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  margin-top: 0.5rem;
  border-radius: 0.75rem;
}
.daterangepicker::after,
.daterangepicker::before {
  top: -0.5rem;
  border-bottom-color: #fff;
  border-bottom-width: 0.5rem;
}
.daterangepicker.drop-up::before {
  border-top-color: #fff;
}
.daterangepicker .drp-calendar {
  max-width: 18.75rem;
}
.daterangepicker .drp-calendar.left {
  padding: 1rem 1rem;
}
.daterangepicker .drp-calendar.right {
  padding: 1rem 1rem;
}
.daterangepicker th.next,
.daterangepicker th.prev {
  min-width: auto;
  width: 2.1875rem;
  height: 2.1875rem;
  color: #092ace;
  font-size: 0.875rem;
  border-radius: 50%;
}
.daterangepicker th.next:hover,
.daterangepicker th.prev:hover {
  background-color: rgba(9, 42, 206, 0.1);
}
.daterangepicker th.next:hover .daterangepicker-custom-arrow,
.daterangepicker th.prev:hover .daterangepicker-custom-arrow {
  color: #092ace;
}
.daterangepicker .calendar-table table {
  border-collapse: separate;
  border-spacing: 0 0.25rem;
}
.daterangepicker .calendar-table th:not(.month) {
  color: #97a4af;
  font-weight: 600;
  text-transform: uppercase;
}
.daterangepicker .calendar-table th.month {
  font-size: 0.875rem;
  font-weight: 600;
}
.daterangepicker .calendar-table td {
  width: 2.1875rem;
  height: 2.1875rem;
  font-size: 0.875rem;
  line-height: 1.9375rem;
}
.daterangepicker td.available:not(.in-range) {
  border-radius: 50%;
}
.daterangepicker td.available:hover:not(.active) {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
}
.daterangepicker td.in-range,
.daterangepicker td.in-range.available:hover {
  color: #fff;
  background-color: #092ace;
}
.daterangepicker td.today.start-date.end-date {
  border-radius: 50%;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  color: #fff;
  background-color: #092ace;
}
.daterangepicker td.active.start-date,
.daterangepicker td.active:hover.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.daterangepicker td.active.end-date,
.daterangepicker td.active:hover.end-date {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  color: var(--cbBorderColor);
}
.daterangepicker .drp-buttons,
.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-color: var(--cbBorderColor);
}
.daterangepicker .drp-buttons {
  padding: 1rem 1rem;
}
.daterangepicker .drp-buttons .btn {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.546875rem 0.875rem;
}
.daterangepicker .cancelBtn {
  background-color: #fff;
  border-color: var(--cbBorderColor);
}
.daterangepicker .cancelBtn.active,
.daterangepicker .cancelBtn:active,
.daterangepicker .cancelBtn:focus,
.daterangepicker .cancelBtn:hover {
  color: #061b85;
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.daterangepicker .drp-selected {
  color: #71869d;
}
@media (max-width: 575.98px) {
  .daterangepicker .drp-selected {
    display: block;
    margin-bottom: 0.5rem;
  }
}
.daterangepicker .ranges ul {
  min-width: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.daterangepicker .ranges li {
  color: #71869d;
  font-size: 0.875rem;
  line-height: 1.6;
  padding: 0.375rem 1.5rem;
}
.daterangepicker .ranges li:hover {
  color: #061b85;
  background-color: transparent;
}
.daterangepicker .ranges li.active {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  cursor: pointer;
  width: 3.5rem;
  font-size: 0.8125rem;
  color: #1e2022;
  background-color: transparent;
  border-color: var(--cbBorderColor);
  padding: 0.25rem 0.25rem;
  border-radius: 0.3125rem;
}
.daterangepicker select.ampmselect:hover,
.daterangepicker select.hourselect:hover,
.daterangepicker select.minuteselect:hover,
.daterangepicker select.secondselect:hover {
  color: #061b85;
}
.dropzone-custom {
  cursor: pointer;
}
.dropzone-custom .dz-message {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}
.dropzone-custom .dz-details {
  margin-bottom: 1rem;
}
.dropzone-custom .dz-file-preview {
  background-color: #fff;
  border-radius: 0.3125rem;
  padding: 1rem 1rem;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}
.dropzone-custom .dz-file-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.dropzone-custom .dz-filename {
  margin-bottom: 0.25rem;
}
.dropzone-custom .dz-img {
  max-width: 2.625rem;
  border-radius: 0.3125rem;
  margin-right: 0.75rem;
}
.dropzone-custom .dz-img-inner {
  border-radius: 0.3125rem;
}
.dropzone-custom .dz-close-icon,
.dropzone-custom .dz-size {
  color: #000;
}
.dropzone-custom .dz-title {
  font-size: 0.75rem;
  font-weight: 600;
}
.dropzone-custom .dz-size {
  font-size: 80%;
}
.dropzone-custom .dz-file-initials {
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  line-height: 2.625rem;
  font-weight: 600;
  font-size: 0.9296875rem;
  color: #092ace;
  text-align: center;
  background-color: rgba(9, 42, 206, 0.1);
  border-radius: 0.3125rem;
  margin-right: 0.75rem;
}
.dropzone-custom [data-dz-thumbnail]:not([src]) {
  display: none;
  margin-bottom: 0;
}
.dropzone-custom .dz-progress {
  margin-bottom: 1rem;
}
.dropzone-custom .dz-processing .dz-error-mark,
.dropzone-custom .dz-processing .dz-success-mark {
  display: none;
}
.dropzone-custom .dz-processing.dz-error .dz-error-mark,
.dropzone-custom .dz-processing.dz-success .dz-success-mark {
  display: block;
}
.dropzone-custom .dz-processing .dz-error-mark {
  color: #f44336;
}
.dropzone-custom .dz-processing .dz-success-mark {
  color: #27ae60;
}
.fancybox-custom .fancybox-slide.animated {
  display: block;
  opacity: 0;
  z-index: 0;
}
.fancybox-custom .fancybox-slide.animated.fancybox-slide--current {
  opacity: 1;
  z-index: 1;
}
.fancybox-custom .fancybox-content {
  background-color: transparent;
}
.fancybox-custom .fancybox-bg {
  background-color: #1e2022;
}
.fancybox-custom .fancybox-button svg {
  margin-bottom: 0;
}
.fancybox-custom .fancybox-progress {
  background-color: #092ace;
}
.fancybox-blur aside,
.fancybox-blur footer,
.fancybox-blur header,
.fancybox-blur main {
  -webkit-filter: blur(30px);
  filter: blur(30px);
}
.fullcalendar-custom .fc-list-empty {
  background-color: #f8fafd;
}
.fullcalendar-custom .fc-daygrid-dot-event.fc-event-mirror,
.fullcalendar-custom .fc-daygrid-dot-event:hover {
  color: #092ace;
}
.fullcalendar-custom .fc-daygrid-dot-event {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1e2022;
  background-color: rgba(9, 42, 206, 0.1);
  border-radius: 0.3125rem;
}
.fullcalendar-custom .fc-v-event .fc-event-main {
  color: #1e2022;
  font-weight: 600;
}
.fullcalendar-custom .fc-h-event,
.fullcalendar-custom .fc-v-event {
  cursor: pointer;
  border: none;
}
.fullcalendar-custom .fc-h-event {
  background-color: rgba(9, 42, 206, 0.1);
}
.fullcalendar-custom .fc-daygrid-dot-event .fc-event-title,
.fullcalendar-custom .fc-daygrid-event {
  font-weight: 600;
}
.fullcalendar-custom .fc-event-resizable,
.fullcalendar-custom .fc-timegrid-event-harness .fc-timegrid-event {
  max-width: 15rem;
}
.fullcalendar-custom .fc-daygrid-event,
.fullcalendar-custom .fc-timegrid-event .fc-event-main {
  padding: 0.3125rem 0.3125rem;
}
.fullcalendar-custom .fc-daygrid-block-event .fc-event-time,
.fullcalendar-custom .fc-daygrid-block-event .fc-event-title {
  color: #1e2022;
  padding: 0;
}
.fullcalendar-custom .fc-daygrid-block-event .fc-event-time:hover,
.fullcalendar-custom .fc-daygrid-block-event .fc-event-title:hover {
  color: #092ace;
}
.fullcalendar-custom .fc-daygrid-block-event .fc-event-time.fc-sticky,
.fullcalendar-custom .fc-daygrid-block-event .fc-event-title.fc-sticky {
  padding-right: 0;
  padding-left: 0;
}
.fullcalendar-custom .fc-daygrid-event .fc-event-title {
  padding: 0.125rem 0;
}
.fullcalendar-custom .fc-event-time {
  font-size: 0.75rem;
}
.fullcalendar-custom .fc-event-title.fc-sticky {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8125rem;
}
.fullcalendar-custom .fc-daygrid-event {
  margin: 0.125rem 0;
}
.fullcalendar-custom.fc table,
.fullcalendar-custom.fc td,
.fullcalendar-custom.fc th {
  border-color: var(--cbBorderColor);
}
.fullcalendar-custom.fc .fc-view:not(.fc-timeGridDay-view) .fc-daygrid-day {
  height: 10rem;
}
.fullcalendar-custom.fc .fc-daygrid-day-frame {
  padding: 0.25rem;
}
.fullcalendar-custom.fc .fc-col-header-cell-cushion,
.fullcalendar-custom.fc .fc-timegrid-slot-label-cushion {
  font-size: 0.765625rem;
  color: #97a4af;
  text-transform: uppercase;
}
.fullcalendar-custom.fc .fc-col-header-cell-cushion {
  display: block;
  background-color: transparent;
  padding: 0.5rem 0.5rem;
}
.fullcalendar-custom.fc .fc-day-today .fc-col-header-cell-cushion {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.fullcalendar-custom.fc .fc-daygrid-day-top {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0.125rem;
}
.fullcalendar-custom.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}
.fullcalendar-custom.fc
  .fc-day-other
  .fc-daygrid-day-top
  .fc-daygrid-day-number {
  color: var(--cbBorderColor);
}
.fullcalendar-custom.fc .fc-daygrid-day-number {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 2.1875rem;
  height: 2.1875rem;
  color: #111b51;
  border-radius: 50%;
}
.fullcalendar-custom.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}
.fullcalendar-custom.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: #fff;
  background-color: #092ace;
}
.fullcalendar-custom.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(9, 42, 206, 0.1);
}
.fullcalendar-custom.fc .fc-cell-shaded,
.fullcalendar-custom.fc .fc-day-disabled {
  background-color: rgba(231, 234, 243, 0.5);
}
.fullcalendar-custom.fc .fc-button {
  font-size: 0.875rem;
  color: #000;
  background-color: transparent;
  border-color: var(--cbBorderColor);
  text-transform: capitalize;
  padding: 0.4375rem 0.65625rem;
}
.fullcalendar-custom.fc .fc-button .fc-icon {
  font-size: 0.875rem;
  vertical-align: baseline;
}
.fullcalendar-custom.fc .fc-button.active,
.fullcalendar-custom.fc .fc-button.focus,
.fullcalendar-custom.fc .fc-button:active,
.fullcalendar-custom.fc .fc-button:focus,
.fullcalendar-custom.fc .fc-button:hover {
  color: #061b85;
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.fullcalendar-custom.fc .fc-button.fc-button-active {
  color: #fff;
  background-color: #092ace;
  border-color: #092ace;
}
.fullcalendar-custom.fc .fc-button.fc-button-primary:focus,
.fullcalendar-custom.fc
  .fc-button.fc-button-primary:not(:disabled).fc-button-active:focus,
.fullcalendar-custom.fc
  .fc-button.fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}
.fullcalendar-custom.fc
  .fc-button.fc-button-primary:not(:disabled).fc-button-active,
.fullcalendar-custom.fc .fc-button.fc-button-primary:not(:disabled):active {
  color: #fff;
  background-color: #092ace;
  border-color: #092ace;
}
.fullcalendar-custom.fc .fc-toolbar-title {
  font-size: 1.1484375rem;
}
.fc-theme-standard .fc-list {
  border-color: var(--cbBorderColor);
}
.fc-theme-standard .fc-list .fc-list-event:hover td {
  background-color: transparent;
}
.fc-theme-standard .fc-list .fc-list-day-text {
  color: #1e2022;
}
.fc-theme-standard .fc-list .fc-list-day-side-text {
  color: #000;
  font-weight: 400;
}
.fullcalendar-custom.fc .fc-popover {
  border-color: rgba(231, 234, 243, 0.7);
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075);
  border-radius: 0.75rem;
}
.fullcalendar-custom.fc .fc-popover-header {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  background-color: #f8fafd;
  padding: 0.5rem 0.75rem;
}
.fullcalendar-custom.fc .fc-more-popover .fc-popover-body {
  padding: 0.5rem 0.75rem;
}
.fullcalendar-custom.fc .fc-more-popover .fc-popover-body .fc-sticky {
  position: static;
}
.fullcalendar-custom.fc .fc-popover-title {
  margin: 0;
}
.fullcalendar-custom.fc .fc-popover-close:hover {
  color: #061b85;
}
.flatpickr-custom {
  position: relative;
  width: 100% !important;
}
.flatpickr-custom .flatpickr-calendar {
  top: calc(1.6em + 1.21875rem) !important;
  left: 0 !important;
  width: auto;
}
.form-control[readonly] {
  background-color: transparent;
}
.flatpickr-calendar {
  width: 21.125rem;
  padding: 1rem 1rem;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.75rem;
  margin-top: 0.5rem;
}
.flatpickr-calendar::before {
  left: 1rem;
  border-width: 0.5rem;
  margin: 0 0.5rem;
}
.flatpickr-calendar.arrowTop::before {
  border-bottom-color: #fff;
}
.flatpickr-calendar::after {
  display: none;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  animation: fadeInUp 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar .flatpickr-months {
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.75rem;
}
.flatpickr-calendar .flatpickr-current-month,
.flatpickr-calendar .flatpickr-next-month,
.flatpickr-calendar .flatpickr-prev-month {
  padding: 0;
}
.flatpickr-calendar .flatpickr-current-month {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
}
.flatpickr-calendar .flatpickr-next-month,
.flatpickr-calendar .flatpickr-prev-month {
  position: static;
  color: #000;
  width: 2.1875rem;
  height: 2.1875rem;
  line-height: 2.1875rem;
  background-color: transparent;
  border-radius: 50%;
}
.flatpickr-calendar .flatpickr-next-month:hover,
.flatpickr-calendar .flatpickr-prev-month:hover {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
}
.flatpickr-calendar .flatpickr-weekday {
  color: #97a4af;
  text-transform: uppercase;
  font-weight: 600;
}
.flatpickr-calendar .rangeMode .flatpickr-day {
  margin-top: 0.25rem;
}
.flatpickr-calendar .flatpickr-monthDropdown-months {
  font-size: 0.875rem;
  font-weight: 600;
}
.flatpickr-calendar .flatpickr-monthDropdown-months:hover {
  background-color: transparent;
}
.flatpickr-calendar .flatpickr-current-month input.cur-year {
  font-size: 0.875rem;
  font-weight: 600;
}
.flatpickr-calendar .flatpickr-day {
  color: #1e2022;
  border: none;
}
.flatpickr-calendar .flatpickr-day:focus,
.flatpickr-calendar .flatpickr-day:hover {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1);
}
.flatpickr-calendar .flatpickr-day.endRange,
.flatpickr-calendar .flatpickr-day.endRange.inRange,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.endRange:focus,
.flatpickr-calendar .flatpickr-day.endRange:hover,
.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.selected.inRange,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.flatpickr-calendar .flatpickr-day.selected:focus,
.flatpickr-calendar .flatpickr-day.selected:hover,
.flatpickr-calendar .flatpickr-day.startRange,
.flatpickr-calendar .flatpickr-day.startRange.inRange,
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.startRange:focus,
.flatpickr-calendar .flatpickr-day.startRange:hover {
  color: #fff;
  background-color: #092ace;
  border-color: #092ace;
}
.flatpickr-calendar .flatpickr-day.inRange {
  color: #fff;
  background-color: #092ace;
  box-shadow: -0.35rem 0 0 #092ace, 0.35rem 0 0 #092ace;
}
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:hover,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay:hover {
  color: #fff;
  border-color: #092ace;
  background-color: #092ace;
}
.flatpickr-calendar .flatpickr-day.today {
  color: #fff;
  border-color: transparent;
  background-color: #092ace;
}
.flatpickr-calendar .flatpickr-day.nextMonthDay,
.flatpickr-calendar .flatpickr-day.prevMonthDay {
  color: var(--cbBorderColor);
}
.flatpickr-calendar .flatpickr-day.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.prevMonthDay:hover {
  color: #bdc5d1;
  background-color: var(--cbBorderColor);
  border-color: var(--cbBorderColor);
}
.flatpickr-calendar .flatpickr-day.disabled {
  color: #8c98a4;
}
.flatpickr-calendar .flatpickr-day.disabled:hover {
  color: #8c98a4;
  background-color: var(--cbBorderColor);
  border-color: var(--cbBorderColor);
}
.flatpickr-calendar
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.flatpickr-calendar
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.flatpickr-calendar
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -0.35rem 0 0 #092ace, 0.35rem 0 0 #092ace;
}
.flatpickr-calendar .numInputWrapper span.arrowDown,
.flatpickr-calendar .numInputWrapper span.arrowUp {
  display: none;
}
.flatpickr-calendar .numInputWrapper:hover {
  background-color: transparent;
}
.hs-form-search-menu-content {
  position: absolute;
  display: block !important;
  opacity: 0;
  pointer-events: none;
}
.hs-form-search-menu-hidden {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}
.hs-form-search-menu-initialized {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.hs-loader-wrapper {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  display: none;
  background-color: #fff;
  border-radius: 0.3125rem;
}
.hs-loader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.hs-unfold {
  position: relative;
  display: inline-block;
}
.hs-unfold-content {
  display: block !important;
  opacity: 0;
  pointer-events: none;
}
.hs-unfold-content-initialized {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.hs-unfold-hidden {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}
.hs-unfold-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(17, 27, 81, 0.25);
}
.hs-unfold-content.hs-unfold-reverse-y {
  top: auto;
  bottom: 100%;
  margin-top: 0;
}
.hs-unfold-has-submenu {
  top: 0;
  right: calc(100% + 0.75rem);
  left: auto;
  margin-top: 0;
}
.hs-unfold-has-submenu::after {
  position: absolute;
  top: 0;
  display: block;
  left: calc(100% - 0.0625rem);
  width: 1rem;
  height: 100%;
  content: '';
}
.hs-fullscreen {
  position: fixed !important;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh !important;
  max-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 0;
}
.hs-fullscreen-on {
  overflow: hidden;
}
.jvectormap-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}
.jvectormap-tip,
.jvectormap-zoomin,
.jvectormap-zoomout {
  position: absolute;
  border-radius: 0.25rem;
}
.jvectormap-tip {
  z-index: 9999;
  display: none;
  color: #1e2022;
  background-color: #fff;
  padding: 0.546875rem 0.875rem;
  margin: -0.875rem;
  box-shadow: 0 6px 24px 0 rgba(140, 152, 164, 0.125);
}
.jvectormap-tip::before {
  position: absolute;
  left: calc(50% - 0.5rem);
  bottom: -0.4375rem;
  width: 1rem;
  height: 0.5rem;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='1rem' height='0.5rem' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 50 22.49'%3E%3Cpath fill='%23ffffff' d='M0,0h50L31.87,19.65c-3.45,3.73-9.33,3.79-12.85,0.13L0,0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem 0.5rem;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #000;
  background-color: #fff;
  border: 0.0625rem solid var(--cbBorderColor);
  width: 1.53125rem;
  height: 1.53125rem;
  cursor: pointer;
}
.jvectormap-zoomin:focus,
.jvectormap-zoomin:hover,
.jvectormap-zoomout:focus,
.jvectormap-zoomout:hover {
  color: #061b85;
}
.jvectormap-zoomin {
  top: 1rem;
  left: 1rem;
}
.jvectormap-zoomout {
  top: 2.84375rem;
  left: 1rem;
}
.leaflet-bar {
  border: none;
  box-shadow: none;
}
.leaflet-bar a,
.leaflet-bar a:hover {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 1.53125rem;
  height: 1.53125rem;
  line-height: 1.6;
  font-size: 0.8125rem;
  font-family: Inter, sans-serif;
  color: #000;
  background-color: #fff;
  border: 0.0625rem solid var(--cbBorderColor);
  border-radius: 0.3125rem;
  text-indent: inherit;
}
.leaflet-bar a:first-child {
  margin-bottom: 0.25rem;
}
.leaflet-bar a:last-child {
  border-bottom: 0.0625rem solid var(--cbBorderColor);
}
.leaflet-bar a:hover {
  color: #092ace;
}
.leaflet-popup {
  margin-bottom: 3rem;
}
.quill-custom {
  position: relative;
  width: 100%;
}
.quill-custom .ql-container {
  position: static;
}
.quill-custom .ql-container.ql-snow,
.quill-custom .ql-toolbar.ql-snow {
  border-color: var(--cbBorderColor);
}
.quill-custom .ql-toolbar.ql-snow .ql-picker-label {
  border-width: 0;
}
.quill-custom .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #000;
  border-radius: 0.25rem;
}
.quill-custom .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #000;
}
.quill-custom .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-width: 0;
  border-radius: 0.75rem;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  padding: 0;
  overflow: hidden;
  margin-top: 0.5rem;
}
.quill-custom
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item {
  padding: 0.375rem 1.5rem;
}
.quill-custom
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item:hover {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}
.quill-custom .ql-toolbar.ql-snow {
  padding: 0.75rem 1.3125rem;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.quill-custom .ql-container.ql-snow {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.quill-custom.ql-toolbar-bottom .ql-toolbar.ql-snow {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.quill-custom.ql-toolbar-bottom .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border: 0.0625rem solid var(--cbBorderColor);
}
.quill-custom .ql-toolbar-list {
  line-height: normal;
  margin-bottom: 0;
}
.quill-custom .ql-formats:first-child {
  padding-left: 0;
}
.quill-custom .ql-formats:first-child button {
  margin-right: 0.5rem;
}
.quill-custom .ql-formats:first-child button:first-child {
  margin-left: -0.25rem;
}
.quill-custom .ql-editor {
  position: relative;
  min-height: 10rem;
  padding: 1.3125rem 1.3125rem;
}
.quill-custom .ql-editor > * {
  font-size: 0.875rem;
  font-family: Inter, sans-serif;
  color: #1e2022;
}
.quill-custom .ql-editor.ql-blank::before {
  left: 1.3125rem;
  color: #97a4af;
  font-family: Inter, sans-serif;
  font-style: normal;
}
.quill-custom .ql-snow a.btn-primary {
  color: #fff;
}
.quill-custom .ql-snow.ql-toolbar .ql-fill {
  fill: #000;
}
.quill-custom .ql-snow.ql-toolbar .ql-stroke {
  stroke: #000;
}
.quill-custom .ql-snow.ql-toolbar button:hover {
  color: #092ace;
}
.quill-custom .ql-snow.ql-toolbar button:hover .ql-fill {
  fill: #092ace;
}
.quill-custom .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: #092ace;
}
.quill-custom .ql-snow .ql-toolbar button,
.quill-custom .ql-snow.ql-toolbar button {
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.25rem 0.25rem;
}
.quill-custom .ql-snow .ql-tooltip {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  z-index: 1;
  min-width: 20rem;
  border-width: 0;
  text-align: center;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  padding: 1.5rem 1.5rem;
  border-radius: 0.3125rem;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.quill-custom .ql-snow .ql-tooltip::before {
  display: block;
  text-align: center;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 0.0625rem solid var(--cbBorderColor);
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  margin-right: 0;
}
.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}
.quill-custom .ql-snow .ql-tooltip a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type='text'] {
  min-width: 20rem;
  font-size: 1rem;
  line-height: normal;
  border: 0.0625rem solid var(--cbBorderColor);
  padding: 0.875rem 1.09375rem;
  margin-bottom: 1.5rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}
.quill-custom .ql-snow .ql-tooltip a.ql-preview {
  display: block;
}
.quill-custom .ql-snow .ql-tooltip a.ql-preview:hover {
  border-color: #092ace;
}
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type='text'] {
  height: auto;
  display: block;
  font-family: Inter, sans-serif;
}
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type='text']:focus {
  border-color: rgba(9, 42, 206, 0.4);
  box-shadow: 0 0 10px rgba(9, 42, 206, 0.1);
}
.quill-custom .ql-snow .ql-action {
  display: inline-block;
  color: #fff;
  background-color: #092ace;
  font-size: 0.875rem;
  line-height: normal;
  padding: 0.875rem 1.09375rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}
.quill-custom .ql-snow .ql-action:hover {
  background-color: #0823ac;
}
.quill-custom .ql-snow .ql-tooltip a.ql-action::after,
.quill-custom .ql-snow .ql-tooltip a.ql-remove::before {
  padding-right: 0;
  margin-left: 0;
  border-right: none;
}
.quill-custom .ql-snow .ql-tooltip a.ql-remove {
  border: 0.0625rem solid var(--cbBorderColor);
  font-size: 0.875rem;
  padding: 0.875rem 1.09375rem;
  border-radius: 0.3125rem;
  margin-left: 0.5rem;
  transition: 0.3s;
}
.quill-custom .ql-snow .ql-tooltip a.ql-remove:hover {
  color: #061b85;
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.select2-custom {
  position: relative;
}
.select2-custom .select2-custom-hide {
  display: none;
}
.select2-dropdown {
  border-color: var(--cbBorderColor);
}
.select2-container--open .select2-dropdown--below {
  border-radius: 0.3125rem;
  border-top: 0.0625rem solid var(--cbBorderColor);
  margin-top: 0.5rem;
}
.select2-selection__placeholder {
  color: #97a4af;
}
.select2-selection--multiple .select2-selection__placeholder {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: 0.75rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sortablejs-custom.sortable-ghost {
  opacity: 0.3;
}
.sortablejs-custom-rotate.sortable-fallback {
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity: 1 !important;
}
.sortablejs-custom-handle {
  cursor: pointer;
}
.tagify {
  --tag-bg: unset;
  --tag-hover: unset;
  -ms-flex-align: center;
  align-items: center;
  border-color: var(--cbBorderColor);
  border-radius: 0.25rem;
}
.tagify:hover {
  border-color: var(--cbBorderColor);
}
.tagify.tagify--focus {
  border-color: rgba(9, 42, 206, 0.4);
}
.tagify__tag--hide {
  height: 0;
}
.tagify__tag {
  margin: 0.25rem 0.25rem 0.25rem 0;
  background-color: var(--cbBorderColor);
  border-radius: 0.25rem;
}
.tagify__tag:first-child {
  margin-left: 0;
}
.tagify__tag > div {
  color: #1e2022;
  padding: 0.3125rem 0.875rem;
  border-radius: 0.25rem;
}
.tagify__tag__removeBtn {
  color: #71869d;
  width: 0.875rem;
  height: 0.875rem;
  line-height: 0.875rem;
}
.tagify__tag__removeBtn:hover {
  background-color: #f44336;
}
.tagify__tag__removeBtn:hover + div::before {
  box-shadow: none !important;
}
.tagify__tag__removeBtn:hover + div .tagify__tag-text {
  opacity: 1;
}
.tagify-form-control {
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 0 0.25rem;
}
.tagify-form-control.tagify--empty {
  padding: 0 0.875rem;
}
.tagify__input:first-child {
  padding-left: 0;
  margin-left: 0;
}
.tagify__input::before {
  color: #97a4af;
  line-height: 1.4;
}
.tagify__input .tagify__tag > div {
  padding: 0.3125rem 0.875rem;
  line-height: normal;
}
.tagify__dropdown__menu {
  overflow: hidden;
  border: 0.0625rem solid var(--cbBorderColor);
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  margin-top: -1px;
}
.tagify__dropdown__wrapper {
  border: none;
  box-shadow: none;
  transition: none !important;
}
.tagify__dropdown__item {
  color: #1e2022;
  padding: 0.546875rem 0.875rem;
  margin: 0;
  border-radius: 0;
}
.tagify__dropdown__item--active {
  background-color: rgba(189, 197, 209, 0.3);
}
body {
  background-color: #f6f8fa;
}
.z-index-10 {
  z-index: 10 !important;
}
.left-n100 {
  left: -100px !important;
}
.left-100 {
  left: 100px !important;
}
.left-50 {
  left: 50px !important;
}
.card-unfold {
  z-index: inherit !important;
}
.avatar-owner {
  width: 1.875rem !important;
  height: 1.875rem !important;
}
.text-black {
  color: var(--black) !important;
}
.font-size-xs {
  font-size: 0.75rem !important;
}
.font-size-sm2 {
  font-size: 0.825rem !important;
}
.font-size-md {
  font-size: 0.9375rem !important;
}
.font-size-normal {
  font-size: 1rem !important;
}
.font-size-ml {
  font-size: 1.0625rem !important;
}
.font-size-lg {
  font-size: 1.125rem !important;
}
.font-size-xl {
  font-size: 1.1875rem !important;
}
.font-size-xxl {
  font-size: 1.25rem !important;
}
.font-size-2xl {
  font-size: 1.75rem !important;
}
.font-size-3xl {
  font-size: 2.5rem !important;
}
.font-size-4xl {
  font-size: 3rem !important;
}
.font-size-6xl {
  font-size: 5rem !important;
}
.font-size-7xl {
  font-size: 6rem !important;
}
.font-size-8xl {
  font-size: 7rem !important;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-semi-bold {
  font-weight: 600 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: 800 !important;
}
@media (min-width: 1200px) {
  .navbar-brand-wrapper {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  body.navbar-vertical-aside-mini-mode .navbar-brand-wrapper {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
}
.navbar-nav .nav-icon {
  font-size: 1.775rem;
}
.text-block {
  color: #000 !important;
  transition: all ease-in-out 0.1s;
}
.text-block:active,
.text-block:focus,
.text-block:hover {
  color: #092ace !important;
}
.img-box img {
  height: auto;
  max-height: 50px;
  max-width: 100%;
}
.img-box img.custom-icon {
  height: 50px !important;
}
.bg-blue {
  background-color: #092ace !important;
}
.bg-blue-5 {
  background-color: rgba(9, 42, 206, 0.05) !important;
}
.bg-soft-blue {
  color: #092ace;
  background-color: rgba(9, 42, 206, 0.1) !important;
}
.bg-hover-blue:hover {
  background-color: rgba(9, 42, 206, 0.05) !important;
}
.text-blue {
  color: #092ace !important;
}
.text-hover-blue:hover {
  color: #092ace !important;
}
.border-blue {
  border-color: #092ace !important;
}
.tooltip-blue.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-blue.bs-tooltip-top .arrow::before {
  border-top-color: #092ace !important;
}
.tooltip-blue.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-blue.bs-tooltip-right .arrow::before {
  border-right-color: #092ace !important;
}
.tooltip-blue.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-blue.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #092ace !important;
}
.tooltip-blue.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-blue.bs-tooltip-left .arrow::before {
  border-left-color: #092ace !important;
}
.tooltip-blue .tooltip-inner {
  background-color: #092ace !important;
}
.custom-checkbox-list-blue
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #092ace !important;
}
.custom-checkbox-list-blue .custom-control-label::before {
  background-color: rgba(9, 42, 206, 0.25) !important;
}
.custom-checkbox-list-blue:hover .custom-control-label::before {
  background-color: rgba(9, 42, 206, 0.375) !important;
}
.custom-checkbox-list-blue:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #092ace !important;
}
.bg-indigo {
  background-color: #5c51e0 !important;
}
.bg-indigo-5 {
  background-color: rgba(92, 81, 224, 0.05) !important;
}
.bg-soft-indigo {
  color: #5c51e0;
  background-color: rgba(92, 81, 224, 0.1) !important;
}
.bg-hover-indigo:hover {
  background-color: rgba(92, 81, 224, 0.05) !important;
}
.text-indigo {
  color: #5c51e0 !important;
}
.text-hover-indigo:hover {
  color: #5c51e0 !important;
}
.border-indigo {
  border-color: #5c51e0 !important;
}
.tooltip-indigo.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-indigo.bs-tooltip-top .arrow::before {
  border-top-color: #5c51e0 !important;
}
.tooltip-indigo.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-indigo.bs-tooltip-right .arrow::before {
  border-right-color: #5c51e0 !important;
}
.tooltip-indigo.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-indigo.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #5c51e0 !important;
}
.tooltip-indigo.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-indigo.bs-tooltip-left .arrow::before {
  border-left-color: #5c51e0 !important;
}
.tooltip-indigo .tooltip-inner {
  background-color: #5c51e0 !important;
}
.custom-checkbox-list-indigo
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #5c51e0 !important;
}
.custom-checkbox-list-indigo .custom-control-label::before {
  background-color: rgba(92, 81, 224, 0.25) !important;
}
.custom-checkbox-list-indigo:hover .custom-control-label::before {
  background-color: rgba(92, 81, 224, 0.375) !important;
}
.custom-checkbox-list-indigo:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #5c51e0 !important;
}
.bg-purple {
  background-color: #9b51e0 !important;
}
.bg-purple-5 {
  background-color: rgba(155, 81, 224, 0.05) !important;
}
.bg-soft-purple {
  color: #9b51e0;
  background-color: rgba(155, 81, 224, 0.1) !important;
}
.bg-hover-purple:hover {
  background-color: rgba(155, 81, 224, 0.05) !important;
}
.text-purple {
  color: #9b51e0 !important;
}
.text-hover-purple:hover {
  color: #9b51e0 !important;
}
.border-purple {
  border-color: #9b51e0 !important;
}
.tooltip-purple.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-purple.bs-tooltip-top .arrow::before {
  border-top-color: #9b51e0 !important;
}
.tooltip-purple.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-purple.bs-tooltip-right .arrow::before {
  border-right-color: #9b51e0 !important;
}
.tooltip-purple.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-purple.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #9b51e0 !important;
}
.tooltip-purple.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-purple.bs-tooltip-left .arrow::before {
  border-left-color: #9b51e0 !important;
}
.tooltip-purple .tooltip-inner {
  background-color: #9b51e0 !important;
}
.custom-checkbox-list-purple
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #9b51e0 !important;
}
.custom-checkbox-list-purple .custom-control-label::before {
  background-color: rgba(155, 81, 224, 0.25) !important;
}
.custom-checkbox-list-purple:hover .custom-control-label::before {
  background-color: rgba(155, 81, 224, 0.375) !important;
}
.custom-checkbox-list-purple:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #9b51e0 !important;
}
.bg-pink {
  background-color: #ff4081 !important;
}
.bg-pink-5 {
  background-color: rgba(255, 64, 129, 0.05) !important;
}
.bg-soft-pink {
  color: #ff4081;
  background-color: rgba(255, 64, 129, 0.1) !important;
}
.bg-hover-pink:hover {
  background-color: rgba(255, 64, 129, 0.05) !important;
}
.text-pink {
  color: #ff4081 !important;
}
.text-hover-pink:hover {
  color: #ff4081 !important;
}
.border-pink {
  border-color: #ff4081 !important;
}
.tooltip-pink.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-pink.bs-tooltip-top .arrow::before {
  border-top-color: #ff4081 !important;
}
.tooltip-pink.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-pink.bs-tooltip-right .arrow::before {
  border-right-color: #ff4081 !important;
}
.tooltip-pink.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-pink.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #ff4081 !important;
}
.tooltip-pink.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-pink.bs-tooltip-left .arrow::before {
  border-left-color: #ff4081 !important;
}
.tooltip-pink .tooltip-inner {
  background-color: #ff4081 !important;
}
.custom-checkbox-list-pink
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #ff4081 !important;
}
.custom-checkbox-list-pink .custom-control-label::before {
  background-color: rgba(255, 64, 129, 0.25) !important;
}
.custom-checkbox-list-pink:hover .custom-control-label::before {
  background-color: rgba(255, 64, 129, 0.375) !important;
}
.custom-checkbox-list-pink:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #ff4081 !important;
}
.bg-red {
  background-color: #f44336 !important;
}
.bg-red-5 {
  background-color: rgba(244, 67, 54, 0.05) !important;
}
.bg-soft-red {
  color: #f44336;
  background-color: rgba(244, 67, 54, 0.1) !important;
}
.bg-hover-red:hover {
  background-color: rgba(244, 67, 54, 0.05) !important;
}
.text-red {
  color: #f44336 !important;
}
.text-hover-red:hover {
  color: #f44336 !important;
}
.border-red {
  border-color: #f44336 !important;
}
.rpt-border-red {
  border-color: #f6dddb !important;
}
.tooltip-red.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-red.bs-tooltip-top .arrow::before {
  border-top-color: #f44336 !important;
}
.tooltip-red.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-red.bs-tooltip-right .arrow::before {
  border-right-color: #f44336 !important;
}
.tooltip-red.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-red.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f44336 !important;
}
.tooltip-red.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-red.bs-tooltip-left .arrow::before {
  border-left-color: #f44336 !important;
}
.tooltip-red .tooltip-inner {
  background-color: #f44336 !important;
}
.custom-checkbox-list-red
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #f44336 !important;
}
.custom-checkbox-list-red .custom-control-label::before {
  background-color: rgba(244, 67, 54, 0.25) !important;
}
.custom-checkbox-list-red:hover .custom-control-label::before {
  background-color: rgba(244, 67, 54, 0.375) !important;
}
.custom-checkbox-list-red:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #f44336 !important;
}
.bg-orange {
  background-color: #ff5a2c !important;
}
.bg-orange-5 {
  background-color: rgba(255, 90, 44, 0.05) !important;
}
.bg-soft-orange {
  color: #ff5a2c;
  background-color: rgba(255, 90, 44, 0.1) !important;
}
.bg-hover-orange:hover {
  background-color: rgba(255, 90, 44, 0.05) !important;
}
.text-orange {
  color: #ff5a2c !important;
}
.text-hover-orange:hover {
  color: #ff5a2c !important;
}
.border-orange {
  border-color: #ff5a2c !important;
}
.tooltip-orange.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-orange.bs-tooltip-top .arrow::before {
  border-top-color: #ff5a2c !important;
}
.tooltip-orange.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-orange.bs-tooltip-right .arrow::before {
  border-right-color: #ff5a2c !important;
}
.tooltip-orange.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-orange.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #ff5a2c !important;
}
.tooltip-orange.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-orange.bs-tooltip-left .arrow::before {
  border-left-color: #ff5a2c !important;
}
.tooltip-orange .tooltip-inner {
  background-color: #ff5a2c !important;
}
.custom-checkbox-list-orange
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #ff5a2c !important;
}
.custom-checkbox-list-orange .custom-control-label::before {
  background-color: rgba(255, 90, 44, 0.25) !important;
}
.custom-checkbox-list-orange:hover .custom-control-label::before {
  background-color: rgba(255, 90, 44, 0.375) !important;
}
.custom-checkbox-list-orange:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #ff5a2c !important;
}
.bg-yellow {
  background-color: #f2c94c !important;
}
.bg-yellow-5 {
  background-color: rgba(242, 201, 76, 0.05) !important;
}
.bg-soft-yellow {
  color: #e7b400;
  background-color: rgba(242, 201, 76, 0.1) !important;
}
.bg-hover-yellow:hover {
  background-color: rgba(242, 201, 76, 0.05) !important;
}
.text-yellow {
  color: #f2c94c !important;
}
.text-hover-yellow:hover {
  color: #f2c94c !important;
}
.border-yellow {
  border-color: #f2c94c !important;
}
.border-soft-yellow {
  border-color: rgba(242, 201, 76, 0.1) !important;
}
.tooltip-yellow.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-yellow.bs-tooltip-top .arrow::before {
  border-top-color: #f2c94c !important;
}
.tooltip-yellow.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-yellow.bs-tooltip-right .arrow::before {
  border-right-color: #f2c94c !important;
}
.tooltip-yellow.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-yellow.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f2c94c !important;
}
.tooltip-yellow.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-yellow.bs-tooltip-left .arrow::before {
  border-left-color: #f2c94c !important;
}
.tooltip-yellow .tooltip-inner {
  background-color: #f2c94c !important;
}
.custom-checkbox-list-yellow
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #f2c94c !important;
}
.custom-checkbox-list-yellow .custom-control-label::before {
  background-color: rgba(242, 201, 76, 0.25) !important;
}
.custom-checkbox-list-yellow:hover .custom-control-label::before {
  background-color: rgba(242, 201, 76, 0.375) !important;
}
.custom-checkbox-list-yellow:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #f2c94c !important;
}
.bg-green {
  background-color: #27ae60 !important;
}
.bg-green-5 {
  background-color: rgba(39, 174, 96, 0.05) !important;
}
.bg-soft-green {
  color: #27ae60;
  background-color: rgba(39, 174, 96, 0.1) !important;
}
.bg-hover-green:hover {
  background-color: rgba(39, 174, 96, 0.05) !important;
}
.text-green {
  color: #27ae60 !important;
}
.text-hover-green:hover {
  color: #27ae60 !important;
}
.border-green {
  border-color: #27ae60 !important;
}
.border-green-5 {
  border-color: rgba(39, 174, 96, 0.05) !important;
}
.border-green-6 {
  border-color: #dbe5d8 !important;
}
.tooltip-green.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-green.bs-tooltip-top .arrow::before {
  border-top-color: #27ae60 !important;
}
.tooltip-green.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-green.bs-tooltip-right .arrow::before {
  border-right-color: #27ae60 !important;
}
.tooltip-green.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-green.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #27ae60 !important;
}
.tooltip-green.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-green.bs-tooltip-left .arrow::before {
  border-left-color: #27ae60 !important;
}
.tooltip-green .tooltip-inner {
  background-color: #27ae60 !important;
}
.custom-checkbox-list-green
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #27ae60 !important;
}
.custom-checkbox-list-green .custom-control-label::before {
  background-color: rgba(39, 174, 96, 0.25) !important;
}
.custom-checkbox-list-green:hover .custom-control-label::before {
  background-color: rgba(39, 174, 96, 0.375) !important;
}
.custom-checkbox-list-green:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #27ae60 !important;
}
.bg-teal {
  background-color: #00c9a7 !important;
}
.bg-teal-5 {
  background-color: rgba(0, 201, 167, 0.05) !important;
}
.bg-soft-teal {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1) !important;
}
.bg-hover-teal:hover {
  background-color: rgba(0, 201, 167, 0.05) !important;
}
.text-teal {
  color: #00c9a7 !important;
}
.text-hover-teal:hover {
  color: #00c9a7 !important;
}
.border-teal {
  border-color: #00c9a7 !important;
}
.tooltip-teal.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-teal.bs-tooltip-top .arrow::before {
  border-top-color: #00c9a7 !important;
}
.tooltip-teal.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-teal.bs-tooltip-right .arrow::before {
  border-right-color: #00c9a7 !important;
}
.tooltip-teal.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-teal.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #00c9a7 !important;
}
.tooltip-teal.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-teal.bs-tooltip-left .arrow::before {
  border-left-color: #00c9a7 !important;
}
.tooltip-teal .tooltip-inner {
  background-color: #00c9a7 !important;
}
.custom-checkbox-list-teal
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #00c9a7 !important;
}
.custom-checkbox-list-teal .custom-control-label::before {
  background-color: rgba(0, 201, 167, 0.25) !important;
}
.custom-checkbox-list-teal:hover .custom-control-label::before {
  background-color: rgba(0, 201, 167, 0.375) !important;
}
.custom-checkbox-list-teal:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #00c9a7 !important;
}
.bg-cyan {
  background-color: #00c9db !important;
}
.bg-cyan-5 {
  background-color: rgba(0, 201, 219, 0.05) !important;
}
.bg-soft-cyan {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1) !important;
}
.bg-hover-cyan:hover {
  background-color: rgba(0, 201, 219, 0.05) !important;
}
.text-cyan {
  color: #00c9db !important;
}
.text-hover-cyan:hover {
  color: #00c9db !important;
}
.border-cyan {
  border-color: #00c9db !important;
}
.tooltip-cyan.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-cyan.bs-tooltip-top .arrow::before {
  border-top-color: #00c9db !important;
}
.tooltip-cyan.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-cyan.bs-tooltip-right .arrow::before {
  border-right-color: #00c9db !important;
}
.tooltip-cyan.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-cyan.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #00c9db !important;
}
.tooltip-cyan.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-cyan.bs-tooltip-left .arrow::before {
  border-left-color: #00c9db !important;
}
.tooltip-cyan .tooltip-inner {
  background-color: #00c9db !important;
}
.custom-checkbox-list-cyan
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #00c9db !important;
}
.custom-checkbox-list-cyan .custom-control-label::before {
  background-color: rgba(0, 201, 219, 0.25) !important;
}
.custom-checkbox-list-cyan:hover .custom-control-label::before {
  background-color: rgba(0, 201, 219, 0.375) !important;
}
.custom-checkbox-list-cyan:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #00c9db !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-white-5 {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.bg-soft-white {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.bg-hover-white:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.text-white {
  color: #fff !important;
}
.text-hover-white:hover {
  color: #fff !important;
}
.border-white {
  border-color: #fff !important;
}
.tooltip-white.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-white.bs-tooltip-top .arrow::before {
  border-top-color: #fff !important;
}
.tooltip-white.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-white.bs-tooltip-right .arrow::before {
  border-right-color: #fff !important;
}
.tooltip-white.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-white.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff !important;
}
.tooltip-white.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-white.bs-tooltip-left .arrow::before {
  border-left-color: #fff !important;
}
.tooltip-white .tooltip-inner {
  background-color: #fff !important;
}
.custom-checkbox-list-white
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #fff !important;
}
.custom-checkbox-list-white .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.25) !important;
}
.custom-checkbox-list-white:hover .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.375) !important;
}
.custom-checkbox-list-white:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #fff !important;
}
.bg-gray {
  background-color: #8c98a4 !important;
}
.bg-gray-5 {
  /*
  background-color: rgba(140, 152, 164, 0.05) !important;
  */
  background-color: #fafafa !important;
}
.bg-soft-gray {
  color: #8c98a4;
  background-color: rgba(140, 152, 164, 0.1) !important;
}
.bg-hover-soft-gray:hover {
  background-color: rgba(140, 152, 164, 0.1) !important;
}
.bg-hover-gray:hover {
  background-color: #fafafa !important;
}
.bg-hover-gray-opacity:hover {
  background: rgba(250, 250, 250, 0.45) !important;
}
.bg-hover-gray-opacity:hover > * {
  color: var(--cbTextGray) !important;
}
.bg-hover-gray-no-transparence:hover {
  background-color: rgb(243 244 246) !important;
}
.border-hover-gray:hover {
  background-color: rgba(140, 152, 164, 0.1) !important;
}
.text-gray {
  color: #555 !important;
}
.text-hover-gray:hover {
  color: #444 !important;
}
.border-grey {
  border-color:  rgba(0, 0, 0, 0.11)!important;
}
.border-gray {
  border-color: #8c98a4 !important;
}
.tooltip-gray.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray.bs-tooltip-top .arrow::before {
  border-top-color: #8c98a4 !important;
}
.tooltip-gray.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray.bs-tooltip-right .arrow::before {
  border-right-color: #8c98a4 !important;
}
.tooltip-gray.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #8c98a4 !important;
}
.tooltip-gray.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray.bs-tooltip-left .arrow::before {
  border-left-color: #8c98a4 !important;
}
.tooltip-gray .tooltip-inner {
  background-color: #8c98a4 !important;
}
.custom-checkbox-list-gray
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #8c98a4 !important;
}
.custom-checkbox-list-gray .custom-control-label::before {
  background-color: rgba(140, 152, 164, 0.25) !important;
}
.custom-checkbox-list-gray:hover .custom-control-label::before {
  background-color: rgba(140, 152, 164, 0.375) !important;
}
.custom-checkbox-list-gray:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #8c98a4 !important;
}
.bg-gray-dark {
  background-color: #71869d !important;
}
.bg-gray-dark-5 {
  background-color: rgba(113, 134, 157, 0.05) !important;
}
.bg-soft-gray-dark {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1) !important;
}
.bg-hover-gray-dark:hover {
  background-color: rgba(113, 134, 157, 0.05) !important;
}
.text-gray-dark {
  color: #71869d !important;
}
.text-hover-gray-dark:hover {
  color: #71869d !important;
}
.border-gray-dark {
  border-color: #71869d !important;
}
.tooltip-gray-dark.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-dark.bs-tooltip-top .arrow::before {
  border-top-color: #71869d !important;
}
.tooltip-gray-dark.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-dark.bs-tooltip-right .arrow::before {
  border-right-color: #71869d !important;
}
.tooltip-gray-dark.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-dark.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #71869d !important;
}
.tooltip-gray-dark.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-dark.bs-tooltip-left .arrow::before {
  border-left-color: #71869d !important;
}
.tooltip-gray-dark .tooltip-inner {
  background-color: #71869d !important;
}
.custom-checkbox-list-gray-dark
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #71869d !important;
}
.custom-checkbox-list-gray-dark .custom-control-label::before {
  background-color: rgba(113, 134, 157, 0.25) !important;
}
.custom-checkbox-list-gray-dark:hover .custom-control-label::before {
  background-color: rgba(113, 134, 157, 0.375) !important;
}
.custom-checkbox-list-gray-dark:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #71869d !important;
}
.bg-gray-100 {
  background-color: #f9fafc !important;
}
.text-gray-100 {
  color: #f9fafc !important;
}
.border-gray-100 {
  border-color: #f9fafc !important;
}
.tooltip-gray-100.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-100.bs-tooltip-top .arrow::before {
  border-top-color: #f9fafc !important;
}
.tooltip-gray-100.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-100.bs-tooltip-right .arrow::before {
  border-right-color: #f9fafc !important;
}
.tooltip-gray-100.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-100.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f9fafc !important;
}
.tooltip-gray-100.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-100.bs-tooltip-left .arrow::before {
  border-left-color: #f9fafc !important;
}
.tooltip-gray-100 .tooltip-inner {
  background-color: #f9fafc !important;
}
.bg-gray-200 {
  background-color: #fafafa !important;
}
.text-gray-200 {
  color: #f8fafd !important;
}
.border-gray-200 {
  border-color: #fafafa !important;
}
.tooltip-gray-200.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-200.bs-tooltip-top .arrow::before {
  border-top-color: #f8fafd !important;
}
.tooltip-gray-200.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-200.bs-tooltip-right .arrow::before {
  border-right-color: #f8fafd !important;
}
.tooltip-gray-200.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-200.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f8fafd !important;
}
.tooltip-gray-200.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-200.bs-tooltip-left .arrow::before {
  border-left-color: #f8fafd !important;
}
.tooltip-gray-200 .tooltip-inner {
  background-color: #f8fafd !important;
}
.bg-gray-300 {
  background-color: var(--cbBorderColor) !important;
}
.text-gray-300 {
  color: var(--cbBorderColor) !important;
}
.border-gray-300 {
  border-color: var(--cbBorderColor) !important;
}
.tooltip-gray-300.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-300.bs-tooltip-top .arrow::before {
  border-top-color: var(--cbBorderColor) !important;
}
.tooltip-gray-300.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-300.bs-tooltip-right .arrow::before {
  border-right-color: var(--cbBorderColor) !important;
}
.tooltip-gray-300.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-300.bs-tooltip-bottom .arrow::before {
  border-bottom-color: var(--cbBorderColor) !important;
}
.tooltip-gray-300.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-300.bs-tooltip-left .arrow::before {
  border-left-color: var(--cbBorderColor) !important;
}
.tooltip-gray-300 .tooltip-inner {
  background-color: var(--cbBorderColor) !important;
}
.bg-gray-400, .bg-gray-300 {
  background-color: rgb(244 244 245/1) !important;
}
.text-gray-400 {
  color: #bdc5d1 !important;
}
.border-gray-400 {
  border-color: #bdc5d1 !important;
}
.tooltip-gray-400.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-400.bs-tooltip-top .arrow::before {
  border-top-color: #bdc5d1 !important;
}
.tooltip-gray-400.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-400.bs-tooltip-right .arrow::before {
  border-right-color: #bdc5d1 !important;
}
.tooltip-gray-400.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-400.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #bdc5d1 !important;
}
.tooltip-gray-400.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-400.bs-tooltip-left .arrow::before {
  border-left-color: #bdc5d1 !important;
}
.tooltip-gray-400 .tooltip-inner {
  background-color: #bdc5d1 !important;
}
.bg-gray-500 {
  background-color: #97a4af !important;
}
.text-gray-500 {
  color: #97a4af !important;
}
.border-gray-500 {
  border-color: #97a4af !important;
}
.tooltip-gray-500.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-500.bs-tooltip-top .arrow::before {
  border-top-color: #97a4af !important;
}
.tooltip-gray-500.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-500.bs-tooltip-right .arrow::before {
  border-right-color: #97a4af !important;
}
.tooltip-gray-500.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-500.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #97a4af !important;
}
.tooltip-gray-500.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-500.bs-tooltip-left .arrow::before {
  border-left-color: #97a4af !important;
}
.tooltip-gray-500 .tooltip-inner {
  background-color: #97a4af !important;
}
.bg-gray-600 {
  background-color: #8c98a4 !important;
}
.text-gray-600 {
  color: #8c98a4 !important;
}
.border-gray-600 {
  border-color: #8c98a4 !important;
}
.tooltip-gray-600.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-600.bs-tooltip-top .arrow::before {
  border-top-color: #8c98a4 !important;
}
.tooltip-gray-600.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-600.bs-tooltip-right .arrow::before {
  border-right-color: #8c98a4 !important;
}
.tooltip-gray-600.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-600.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #8c98a4 !important;
}
.tooltip-gray-600.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-600.bs-tooltip-left .arrow::before {
  border-left-color: #8c98a4 !important;
}
.tooltip-gray-600 .tooltip-inner {
  background-color: #8c98a4 !important;
}
.bg-gray-700 {
  background-color: #677788 !important;
}
.text-gray-700 {
  color: #677788 !important;
}
.border-gray-700 {
  border-color: #677788 !important;
}
.tooltip-gray-700.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-700.bs-tooltip-top .arrow::before {
  border-top-color: #677788 !important;
}
.tooltip-gray-700.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-700.bs-tooltip-right .arrow::before {
  border-right-color: #677788 !important;
}
.tooltip-gray-700.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-700.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #677788 !important;
}
.tooltip-gray-700.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-700.bs-tooltip-left .arrow::before {
  border-left-color: #677788 !important;
}
.tooltip-gray-700 .tooltip-inner {
  background-color: #677788 !important;
}
.bg-gray-800 {
  background-color: #71869d !important;
}
.text-gray-800 {
  color: var(--cbTextGray) !important;
}
.border-gray-800 {
  border-color: #71869d !important;
}
.tooltip-gray-800.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-800.bs-tooltip-top .arrow::before {
  border-top-color: #71869d !important;
}
.tooltip-gray-800.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-800.bs-tooltip-right .arrow::before {
  border-right-color: #71869d !important;
}
.tooltip-gray-800.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-800.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #71869d !important;
}
.tooltip-gray-800.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-800.bs-tooltip-left .arrow::before {
  border-left-color: #71869d !important;
}
.tooltip-gray-800 .tooltip-inner {
  background-color: #71869d !important;
}
.bg-gray-900 {
  background-color: #1e2022 !important;
}
.text-gray-900 {
  color: var(--cbTextBlack) !important;
}
.border-gray-900 {
  border-color: #1e2022 !important;
}
.tooltip-gray-900.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-900.bs-tooltip-top .arrow::before {
  border-top-color: #1e2022 !important;
}
.tooltip-gray-900.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-900.bs-tooltip-right .arrow::before {
  border-right-color: #1e2022 !important;
}
.tooltip-gray-900.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-900.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #1e2022 !important;
}
.tooltip-gray-900.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-900.bs-tooltip-left .arrow::before {
  border-left-color: #1e2022 !important;
}
.tooltip-gray-900 .tooltip-inner {
  background-color: #1e2022 !important;
}
.bg-gray-100 {
  background-color: #f9fafc !important;
}
.text-gray-100 {
  color: #f9fafc !important;
}
.border-gray-100 {
  border-color: #f9fafc !important;
}
.tooltip-gray-100.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-100.bs-tooltip-top .arrow::before {
  border-top-color: #f9fafc !important;
}
.tooltip-gray-100.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-100.bs-tooltip-right .arrow::before {
  border-right-color: #f9fafc !important;
}
.tooltip-gray-100.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-100.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f9fafc !important;
}
.tooltip-gray-100.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-100.bs-tooltip-left .arrow::before {
  border-left-color: #f9fafc !important;
}
.tooltip-gray-100 .tooltip-inner {
  background-color: #f9fafc !important;
}
.text-gray-200 {
  color: #f8fafd !important;
}
.border-gray-200 {
  border-color: #f8fafd !important;
}
.tooltip-gray-200.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-200.bs-tooltip-top .arrow::before {
  border-top-color: #f8fafd !important;
}
.tooltip-gray-200.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-200.bs-tooltip-right .arrow::before {
  border-right-color: #f8fafd !important;
}
.tooltip-gray-200.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-200.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f8fafd !important;
}
.tooltip-gray-200.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-200.bs-tooltip-left .arrow::before {
  border-left-color: #f8fafd !important;
}
.tooltip-gray-200 .tooltip-inner {
  background-color: #f8fafd !important;
}
.text-gray-300 {
  color: var(--cbBorderColor) !important;
}
.border-gray-300 {
  border-color: var(--cbBorderColor) !important;
}
.tooltip-gray-300.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-300.bs-tooltip-top .arrow::before {
  border-top-color: var(--cbBorderColor) !important;
}
.tooltip-gray-300.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-300.bs-tooltip-right .arrow::before {
  border-right-color: var(--cbBorderColor) !important;
}
.tooltip-gray-300.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-300.bs-tooltip-bottom .arrow::before {
  border-bottom-color: var(--cbBorderColor) !important;
}
.tooltip-gray-300.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-300.bs-tooltip-left .arrow::before {
  border-left-color: var(--cbBorderColor) !important;
}
.tooltip-gray-300 .tooltip-inner {
  background-color: var(--cbBorderColor) !important;
}
.text-gray-400 {
  color: #bdc5d1 !important;
}
.border-gray-400 {
  border-color: #bdc5d1 !important;
}
.tooltip-gray-400.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-400.bs-tooltip-top .arrow::before {
  border-top-color: #bdc5d1 !important;
}
.tooltip-gray-400.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-400.bs-tooltip-right .arrow::before {
  border-right-color: #bdc5d1 !important;
}
.tooltip-gray-400.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-400.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #bdc5d1 !important;
}
.tooltip-gray-400.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-400.bs-tooltip-left .arrow::before {
  border-left-color: #bdc5d1 !important;
}
.tooltip-gray-400 .tooltip-inner {
  background-color: #bdc5d1 !important;
}
.bg-gray-500 {
  background-color: #97a4af !important;
}
.text-gray-500 {
  color: #97a4af !important;
}
.border-gray-500 {
  border-color: #97a4af !important;
}
.tooltip-gray-500.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-500.bs-tooltip-top .arrow::before {
  border-top-color: #97a4af !important;
}
.tooltip-gray-500.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-500.bs-tooltip-right .arrow::before {
  border-right-color: #97a4af !important;
}
.tooltip-gray-500.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-500.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #97a4af !important;
}
.tooltip-gray-500.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-500.bs-tooltip-left .arrow::before {
  border-left-color: #97a4af !important;
}
.tooltip-gray-500 .tooltip-inner {
  background-color: #97a4af !important;
}
.bg-gray-600 {
  background-color: #8c98a4 !important;
}
.text-gray-600 {
  color: #8c98a4 !important;
}
.border-gray-600 {
  border-color: #8c98a4 !important;
}
.tooltip-gray-600.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-600.bs-tooltip-top .arrow::before {
  border-top-color: #8c98a4 !important;
}
.tooltip-gray-600.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-600.bs-tooltip-right .arrow::before {
  border-right-color: #8c98a4 !important;
}
.tooltip-gray-600.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-600.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #8c98a4 !important;
}
.tooltip-gray-600.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-600.bs-tooltip-left .arrow::before {
  border-left-color: #8c98a4 !important;
}
.tooltip-gray-600 .tooltip-inner {
  background-color: #8c98a4 !important;
}
.bg-gray-700 {
  background-color: #677788 !important;
}
.text-gray-700 {
  color: #677788 !important;
}
.border-gray-700 {
  border-color: #677788 !important;
}
.tooltip-gray-700.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-700.bs-tooltip-top .arrow::before {
  border-top-color: #677788 !important;
}
.tooltip-gray-700.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-700.bs-tooltip-right .arrow::before {
  border-right-color: #677788 !important;
}
.tooltip-gray-700.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-700.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #677788 !important;
}
.tooltip-gray-700.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-700.bs-tooltip-left .arrow::before {
  border-left-color: #677788 !important;
}
.tooltip-gray-700 .tooltip-inner {
  background-color: #677788 !important;
}
.bg-gray-800 {
  background-color: #71869d !important;
}
.border-gray-800 {
  border-color: #71869d !important;
}
.tooltip-gray-800.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-800.bs-tooltip-top .arrow::before {
  border-top-color: #71869d !important;
}
.tooltip-gray-800.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-800.bs-tooltip-right .arrow::before {
  border-right-color: #71869d !important;
}
.tooltip-gray-800.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-800.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #71869d !important;
}
.tooltip-gray-800.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-800.bs-tooltip-left .arrow::before {
  border-left-color: #71869d !important;
}
.tooltip-gray-800 .tooltip-inner {
  background-color: #71869d !important;
}
.bg-gray-900 {
  background-color: #1e2022 !important;
}
.text-gray-900 {
  color: var(--cbTextBlack) !important;
}
.border-gray-900 {
  border-color: #1e2022 !important;
}
.tooltip-gray-900.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip-gray-900.bs-tooltip-top .arrow::before {
  border-top-color: #1e2022 !important;
}
.tooltip-gray-900.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-gray-900.bs-tooltip-right .arrow::before {
  border-right-color: #1e2022 !important;
}
.tooltip-gray-900.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip-gray-900.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #1e2022 !important;
}
.tooltip-gray-900.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip-gray-900.bs-tooltip-left .arrow::before {
  border-left-color: #1e2022 !important;
}
.tooltip-gray-900 .tooltip-inner {
  background-color: #1e2022 !important;
}
.card-lesson {
  min-height: 22rem;
}
.link-active-wrapper {
  font-weight: 500;
}
.link-active-wrapper .active {
  font-weight: 600;
}
.badge-lg {
  padding: 0.375rem 0.625rem !important;
}
.mini-calendar {
  max-height: 1000px;
  overflow-y: scroll;
}
.activity-group .active {
  background-color: rgba(9, 42, 206, 0.05);
  border-color: #092ace;
}
.custom-control-green
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #27ae60;
  background-color: #27ae60;
}
.custom-control-green
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #75e0a2;
}
.custom-control-green
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  color: #1e2022;
  background-color: #9ee9be;
  border-color: #9ee9be;
}
.progress-vertical {
  height: 14rem !important;
}
.progress-vertical-lg {
  height: 18rem !important;
}
@media (min-width: 768px) {
  .rotate-x {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.border-width-2 {
  border-width: 2px !important;
}
.border-solid {
  border-style: solid;
}
.legend-box {
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
}
.fixed-main .container-fluid {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.fixed-main .col-fixed {
  padding: 1.5rem;
}
@media (min-width: 768px) {
  .fixed-main .col-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 2.5rem 1.5rem;
  }
}
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.light-yellow.bg-hover-gray {
  background-color: lightyellow !important;
}
.modal-assigncontent .nav-tabs {
  margin-bottom: 70px;
}
.dropdown-autwidth .d-inline-block {
  width: auto !important;
}
.client-share {
  justify-content: flex-end;
}
.table-roles .table-nowrap td,
.table-roles .table-nowrap th {
  white-space: unset !important;
}
.table-roles .table-nowrap td .role-desc {
  float: left;
  width: 500px;
}
.table-roles .table-nowrap td .role-desc-more {
  display: flex;
  align-items: center;
  opacity: 0;
}
.role-desc-more .more {
  color: var(--secondaryColor) !important;
  padding-left: 5px;
  font-weight: 500;
}
.table-roles .table-nowrap td:hover .role-desc-more {
  opacity: 1;
}
.more-btn {
  color: #bc7505;
  cursor: pointer;
}
.clamp-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.nav.nav-tabs .bg-primary-soft {
  z-index: -1;
}
.nav.nav-tabs .nav-count {
  z-index: 10 !important;
}
.breadcrumb-mobile {
  display: none;
}
.d-px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.d-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.d-mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.d-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
