:root {
  --primaryColor: #0029e3;
  --secondaryColor: #0029e3;
  --menuBgColor: #fff;
  --menuBorderColor: rgb(228 228 231/ 1);
  --lightBlueColor: rgba(9, 42, 206, 0.1);
  --lightGreyColor: #a3a1a1;
  --black: rgb(9 9 11/1);
  --lostColor: #fe8888;
  --redSoft: #fff0ef;
  --lostDarkColor: #c1232a;
  --lostDarkColorHover: #9a1c21;
  --wonColor: #ade8ce;
  --wonDarkColor: #5cb85c;
  --wonDarkColorHover: #1a5a28;
  --blueSoftBgColor: #e5f0ff;
  --blueSoftColor: #214974;
  --deleteColor: #b9bfce;
  --deleteDarkColor: #414141;
  --borderRadius: 0.3125rem;
  --borderRadiusLg: 0.75rem;
  --greyColor: #f8fafd;
  --greyTableHead: #fafafa;
  --colorAInstitute: #233b75;
  --colorAInstituteSecondary: #38b4ad;
  --setting-menu-color: #fafafa;
  --accordion-color-active: #f1f1f1;
  --whiteHoverBlue: #061b85;
  --excel-success: #008036;
  --excel-primary: #4d80c7;
  --excel-yellow: #ffbd59;
  --excel-gray: #737373;
  --reddish-orange: #e26742;
  --teal-blue: #20abf0;
  --sea-green: #00cd84;
  --second-column-width: 300px;
  --checkist-sidebar-width: 300px;
  --rpt-bg-green: #e9f4e7;
  --rpt-green-border: #d7e6d7;
  --rpt-color-green: #258e13;
  --rpt-color-gray: #5e6369;
  --rpt-color-blue: #2392d4;
  --rpt-color-green-dark: #daecda;
  --rpt-color-red: #e60808;
  --rpt-bg-yellow: #f6f0d9;
  --rpt-yellow-border-color: #f4e9c1;
  --rpt-color-orange: #faae13;
  --rpt-color-yellow: #f0cb46;
  --rpt-bg-red: rgba(230, 8, 8, 0.1);
  --rpt-bg-icon: #a3a4af;
  --rpt-soft-primary: #eeeffc;
  --rpt-color-dark-gray: #e8e8eb;
  --rpt-color-light-gray: #f8f8fa;
  --rpt-subtext-color: #404040;
  --rpt-widget-border-color: #e5e5e5;
  --rpt-widget-border-color-dark: #d3d1d1;
  --rpt-inner-section-border-color: #fafafa;
  --rpt-widget-border-radius: 10px;
  --rpt-icon-bg-color: #fcfcfc;
  --rpt-widget-border-thickness: 2px;
  --rpt-donut-light-color: #eee;
  --primaryColorHsl: lightblue;
  --primaryColorRgb: rgba(0, 41, 227, 0.8);
  --primaryColorRgba: rgba(0, 41, 227, 0.2);

  --rpt-tile-in-bg: #fef1f6;
  --rpt-tile-in-icon: #ff4081;

  --rpt-tile-tm: #5eb85b08;
  --rpt-tile-tm-bg: #f3fff1;
  --rpt-tile-tm-icon: #22c55e;
  --rpt-tile-tm-dark: #5eb85b1a;

  --rpt-tile-wc: #f28a4508;
  --rpt-tile-wc-bg: #fef2f2;
  --rpt-tile-wc-icon: #ef4444;
  --rpt-tile-wc-dark: #f28a452d;

  --rpt-tile-mc: #cb61e608;
  --rpt-tile-mc-bg: #fef3f2;
  --rpt-tile-mc-icon: #f97316;
  --rpt-tile-mc-dark: #cb61e61a;

  --rpt-tile-fr: #548ee608;
  --rpt-tile-fr-bg: #fdf4ff;
  --rpt-tile-fr-icon: #d946ef;
  --rpt-tile-fr-dark: #548ee61a;

  --rpt-tile-cc-bg: #fff5e6;
  --rpt-tile-cc-icon: #ff9500;

  --rpt-tile-as-bg: #f0f4ff;
  --rpt-tile-as-icon: #3b82f6;

  --coach-tile-sales: #f24f4508;
  --coach-tile-sales-bg: #960700;
  --coach-tile-sales-dark: #f24f451a;
  --coach-tile-sales-light: #f24f454d;
  --coach-tile-sales-dark-btn: #f24f45dd;

  --coach-tile-professional: #4580f208;
  --coach-tile-professional-dark: #4580f21a;
  --coach-tile-professional-light: #4580f24d;
  --coach-tile-professional-dark-btn: #4580f2dd;

  --coach-tile-industry: #ef45f208;
  --coach-tile-industry-dark: #ef45f21a;
  --coach-tile-industry-light: #ef45f24d;
  --coach-tile-industry-dark-btn: #ef45f2dd;

  --login-alert-green-bg: #f6ffed;
  --login-alert-green-border: #b7eb8f;

  --alert-color-red-bg: #fff2f0;
  --alert-color-red-border: #ffccc7;
  --alert-color-red-text: #ff4d4f;

  --alert-color-green-text: #52c41a;
  --alert-color-green-hover: #eaf6de;

  --alert-color-blue-bg: #e6f4ff;
  --alert-color-blue-border: #91caff;
  --alert-color-blue-hover: #d6e9f9;
  --alert-color-blue-text: #1677ff;

  --alert-color-yellow-bg: #fffbe6;
  --alert-color-yellow-border: #ffe58f;
  --alert-color-yellow-text: #faad14;
  --alert-color-yellow-hover: #fbf3cf;

  --tree-item: #afafaf;
  --tree-line: #999;

  --status-blue-bg: #e6f2ff;
  --status-blue-text: #007bff;
  --status-blue-main: #007bff;

  --status-yellow-bg: #fff3cd;
  --status-yellow-text: #8b6d05;
  --status-yellow-main: #ffc107;

  --status-green-bg: #d4edda;
  --status-green-text: #28a745;
  --status-green-main: #28a745;

  --status-gray-bg: #e9ecef;
  --status-gray-text: #4a4a4a;
  --status-gray-main: #6c757d;

  --status-red-bg: #f8d7da;
  --status-red-text: #dc3545;
  --status-red-main: #dc3545;

  --status-premium: #f2c94c; /*#eeb95b*/

  --table-hover: #f4f4f4;

  --activity-task: #c16903;
  --activity-event: #026cc1;
  --activity-call: #654dc4;

  --chat-ask-anything-size: 650px;
  --text-muted: #444;

  --rounded: 0.3125rem;
  --ag-header-background-color: var(--setting-menu-color) !important;

  --mask: linear-gradient(90deg, #000, #000 84%, transparent 89%, transparent);
}

.bg-accordion-active {
  background: var(--accordion-color-active);
}

div.border-top.bg-gray-table-head {
  background: var(--accordion-color-active) !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  ul {
    padding-left: 0 !important;
  }
}
.text-teal-blue {
  color: var(--teal-blue);
}
.text-AInstituteSecondary {
  color: var(--colorAInstituteSecondary);
}
.bg-red-soft {
  background-color: var(--redSoft);
}
.bg-soft-alert-blue {
  background: var(--alert-color-blue-hover);
}

.bg-soft-alert-yellow {
  background: var(--alert-color-yellow-hover);
}

.bg-soft-alert-green {
  background: var(--alert-color-green-hover);
}
.alert-red {
  border-color: var(--alert-color-red-border) !important;
  background: var(--alert-color-red-bg);
  .alert-icon {
    color: var(--alert-color-red-text);
  }
}

.alert-green {
  border-color: var(--login-alert-green-border) !important;
  background: var(--login-alert-green-bg);
  .alert-icon {
    color: var(--alert-color-green-text);
  }
}

.alert-blue {
  border-color: var(--alert-color-blue-border) !important;
  background: var(--alert-color-blue-bg);
  .alert-icon {
    color: var(--alert-color-blue-text);
  }
}

.alert-yellow {
  border-color: var(--alert-color-yellow-border) !important;
  background: var(--alert-color-yellow-bg);
  .alert-icon {
    color: var(--alert-color-yellow-text);
  }
}

.dark-status {
  /* will move these out */
  --yellow: #ffc107;
  --gray: #e0e0e0;
  --orange: #ffcdd2;
  --blue: #b3e5fc;
  --green: #c5e1a5;
  --magenta: #eab0ea;
  --teal: #95f1f1;
  --indigo: #cfaaea;
  color: black;
  &.yellow {
    background-color: var(--yellow);
  }
  &.red {
    background-color: var(--orange);
  }
  &.green {
    background-color: var(--green);
  }
  &.blue {
    background-color: var(--blue);
  }
  &.gray {
    background-color: var(--gray);
  }
  &.magenta {
    background-color: var(--magenta);
  }
  &.teal {
    background-color: var(--teal);
  }
  &.indigo {
    background-color: var(--indigo);
  }
}

.status-green {
  background: var(--status-green-bg);
  color: var(--status-green-text) !important;
}

.status-red {
  background: var(--status-red-bg);
  color: var(--status-red-text) !important;
}

.status-yellow {
  background: var(--status-yellow-bg);
  color: var(--status-yellow-text) !important;
}

.status-gray {
  background: var(--status-gray-bg);
  color: var(--status-gray-text) !important;
}
.status-premium {
  background: var(--status-premium);
  color: var(--black) !important;
}
.filter-pending input[type='radio'],
.filter-pending input[type='checkbox'] {
  accent-color: var(--status-gray-main);
}

.filter-progress input[type='radio'],
.filter-progress input[type='checkbox'] {
  accent-color: #ba8a2c;
}

.filter-overdue input[type='radio'],
.filter-overdue input[type='checkbox'] {
  accent-color: var(--status-red-main);
}

.filter-completed input[type='radio'],
.filter-completed input[type='checkbox'] {
  accent-color: var(--status-green-main);
}

.filter-all input[type='radio'],
.filter-all input[type='checkbox'] {
  accent-color: var(--status-blue-main);
}

.status-all {
  background-color: var(--status-blue-bg);
  color: var(--status-blue-text);
}
.status-pending {
  background-color: var(--status-gray-bg);
  color: var(--status-gray-text);
}

.status-inProgress {
  background-color: var(--status-yellow-bg);
  color: var(--status-yellow-text);
}

.status-overdue {
  background-color: var(--status-red-bg);
  color: var(--status-red-text);
}

.status-completed {
  background-color: var(--status-green-bg);
  color: var(--status-green-text);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.pe-none {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.badge-small {
  width: 30px !important;
}

.icon-list-size {
  font-size: x-large;
}

.min-heigth-head-card {
  min-height: 35px !important;
}

.cursor-default {
  cursor: default !important;
}

.product-item-modal {
  border-radius: var(--borderRadius);
}

.product-item-modal.active {
  cursor: pointer;
  background: #eee;
}

.add-more-btn {
  width: 47px;
  height: 47px;
}

.add-more-btn:hover,
.hover-icon:hover {
  color: #444 !important;
}

.hover-icon.more-actions {
  height: 35px;
}

.exceptions-view {
  margin-top: 15vh;
}

.exceptions-view h1 {
  font-size: 150px;
  color: #092ace;
}

.exceptions-view h3 {
  font-size: 48px;
  color: #111b51;
  margin-top: -40px;
}

.exceptions-view p {
  font-size: 18px;
  color: #111b51;
}

.navbar-vertical-fixed {
  z-index: 90 !important;
}

.navbar-dark .navbar-nav .nav-link {
  border-left: none;
}

.navbar-nav.nav-tabs .active.nav-link {
  color: var(--secondaryColor) !important;
  opacity: 1;
}

.rdrMonthAndYearWrapper {
  margin-left: 10px !important;
  margin-right: 0 !important;
  padding-top: 0 !important;
  .rdrNextPrevButton.rdrPprevButton {
    margin-right: 0 !important;
  }
}

.text-primary,
a {
  color: var(--secondaryColor) !important;
}

body {
  margin-bottom: -8px !important;
}

.text-primary.btn-link:not(.no-underline):hover {
  text-decoration: underline;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--secondaryColor) !important;
}

.hoverLink:hover {
  color: var(--secondaryColor) !important;
}

.main-owner div {
  border-color: #fff !important;
}

.secondary-owners:hover {
  z-index: 11 !important;
}
.nav-link.bg-primary.text-white:hover {
  color: white !important;
  filter: brightness(80%);
}
.item-filter.text-primary .p-item-filter.nav-link {
  color: var(--secondaryColor) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--secondaryColor) !important;
}

.edit-avatar-container {
  border-color: var(--secondaryColor) !important;
}

.page-item.active .page-link,
.bg-primary,
.edit-avatar-icon {
  background-color: var(--secondaryColor) !important;
}

.btn-primary,
.bg-dark {
  background-color: var(--secondaryColor) !important;
  border-color: var(--secondaryColor) !important;
}

.btn-primary-no-border {
  background-color: var(--secondaryColor) !important;
}

.btn-ai-institute {
  background-color: var(--colorAInstitute) !important;
  border-color: var(--colorAInstitute) !important;
  color: #fff;
}

.btn-outline-ai-institute {
  background-color: #fff !important;
  border-color: var(--colorAInstitute) !important;
  color: var(--colorAInstitute);
}

.btn-outline-ai-institute:hover {
  background-color: var(--colorAInstitute) !important;
  color: #fff;
}
.btn-ai-institute:hover {
  background-color: var(--blueSoftColor) !important;
  filter: brightness(100%) !important;
  color: #fff !important;
}

.bg-light {
  background-color: var(--menuBgColor) !important;
  border-color: var(--menuBorderColor) !important;
}

.btn-ghost-primary {
  background-color: #fff !important;
  color: var(--secondaryColor) !important;
}

.btn-ghost-secondary {
  background-color: transparent !important;
  border-color: var(--secondaryColor) !important;
}

.btn-ghost-secondary:hover {
  background-color: transparent !important;
  border-color: var(--secondaryColor) !important;
  color: var(--secondaryColor) !important;
}

.navbar-dark,
.navbar-vertical-footer,
.sidebar-min-height {
  background-color: var(--menuBgColor) !important;
  border-color: var(--menuBorderColor) !important;
}

.fullscreen-spinner {
  margin-top: 45vh;
}

.tags-dropdown {
  background-color: white !important;
}

.btn-import {
  background-color: var(--secondaryColor) !important;
  border-color: var(--secondaryColor) !important;
  color: #fff !important;
}

.btn-transparent {
  background: transparent !important;
  border-radius: unset !important;
  border: unset !important;
}

.drop-disabled {
  background-color: #fafafa !important;
}

.fs-7 {
  font-size: 13px;
}

.fs-7.lead {
  line-height: 26px;
}

.fs-7_1 {
  font-size: 14px;
}

.fs-8 {
  font-size: 12px;
}

.fs-20 {
  font-size: 20px;
}

.fs-9 {
  font-size: 11px !important;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 9px;
}

.fs-12 {
  font-size: 8px;
}

.fs-13px {
  font-size: 13px;
}
.right-sidebar {
  background-color: #fff;
  width: 55px;
  position: relative;
  z-index: 0;
  top: 0;
}

.content-with-insights {
  margin-right: 55px;
}

.insights-bar {
  position: fixed;
  z-index: 95;
  right: 0;
  height: 100%;
  top: 3.75rem;
}

.right-sidebar .related-lessons .card {
  border-color: #e5e5e5;
  border-radius: 0.5rem;
}

.right-sidebar .related-lessons h4,
.right-sidebar .related-lessons a {
  text-align: left;
}

.dropdown-search:focus {
  border-color: rgba(9, 42, 206, 0.4) !important;
  box-shadow: 0 0 10px rgb(9 42 206 / 10%) !important;
}

.profile-popover.dropdown.show .popover-link,
.popover-link:hover {
  text-decoration: underline !important;
}

.popover__wrapper {
  position: relative;
  display: inline-block;
  width: auto;
}

.h-0 {
  height: 0;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translate(0, 10px);
  background-color: #fff;
  width: auto;
}

.profile-popover.right {
  right: -300px;
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
}

.popover__wrapper:hover .popover__content.lg {
  min-width: 325px;
}

.popover__wrapper:hover .popover-link {
  text-decoration: underline;
  color: var(--secondaryColor) !important;
}

.popover__message {
  text-align: center;
}

.avatar-label {
  position: absolute;
  bottom: -12px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  line-height: 18px;
  font-weight: bold;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  color: #fff;
  border: 2px solid #fff;
  font-size: 10px;
  display: inline-block;
}

.avatar-primary-label {
  background: #27ae60;
}

.avatar-secondary-label {
  background: var(--secondaryColor);
}

.rr-card-width {
  min-width: 100px;
}

.navbar-brand-wrapper {
  background-color: var(--menuBgColor) !important;
}

.card-lesson-hero {
  border-top: 4px solid var(--secondaryColor) !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.idf-dropdown-item-list .dropdown-item {
  padding-left: 1rem;
  padding-right: 1rem;
}

.idf-dropdown-item-list .dropdown-item:not(.inactive):hover,
.idf-dropdown-item-list:not(.ignore-child) .dropdown-item:hover * {
  background-color: var(--secondaryColor);
  color: #fff !important;
  font-weight: 700;
  border-radius: 4px;
}

.idf-dropdown-item-list.soft .dropdown-item:hover {
  background-color: hsl(var(--primaryColorHsl), 100%, 98%) !important;
  color: var(--secondaryColor) !important;
  font-weight: normal !important;
  border-radius: 4px;
}

.idf-dropdown-item-list.soft .dropdown-item.bg-primary-soft:hover {
  background-color: hsl(var(--primaryColorHsl), 100%, 99%) !important;
}

.idf-dropdown-item-list.gray .dropdown-item:hover {
  background-color: rgba(140, 152, 164, 0.1) !important;
  color: var(--secondaryColor) !important;
  font-weight: normal !important;
  border-radius: 0 !important;
}

.idf-dropdown-item-list .dropdown-item.inactive {
  color: #ccc !important;
  font-weight: 600 !important;
}
.idf-dropdown-item-list .dropdown-item.inactive:hover * {
  background: white !important;
  pointer-events: none;
  color: #ccc !important;
  font-weight: 600 !important;
}

.idf-tabs a:hover {
  background: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}

.dropdown-center {
  left: -50% !important;
  margin-left: -50% !important;
}

.text-gray-search {
  color: var(--lightGreyColor) !important;
}
.text-gray-dark {
  color: var(--deleteDarkColor) !important;
}
.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.deal-edit-header {
  min-width: 220px;
}

.min-w-20 {
  min-width: 20px !important;
}

.min-w-25 {
  min-width: 25px !important;
}

.min-w-30 {
  min-width: 30px !important;
}

.min-w-170 {
  min-width: 200px !important;
}
.min-w-250 {
  min-width: 250px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.min-h-100px {
  min-height: 100px;
}

.min-h-120 {
  min-height: 120px;
}
.min-h-150 {
  min-height: 150px;
}

.min-h-150 {
  min-height: 150px;
}

.ck.ck-toolbar,
.ck.ck-content {
  border: 1px solid rgba(231, 234, 243, 0.7) !important;
}

.ck.ck-content {
  min-height: 200px;
}

.form-group label,
.form-label {
  color: var(--black);
  font-size: 13px;
  font-weight: 500;
}

.error-show-right {
  top: 6px;
  right: 6px;
}

.setting-item,
.home-card-item,
.hover-actions {
  transition: all 0.3s ease;
}

.setting-item .idf-dropdown-item-list,
.setting-item .refresh-icon {
  opacity: 0;
}

.setting-item:not(.inactive):hover .idf-dropdown-item-list,
.setting-item:not(.inactive):hover .refresh-icon {
  opacity: 1;
}

.setting-item:hover,
.home-card-item-new:hover {
  border-color: var(--secondaryColor);
}

.setting-item:hover span.text-black {
  color: var(--secondaryColor) !important;
}

.picklist-button {
  cursor: pointer;
  border: 1px dashed rgb(218, 218, 218);
  margin-left: 32px;
  margin-right: 75px;
}
.picklist-button:hover {
  border-color: var(--secondaryColor);
}

.course-card-box {
  min-height: 270px !important;
  transition: all 0.3s ease;
  background-clip: padding-box;
  background: linear-gradient(303.98deg, #eeeffc -29.45%, #fff 60.9%);
}
.lesson-card-box,
.card-lesson-hero,
.home-card-item-new,
.bg-custom-gradient {
  transition: all 0.3s ease;
  background-clip: padding-box;
  background: linear-gradient(303.98deg, #eeeffc -29.45%, #fff 60.9%);
}
.setting-item-category {
  background-clip: padding-box;
  background: linear-gradient(303.98deg, #eeeffc -29.45%, #fff 60.9%);
  cursor: pointer !important;
}
.lesson-card-box-list {
  min-height: 150px !important;
}

.lesson-card-box-grid {
  min-height: 230px !important;
  background-clip: padding-box;
  background: linear-gradient(303.98deg, #eeeffc -29.45%, #fff 60.9%);
}
.lesson-card-resize .lesson-card-box-grid {
  min-height: 270px !important;
  background-clip: padding-box;
  background: linear-gradient(303.98deg, #eeeffc -29.45%, #fff 60.9%);
}

.home-card-item * {
  color: var(--secondaryColor) !important;
}
.home-card-item:hover * {
  color: white !important;
}
.active-link {
  color: #092ace !important;
}

.hover-actions .action-items {
  opacity: 0;
}
.hover-actions:hover .action-items {
  opacity: 1;
}

/* nice trick found at: https://stackoverflow.com/a/71295590/2633871 */
.add-component-form select.form-control {
  border: 0;
  border-right: 10px solid transparent;
  outline: 1px solid var(--cbBorderColor);
  background: #fff;
}

select.form-control.outline-danger {
  outline: 1px solid #f44336 !important;
}

input[type='range'] {
  appearance: none;
  -webkit-appearance: none;
  height: 7px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #eee;
  cursor: ew-resize;
}

input[type='range']::-webkit-slider-runnable-track {
  appearance: none;
  -webkit-appearance: none;
  background-color: #ccc;
  height: 7px;
  border-radius: 8px;
  border: 1px solid #eee;
  margin-top: -16px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  width: 18px;
  height: 18px;
  background: var(--secondaryColor) !important;
  border: 1px solid var(--secondaryColor);
  border-radius: 50%;
  cursor: pointer;
  margin-top: -8px;
}

.ticks {
  display: flex;
  width: 118%;
  overflow: hidden;
  accent-color: var(--secondaryColor);
}

.tick-text {
  flex: 1;
  font-size: 11px;
  font-weight: 500;
}

.ticks .tick-text {
  position: relative;
  top: 3px;
}

.ticks .tick-text::before {
  content: '|';
  position: absolute;
  height: 10px;
  overflow: hidden;
  top: -8px;
  left: 5px;
}

.no-after::after {
  display: none;
}

.icon-action {
  font-size: 1.28rem !important;
}
.icon-hover-bg,
.btn.btn-icon.btn-sm:not(.btn-ghost-primary):not(.btn-success):not(
    .icon-ignore
  ) {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  text-align: center;
  transition: all linear 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--icon-text-color) !important;
}

.icon-hover-bg-lg {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  text-align: center;
  transition: all linear 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--icon-text-color) !important;
}

.icon-hover-bg-soft-primary {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  text-align: center;
  transition: all linear 0.2s;
  display: flex;
  justify-content: center;
  border-color: transparent !important;
  align-items: center;
  background: hsl(var(--primaryColorHsl), 100%, 97%) !important;
  color: var(--primaryColor) !important;
}
.icon-hover-bg-soft-primary:hover {
  background: hsl(var(--primaryColorHsl), 100%, 90%) !important;
}

.text-icon {
  color: var(--icon-text-color);
}

.text-icon-light {
  color: var(--light-grey);
}

.icon-hover-bg.text-primary {
  color: var(--secondaryColor) !important;
}
.icon-hover-bg.text-black,
.icon-hover-bg.text-black .material-symbols-rounded {
  color: #000 !important;
}

.icon-hover-bg:hover,
.modal-icon-bg,
.icon-hover-bg-lg:hover,
.text-icon:hover,
.btn.btn-icon.btn-sm:not(.btn-success):not(.icon-ignore):hover,
.pipelineModalHeader + .align-items-center a {
  background-color: #eee !important;
  color: #444 !important;
  border-radius: 50%;
}
.pipelines-board-col .column-deal .card .icon-hover-bg:hover {
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.text-icon-light:hover {
  background-color: #fff !important;
  color: #ccc;
  border-color: #ccc !important;
}

.border-danger {
  border-color: var(--rpt-color-red) !important;
}
.border-danger-hover:hover {
  border-color: var(--rpt-color-red) !important;
}

.border-danger-hover:hover i,
.border-danger-hover:hover .custom-icon {
  color: var(--rpt-color-red) !important;
}
.border-success-hover:hover {
  border-color: var(--wonDarkColor) !important;
}
.border-success-hover:hover i,
.border-success-hover:hover .custom-icon {
  color: var(--wonDarkColor) !important;
}
.modal-icon-bg.spacing {
  padding: 3.3px !important;
}
.modal-icon-bg i {
  font-size: 16px !important;
}

.add-icon {
  width: 35px !important;
  height: 35px !important;
}

.border-light-color {
  border-color: #f0f5f8 !important;
}

.border-left-4 {
  border-left: 4px solid #fe8888 !important;
}

select.form-control.comfort {
  border: 0;
  border-right: 10px solid transparent;
  outline: 1px solid var(--cbBorderColor);
  background: #fff;
}

.font-size-2xl.profile-icon svg {
  height: 1.5em;
  width: 1.5em;
}

.font-size-3xl.profile-icon svg {
  height: 2em;
  width: 2em;
}

.green-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--wonDarkColor);
}

.links-hover img,
.links-hover .hover-child-border {
  transition: all 0.2s linear;
}

.links-hover.selected {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  transform: scale(1.1);
  background-color: #eee;
}

.links-hover img:hover {
  box-shadow: 0 2px 1px #ccc;
  transform: scale(1.1);
}

.links-hover .hover-child-border {
  border: 2px solid var(--menuBorderColor);
}

.links-hover .hover-child-border:hover i {
  color: var(--secondaryColor) !important;
}
.links-hover .hover-child-border:hover {
  cursor: pointer;
  border-color: var(--secondaryColor) !important;
  transform: scale(1.1);
}

.prospect-detail-heading {
  padding-left: 12px;
  padding-right: 12px;
}

.sorting-custom {
  position: relative;
  cursor: pointer;
}

.sorting-custom:hover {
  background: #eff3fa !important;
}

.rotate-x-180 {
  transform: rotateX(180deg);
}

.rpt-red-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 40px;
  border: 2px solid #fcdbdb;
  border-radius: var(--rpt-widget-border-radius);
  background: var(--rpt-bg-red);
}

.rpt-red-box-lite {
  color: #fcdbdb;
}
.rpt-red-box-heading {
  color: var(--rpt-color-red) !important;
}
.rpt-orange-box-heading {
  color: var(--rpt-color-orange) !important;
}

.bg-rpt-orange {
  background: var(--rpt-color-orange) !important;
}
.rpt-red-box h1 {
  color: var(--rpt-subtext-color) !important;
}

.rpt-red-box-dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 40px;
  border: 2px solid #fcdbdb;
  border-radius: var(--rpt-widget-border-radius);
  background: var(--rpt-bg-red);
}

.rpt-red-box-dark-lite {
  color: #fcdbdb;
}
.rpt-red-box-dark-heading {
  color: var(--rpt-color-red) !important;
}

.rpt-red-box-dark h1 {
  color: var(--black) !important;
}

.rpt-green-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 40px;
  background: var(--rpt-bg-green);
  border: 2px solid var(--rpt-color-green-dark);
  border-radius: var(--rpt-widget-border-radius);
}

.rpt-yellow-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 40px;
  background: var(--rpt-bg-yellow);
  border: 2px solid var(--rpt-yellow-border-color);
  border-radius: var(--rpt-widget-border-radius);
}

.rpt-yellow-box h1 {
  color: var(--rpt-subtext-color) !important;
}

.text-rpt-blue {
  color: var(--rpt-color-blue) !important;
}
.bg-rpt-blue {
  background: var(--rpt-color-blue) !important;
}
.text-rpt-gray {
  color: var(--rpt-color-gray) !important;
}
.bg-rpt-gray {
  background: var(--rpt-color-gray) !important;
}
.text-rpt-green {
  color: var(--rpt-color-green) !important;
}
.text-rpt-red {
  color: var(--rpt-color-red) !important;
}
.bg-rpt-green {
  background: var(--rpt-color-green) !important;
}
.bg-rpt-red {
  background: var(--rpt-color-red) !important;
}
.rpt-green-box-heading {
  color: var(--rpt-color-green) !important;
}
.rpt-green-box h1 {
  color: var(--rpt-color-green) !important;
}
.check-black h1 {
  color: var(--rpt-subtext-color) !important;
}

.rpt-green-box .icon-wrap {
  background: #ffffff;
  border: 2px solid #a6d1a7;
  border-radius: 60px;
  padding: 2px;
}
.rpt-green-box .icon-money {
  background: #4da44f;
  box-shadow: 0 9px 32px rgba(54, 173, 123, 0.33);
  border-radius: 60px;
  outline: 1px solid #4da44f;
}

.green-red-check-list p:last-child {
  border-bottom: 0 !important;
}

.rpt-blue-box {
  background: hsl(var(--primaryColorHsl), 100%, 97%);
}

.rpt-blue-box-lite {
  background: hsl(var(--primaryColorHsl), 90%, 91%);
}

.rpt-bg-dark-gray {
  background: var(--rpt-color-dark-gray);
}
.rpt-dark-gray-box-heading {
  color: var(--rpt-color-dark-gray);
}

.rpt-bg-light-gray {
  background: var(--rpt-color-light-gray) !important;
}

.icon-wrap-black {
  background: #ffffff;
  border: 2px solid var(--black);
  border-radius: 60px;
  padding: 2px;
}
.icon-money-black {
  background: var(--black);
  border-radius: 60px;
  outline: 1px solid var(--black);
}

.icon-wrap-red {
  background: #ffffff;
  border: 2px solid var(--rpt-color-red);
}
.icon-money-red {
  background: var(--rpt-color-red);
}

.rpt-cover-gradient {
  background: linear-gradient(
    180deg,
    #d9d9d9 33.05%,
    rgba(217, 217, 217, 0) 86.36%
  );
  filter: blur(4px);
  position: absolute;
  bottom: 0;
  right: 0;
  height: 300px;
  width: 300px;
}

.fraud-products .flex-fill:first-child {
  border: 0;
}
.text-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-clamp-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.stickyColumn,
.stickyColumn1 {
  min-width: 1500px;
  border-collapse: collapse;
  width: 100%;
}
.tr-hover-action:hover {
  background-color: rgba(140, 152, 164, 0.05) !important;
}
.tr-hover-action:hover .action-items {
  opacity: 100 !important;
}
.stickyColumn th:first-child,
.stickyColumn td:first-child,
.stickyColumn1 th:first-child,
.stickyColumn1 td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  box-shadow: 2px 1px 5px -1px rgba(0, 0, 0, 0.05) !important;
}
.stickyColumn1.fixed-column-width th:first-child {
  width: 250px;
}
.stickyColumn th:nth-child(2),
.stickyColumn td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 45px;
  background: white;
  z-index: 2;
  max-width: 230px !important;
}

.stickyColumn th:nth-child(2)::after,
.stickyColumn td:nth-child(2)::after,
.stickyColumn1 th:first-child::after,
.stickyColumn1 td:first-child::after {
  content: '';
  position: absolute;
  right: -1px;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0, 0, 0, 0.09);
  z-index: 1;
  pointer-events: none;
  opacity: 1;
  transition: all 0.2s;
}

.stickyColumn.scrolling th:nth-child(2)::after,
.stickyColumn.scrolling td:nth-child(2)::after,
.stickyColumn1.scrolling th:first-child::after,
.stickyColumn1.scrolling td:first-child::after {
  background: rgba(0, 0, 0, 0.15) !important;
}

.stickyColumn1.scrolling .deal-add-circle,
.stickyColumn1.scrolling .hide-on-scroll {
  display: none;
}

.activity-table.scrolling .index-0-on-scroll,
.stickyColumn1.scrolling .index-0-on-scroll {
  z-index: 0 !important;
}

.activity-table.scrolling .hide-on-scroll {
  visibility: hidden;
}
.merchant-cover-heading {
  font-size: 60px;
  color: white !important;
  line-height: 80px;
  max-width: 83%;
}
.progress-circle-size {
  width: 120px;
  height: 125px;
  font-weight: bold;
}
.progress-circle-pdf {
  width: 80px;
  height: 80px;
  font-weight: bold;
}
.merchant-progress {
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  font-weight: 700;
}
.merchant-progress-pdf {
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 700;
}
.merchant-card .card {
  border-radius: 12px !important;
}
.modal-lg .merchant-p-0.modal-body {
  padding: 0px !important;
}
.badge-yellow {
  background-color: #f7e5b9 !important;
  color: #583e05;
}
.setting-item.lesson-active {
  border-color: var(--secondaryColor);
}
.setting-item.lesson-active .card-title {
  color: var(--secondaryColor) !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.px-1.deal-col.pipelines-board-col.col {
  min-width: 320px;
}
.section-owners-header.dropdown-toggle::after {
  display: none !important;
}
/* .board-header.col {
  min-width: 320px;
} */
.avatar-initial {
  height: 35px;
  width: 35px;
  display: flex;
  border-radius: 100%;
  margin-left: -15px;
  border: 1px solid white;
  color: white;
  align-items: center;
  justify-content: center;
}
.avatar-initial:not():first-child {
  margin-left: 0px !important;
}
.avatar-container > div:first-child .avatar-initial {
  z-index: 123123123123;
  position: relative;
}
.client-access {
  position: relative;
}
.client-access:hover .edit-icon {
  display: flex;
  visibility: visible;
  transition: all 0.5s ease;
  opacity: 1;
}
.edit-icon {
  opacity: 0;
  transition: all 0.5s ease;
  visibility: hidden;
  display: none;
  position: absolute;
  background: #000000;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  cursor: pointer;
}
.input-buttons {
  display: flex;
  align-items: center;
  gap: 6px;
  position: absolute;
  background: #ffffff;
  padding: 5px 10px;
  bottom: -22px;
  right: 0;
  z-index: 233;
}
.text-transform-none {
  text-transform: none !important;
}
.MuiPaper-root.MuiPaper-elevation,
.MuiDrawer-root.MuiDrawer-modal {
  z-index: 100 !important;
}
.MuiDrawer-root.MuiDrawer-modal:has(.account-structure-add-new-account) {
  z-index: 9999 !important;
}
.checklist-progress .progress-bar {
  border-radius: 7px !important;
}

.input-global-height {
  height: calc(1.6em + 1.21875rem);
}
.btn-hover-primary:hover {
  color: #fff !important;
  background-color: var(--secondaryColor) !important;
}
.input-disabled {
  text-transform: capitalize;
  border: 1px solid var(--cbBorderColor);
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 13px 13px;
}

/* Add arrows to the connectors */
.orgchart .oc-node .oc-children .oc-line::after {
  content: '';
  position: absolute;
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Specific positioning and size adjustments for vertical and horizontal lines */
.orgchart .oc-node .oc-children .oc-line-vertical::after {
  left: 50%;
  top: 100%;
  transform: translateX(-50%) rotate(45deg);
}

.orgchart .oc-node .oc-children .oc-line-horizontal::after {
  top: 50%;
  left: 100%;
  transform: translateY(-50%) rotate(45deg);
}

.accordion-sub-menu.active p {
  color: var(--secondaryColor);
  font-weight: 700 !important;
}
.report-date .rdrDefinedRangesWrapper,
.rdrInputRanges {
  display: none !important;
}

.w-33 {
  width: 33%;
}
.w-67 {
  width: 67%;
}

.table-hover tbody tr:hover {
  background-color: var(--table-hover) !important;
}
.settings-layout {
  display: flex;
  width: 100%;
  background: var(--setting-menu-color) !important;
}
.settings-layout .setting-sidebar ul::-webkit-scrollbar,
.scrollable-body.card-body::-webkit-scrollbar,
.pipelines-board-col::-webkit-scrollbar,
.checklist-layout .checklist-content .card .datatable-custom::-webkit-scrollbar,
.custom-layout .checklist-content .card .datatable-custom::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar,
.checklist-sidebar::-webkit-scrollbar,
.custom-layout
  .custom-layout-content
  .card
  .datatable-custom::-webkit-scrollbar,
.custom-layout-sidebar::-webkit-scrollbar {
  width: 6px;
}
.settings-layout .setting-sidebar ul::-webkit-scrollbar-track,
.scrollable-body.card-body::-webkit-scrollbar-track,
.pipelines-board-col::-webkit-scrollbar-track,
.custom-layout
  .checklist-layout
  .checklist-content
  .custom-layout-content
  .card
  .datatable-custom::-webkit-scrollbar-track,
.checklist-sidebar::-webkit-scrollbar-track,
.custom-scrollbar::-webkit-scrollbar-track,
.custom-layout-sidebar::-webkit-scrollbar-track {
  background: transparent;
}
.settings-layout .setting-sidebar ul::-webkit-scrollbar-thumb,
.scrollable-body.card-body::-webkit-scrollbar-thumb,
.pipelines-board-col::-webkit-scrollbar-thumb,
.custom-layout
  .checklist-layout
  .checklist-content
  .custom-layout-content
  .card
  .datatable-custom::-webkit-scrollbar-thumb,
.checklist-sidebar::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb,
.custom-layout-sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}
.setting-sidebar {
  width: var(--second-column-width);
  flex-shrink: 0;
  transition: 0.3s ease-in-out;
  border-right: 1px solid #e5ebef;
}
.setting-sidebar:not(.opportunity-left-sidebar) ul {
  height: calc(100vh - 126px);
  overflow-y: auto;
  box-sizing: border-box;
  margin-bottom: 0;
}

.setting-sidebar.opportunity-left-sidebar {
  height: calc(100vh - 65px);
  overflow-y: auto;
  box-sizing: border-box;
  margin-bottom: 0px;
}
.setting-sidebar ul li a {
  padding: 8px 20px;
}
.setting-sidebar ul li a.active h5,
.setting-sidebar ul li a.active i {
  color: var(--secondaryColor) !important;
}
.settings-content {
  flex-grow: 1;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  overflow-y: auto;
}
.custom-layout {
  display: flex;
  width: 100%;
  background: var(--setting-menu-color) !important;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
}
.custom-layout-sidebar {
  width: var(--checkist-sidebar-width) !important;
  flex-shrink: 0;
  transition: 0.3s ease-in-out;
  border-right: 1px solid #e5ebef;
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  &.no-br {
    border-right: 0 !important;
  }
}
.custom-layout-sidebar .css-gqp4xl-MuiPickersLayout-root,
.custom-layout-sidebar .css-1rtg91x-MuiDateCalendar-root {
  min-width: unset;
  width: 100%;
  max-width: 100%;
}
.custom-layout-sidebar
  .css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root:hover {
  background: hsl(var(--primaryColorHsl), 100%, 97%);
}
.custom-layout-content {
  width: 100%;
  flex-grow: 1;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  overflow-y: auto;
}
.custom-layout
  .custom-layout-content
  .card
  .datatable-custom
  .dataTables_wrapper {
  height: calc(100vh - 219px);
  overflow-y: auto;
}
.activities-layout .custom-layout-content .card .datatable-custom {
  height: calc(100vh - 182px);
  overflow-y: auto;
}
.custom-layout.checklist-layout .custom-layout-content .card .datatable-custom {
  height: calc(100vh - 180px);
}
.prospects-layout .custom-layout-content .card .datatable-custom {
  height: calc(100vh - 189px);
  overflow-y: auto;
}
.accounts-layout .custom-layout-sidebar .transparent-scroll-track {
  overflow-y: auto;
  box-sizing: border-box;
  margin-bottom: 0px;
}
.accounts-layout .datatable-custom .card-footer .sticky-table-pagination {
  padding-right: 0 !important;
}
.learn-dashboard .learn-report-card .card {
  overflow: auto;
}
.scrollable-body.card-body {
  height: calc(100vh - 138px);
  overflow-y: auto;
  padding: 0;
}

.card-borderinline {
  border-inline: none;
}
.bulk-import h3 {
  padding-inline: 1rem;
}
.pipleline-header-nametabs,
.pipeline-tabs > div,
.pipeline-tabs > div > .card,
.pipeline-tabs > div > .card,
.pipeline-tabs > div > .card > .tab-content:not(.activity-modal-tab-content) {
  height: 100%;
}
.pipeline-tabs .card,
.accounts-tabs .card,
.piple-contact-card > .card {
  box-shadow: unset !important;
}

.pipeline-tabs .card .card-header {
  border-radius: unset !important;
  padding-bottom: 0 !important;
}

.pipeline-tabs .card .card-header ul:not(.activity-modal-tabs) {
  padding-left: 16px !important;
  padding-top: 4px !important;
}

.pipeline-tabs .card .tab-content:not(.activity-modal-tab-content) {
  background-color: var(--setting-menu-color) !important;
  padding: 1rem 1rem 0 1rem !important;
}
.pipeline-tabs
  .card
  .tab-content:not(.activity-modal-tab-content)
  .overflow-hidden.p-3,
.pipeline-tabs .tab-item,
.pipeline-tabs .card .tab-content:not(.activity-modal-tab-content) .pt-3.pb-4 {
  background-color: #fff !important;
}
.pipeline-tabs .card .tab-content:not(.activity-modal-tab-content) > .pt-3.pb-4,
.pipeline-tabs .card .tab-content:not(.activity-modal-tab-content) > .p-0.card,
.pipeline-tabs
  .card
  .tab-content:not(.activity-modal-tab-content)
  > .card.mt-3 {
  height: calc(100vh - 278px) !important;
}
.pipeline-tabs
  .tab-content:not(.activity-modal-tab-content)
  > .tab-item:not(.activity-detail-tab) {
  padding: 1rem 1rem 1.72rem 1rem !important;
  min-height: calc(100vh - 278px) !important;
}

.pipeline-tabs
  .tab-content:not(.activity-modal-tab-content)
  > .tab-item.activity-detail-tab {
  padding: 1rem 0 1.72rem 0 !important;
  min-height: calc(100vh - 278px) !important;
}
.pipeline-tabs .tab-item:not(.activity-detail-tab) > h4,
.contactdetail-tabs .tab-item:not(.activity-detail-tab) > h4,
.companydetail-tabs .tab-item:not(.activity-detail-tab) > h4 {
  margin-top: 0px !important;
  padding: 0px !important;
}
.pipeline-tabs .tab-item .btn-add-activity {
  padding-bottom: 12px !important;
}
.pipeline-tabs .card .tab-content:not(.activity-modal-tab-content) {
  height: calc(100vh - 278px) !important;
  overflow-y: auto !important;
}
.pipeline-tabs .tab-content:not(.activity-modal-tab-content) .card.mt-3 {
  margin-top: 0 !important;
}

.pipeline-tabs .tab-content:not(.activity-modal-tab-content) > div {
  border-radius: var(--borderRadius) !important;
}

.pipeline-tabs
  .tab-content:not(.activity-modal-tab-content)
  > div
  > .card-header {
  border-top-right-radius: var(--borderRadius) !important;
  border-top-left-radius: var(--borderRadius) !important;
  align-items: flex-start !important;
}
.pipeline-tabs
  .tab-content:not(.activity-modal-tab-content)
  .card
  .card-body
  > div:first-child {
  align-items: flex-start !important;
}

.pipline-tab-aside {
  max-height: calc(100vh - 220px);
  overflow-y: auto !important;
}
.contactdetail-tab-aside {
  max-height: calc(100vh - 147px);
  overflow-y: auto !important;
}
.contactdetail-tabs .card .tab-content:not(.activity-modal-tab-content) {
  height: calc(100vh - 187px) !important;
  overflow-y: auto !important;
  background-color: var(--setting-menu-color) !important;
  padding: 1rem !important;
}
.contactdetail-tabs .tab-item {
  border-radius: var(--borderRadius) !important;
  padding: 1rem 1rem 1.72rem 1rem !important;
  min-height: calc(100vh - 220px) !important;
  background-color: #fff !important;
  overflow-y: auto !important;
}

.contactdetail-tabs
  .card
  .tab-content:not(.activity-modal-tab-content)
  > div.activity-detail-tab {
  padding: 1rem 0 1.72rem 0 !important;
  padding-bottom: 0 !important;
}
.companydetail-tab-aside {
  max-height: calc(100vh - 147px);
  overflow-y: auto !important;
}
.companydetail-tabs .card .tab-content:not(.activity-modal-tab-content) {
  height: calc(100vh - 187px) !important;
  overflow-y: auto !important;
  background-color: var(--setting-menu-color) !important;
  padding: 1rem !important;
}
.companydetail-tabs .card .tab-content .tab-item:not(.activity-detail-tab) h4,
.companydetail-tabs
  .card
  .tab-content
  .tab-item:not(.activity-detail-tab)
  .card-header:not(.activity-modal-header) {
  padding-inline: 0 !important;
}
.companydetail-tabs .card {
  box-shadow: none;
}
.companydetail-tab-aside .card .card-body .list-group .mb-2:last-child {
  margin-bottom: 0 !important;
}
.companydetail-tabs .card .tab-content:not(.activity-modal-tab-content) > div {
  border-radius: var(--borderRadius) !important;
  padding: 1rem 1rem 1.72rem 1rem !important;
  min-height: calc(100vh - 220px) !important;
  background-color: #fff !important;
}

.companydetail-tabs
  .card
  .tab-content:not(.activity-modal-tab-content)
  > div.dropdown-tenant-selection {
  min-height: auto !important;
}

.companydetail-tabs
  .card
  .tab-content:not(.activity-modal-tab-content)
  > .companydetail-tab-engagement {
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 1050px;
}

.companydetail-tabs
  .card
  .tab-content:not(.activity-modal-tab-content)
  > div.activity-detail-tab {
  padding: 1rem 0 1.72rem 0 !important;
  padding-bottom: 0 !important;
}

.companydetail-tabs
  .card
  .tab-content:not(.activity-modal-tab-content)
  > .companydetail-tab-contact {
  padding: 0 !important;
  margin-top: 0 !important;
}
.companydetail-tabs
  .card
  .tab-content:not(.activity-modal-tab-content)
  > .companydetail-tab-contact
  h4 {
  padding-left: 16px !important;
}

.companydetail-tab-aside .list-group-item,
.companydetail-tab-aside .card {
  border: 0;
  box-shadow: none;
}
.companydetail-tabs .card .tab-content .tab-item-deal,
.contactdetail-tabs .card .tab-content .tab-item-deal {
  padding: 0 !important;
}
.companydetail-tabs .opportunity-tab-table,
.contactdetail-tabs .opportunity-tab-table {
  min-width: 1000px;
  border-collapse: collapse;
  width: 100%;
}
.tab-item-deal .card {
  margin-top: 0 !important;
}
.tab-item-deal .card .card-header {
  padding: 0 !important;
  border-bottom: unset !important;
  align-items: flex-start !important;
}
.tab-item-deal .card .card-header h4.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.companydetail-tabs .card .tab-content .sticky-table-footer,
.contactdetail-tabs .card .tab-content .sticky-table-footer {
  height: calc(100vh - 360px) !important;
}
.companydetail-tabs
  .card
  .tab-content
  .modal-right
  .card
  .card-header:not(.activity-modal-header),
.contactdetail-tabs
  .card
  .tab-content
  .modal-right
  .card
  .card-header:not(.activity-modal-header) {
  padding: 0.5rem 1.3125rem !important;
}
.content.container-fluid.p-0 {
  overflow-x: hidden;
}
.pipleline-header-name {
  gap: 8px;
}
.pipleline-header-name:hover {
  background-color: #f5f8fa;
}
.pipleline-header-name .edit-div {
  display: none;
}
.pipleline-header-name:hover .edit-div {
  display: block;
}
.pipeline-overview-row {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 8px !important;
}
.pipeline-overview-row p {
  font-size: 14px;
  word-break: break-word;
  padding-right: 12px;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  width: 65%;
}
.pipeline-overview-row p:first-child {
  color: #515159;
  width: 35%;
}

.pipeline-overview-row.modified-at {
  padding-top: 16px !important;
}
.pipeline-overview-row.modified-at p {
  width: 100%;
}
.pipeline-overview-row.modified-at p span {
  color: #000 !important;
  font-weight: 500;
}
.pipeline-overview-row.editable-field {
  border-radius: 4px;
}
.pipeline-overview-row.editable-field:hover {
  background-color: #f1f1f1;
}
.pipeline-overview-row.no-hover:hover {
  background-color: unset;
}
.pipeline-overview-row .edit-div {
  display: none;
}
.pipeline-overview-row.editable-field:hover .edit-div {
  display: block;
}
.pipeline-overview-row.editable-field > .label_id {
  flex-grow: 1;
  margin-right: 30px;
}
.pipeline-overview-row.editable-field .lbl-btn-wrp {
  flex-direction: column;
}
.pipeline-overview-row.editable-field .lbl-btn-wrp .lbl-col-right .btn {
  width: unset;
  padding-block: 4px !important;
}
.pipeline-overview-row .form-group {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
}
.pipeline-overview-row .form-group > div {
  width: 100%;
  max-width: 100%;
  flex: 1;
  padding-right: 10px !important;
}
.pipeline-overview-row .form-group input {
  height: 35px !important;
}
.pipeline-overview-row .pickmulty .form-group .form-group .w-100 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pipeline-overview-row .pickmulty .tags-input-main {
  margin: 0 !important;
  padding-right: 0 !important;
}
.pipeline-overview-row .picksingly {
  width: 55%;
}
.pipeline-overview-row .picksingly .date-picker .dropdown {
  padding-right: 0 !important;
}
.pipeline-overview-row button[type='submit'],
.pipeline-overview-row button[type='button'] {
  display: flex !important;
  background: unset !important;
  border: unset !important;
}
.pipeline-overview-row .text-danger {
  width: 100% !important;
}

.follow-tag {
  padding: 2px 10px;
  border-radius: 8px;
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
  max-width: 95%;
  color: #212129;
  font-size: 12px;
  background: hsl(var(--primaryColorHsl), 100%, 90%) !important;
  font-weight: 600;
}
.follow-tag:hover {
  color: #212129 !important;
}
.follow-tag .unfollow-tag {
  display: none;
}
.follow-tag:hover .unfollow-tag {
  display: block;
}
.follow-tag:hover .following-tag {
  display: none;
}
.text-light {
  color: #515159;
}
.edit-heading > span {
  max-width: 500px;
  word-break: break-word;
  min-width: 150px;
}
.px-21 {
  padding-inline: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.flex-1 {
  flex: 1;
}
.split-progress-bar {
  background: #f8f8fa;
  padding: 15px 10px;
  margin: 10px 12px 0px !important;
  border-radius: 0.75rem;
}
.button-icon:hover {
  text-decoration: none !important;
}
.link-underline .btn-icon-label:hover {
  text-decoration: underline;
}
.text-align-left {
  text-align: left !important;
}
.changelog-date-picker .react-datepicker-wrapper {
  width: 100% !important;
}

.bg-gray-3 {
  background-color: #eee !important;
}
.closedStage {
  position: absolute;
  height: 100%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.closedStage.won {
  background-color: #e0f8f0;
}
.closedStage.lost {
  background-color: #fdf0f0;
}
.date-clear-btn:hover {
  text-decoration: underline;
}

.large-radio .form-check-label,
.large-check .form-check-label {
  margin-left: 5px;
}
.large-radio input[type='radio'],
.large-check input[type='checkbox'] {
  width: 13px;
  height: 13px;
  transform: scale(1.2);
}

.new-kind-of-owner-select {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.new-kind-of-owner-select .rbt-input-main {
  border-radius: 25px !important;
}

.new-kind-of-owner-select .owner-btn {
  border-radius: 25px !important;
}

.new-kind-of-owner-select .owner-btn .selected-content {
  width: 101px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block !important;
}

.new-kind-of-owner-select .owner-options {
  width: max-content !important;
  right: 4px !important;
  top: 103% !important;
}

.opportunity-list-view-table {
  height: calc(100vh - 169px) !important;
  overflow-y: auto;
}
.opportunity-list-view-table .table-container + .card-footer {
  padding-block: 7px;
  padding-inline: 16px;
}
.opportunity-list-view-table .table-container + .card-footer .row > * {
  padding-left: 0;
  padding-right: 0;
}
.checklist-layout .custom-layout-content .checklist-stats {
  padding-block: 3.7px;
}
.table-min-height .table-container {
  min-height: 350px;
}

.org-deal-table .table-container {
  height: 100% !important;
}

.cal-dot-pending {
  background-color: var(--status-gray-main);
}
.cal-dot-inProgress {
  background-color: var(--status-yellow-main);
}
.cal-dot-overdue {
  background-color: var(--status-red-main);
}
.cal-dot-completed {
  background-color: var(--status-green-main);
}
body .overflow-visible {
  overflow: visible !important;
}
.filter-task input[type='checkbox'] {
  accent-color: var(--activity-task) !important;
}
.filter-event input[type='checkbox'] {
  accent-color: var(--activity-event) !important;
}
.filter-call input[type='checkbox'] {
  accent-color: var(--activity-call) !important;
}
.accordion-filter-task {
  color: var(--activity-task) !important;
}
.accordion-filter-event {
  color: var(--activity-event) !important;
}
.accordion-filter-call {
  color: var(--activity-call) !important;
}
.cal-dot-task {
  background-color: var(--activity-task);
}
.cal-dot-event {
  background-color: var(--activity-event);
}
.cal-dot-call {
  background-color: var(--activity-call);
}
.sticky-table-footer {
  height: calc(100vh - 178px) !important;
  overflow-y: auto;
}
.settings-layout .sticky-table-footer {
  height: calc(100vh - 184px) !important;
}
.sticky-table-footer + .card-footer .pagination {
  margin-bottom: 0px;
}
.checklist-stats .pagination {
  padding-top: 4px !important;
  margin-bottom: 0 !important;
}

.accounts-table-container {
  height: calc(100vh - 218px) !important;
}
.accounts-table-container table tr {
  border-top: 1px solid var(--borderLight) !important;
}
.tenants-table-container,
.learning-table-container,
.ida-table-container {
  height: calc(100vh - 238px) !important;
}

/* =========New Custom AnimatedTabs======= */
.new-custom-tabs {
  border-bottom: 1px solid #e5ebef !important;
  padding-bottom: 1px !important;
}
.new-custom-tabs .nav-item {
  min-width: 180px !important;
  border-right: 1px solid #e5ebef;
}
.new-custom-tabs .nav-item-active {
  padding-bottom: 1px !important;
  background-color: white;
}

.nav-item .nav-item-icon {
  display: none;
  position: absolute;
  right: 16px;
  padding: 3px;
}
.nav-item .nav-item-icon:hover {
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
}
.nav-item:hover .nav-item-icon {
  display: flex;
}
.disable-btn {
  pointer-events: none;
}
.related-org-name {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #515159 !important;
  font-family: Inter, sans-serif !important;
}

.organization-edit-form .naics-field .dropdown-toggle {
  border: unset !important;
}
.propescting-sidebar .rounded-pill {
  padding: 4px !important;
  background-color: #fff;
}
.propescting-sidebar .rounded-pill a {
  width: 50% !important;
  padding: 6px !important;
}
.prospects-layout .custom-layout-content .prospect-stats .pagination {
  margin-bottom: 0 !important;
}
.prospect-filter-wrapper {
  border-radius: 8px;
  margin-bottom: 10px;
}
.prospect-filter-wrapper .nav-link.bg-primary {
  margin-top: 1px !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.prospect-search-wrapper {
  border: 2px solid var(--secondaryColor) !important;
}
.prospect-search-wrapper:hover .nav-link {
  border-radius: 6px !important;
}
.prospect-search-wrapper:hover .nav-link.bg-primary {
  border-radius: 0 !important;
}
.prospect-search-wrapper .nav-link.bg-primary {
  margin-top: 0 !important;
}
.accordion-content-wrapper {
  background-color: #fff !important;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.chart-total-row {
  position: absolute;
  bottom: -16px !important;
  left: 155px;
  width: calc(100% - 160px);
}

.accordion-content-wrapper .rounded-pill a:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.accordion-content-wrapper .rounded-pill a:last-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.competitor-card-item {
  cursor: pointer;
  border-color: #dbe4eb !important;
}

.competitor-card-item:hover {
  background-color: #eef6fd !important;
}

.competitor-card-item .competitor-stats {
  margin-top: 8px;
  border-radius: 8px;
  background-color: #d3e9fa;
  padding: 8px 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.competitor-card-item .competitor-stats > div:last-child {
  grid-column: span 3;
}

.accordion-wrapper {
  border-radius: 8px;
  border: 2px solid var(--cbBorderColor);
  margin-bottom: 10px;
}
.accordion-wrapper .accordion-toggle {
  border-radius: 8px;
  width: 100%;
  border: 0 !important;
}

.accordion-wrapper .bg-accordion-active {
  border-bottom: 0.0625rem solid var(--cbBorderColor) !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* ==================Chat Sidebar Section================== */

.chat-sidebar .new-chat {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 8px 8px 8px 16px;
  color: #677788;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
}

.chat-sidebar .new-chat:focus,
.chat-sidebar .new-chat:hover {
  border-color: var(--primaryColor) !important;
}

.chat-history-items {
  display: flex;
  flex-direction: column;
}
.chat-history-item {
  cursor: pointer;
  position: relative;
  padding-right: 12px !important;
}
.chat-history-item:hover {
  background-color: var(--table-hover);
  padding-right: 4px !important;
}
.chat-history-item p {
  font-size: 12px;
  color: #696e75;
  font-weight: 500;
  white-space: nowrap;
  mask-image: var(--mask);
}

.chat-history-item:hover p,
.chat-history-item.active p {
  color: var(--primaryColor);
}

.more-actions-wrapper {
  position: absolute;
  right: 0;
}

.more-actions-wrapper .add-more-btn {
  visibility: hidden;
}

.chat-history-item:hover .more-actions-wrapper .add-more-btn,
.more-actions-wrapper .idf-dropdown-item-list.show .add-more-btn {
  visibility: visible;
}

.chat-history-item:has(.idf-dropdown-item-list.show) {
  padding-right: 4px !important;
}
.chat-history-item:has(.idf-dropdown-item-list.show) .add-more-btn {
  background-color: #eee !important;
  color: #444 !important;
}

.chat-history-item.active .fade-out::after,
.chat-history-item:hover .fade-out::after {
  content: unset !important;
}

.chat-history-item:hover .history-item {
  padding-right: 2rem;
}

.chat-history-item .more-actions-wrapper .add-more-btn {
  transition: none !important;
}

/* ==========Chat Home Section========= */
.chat-home-window-size {
  max-width: var(--chat-ask-anything-size);
}
.chat-home-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--chat-ask-anything-size);
}

.chat-uploaded-files {
  border-radius: 0.5rem;
  margin: 16px 16px 0;
  max-width: max-content;
  display: flex;
  align-items: center;
}

.chat-uploaded-files.chat-img,
.chat-uploaded-files.chat-img .img-border {
  border: 2px solid transparent;
}
.chat-uploaded-files.chat-img:hover .img-border {
  border-color: var(--black) !important;
}

.chat-files-modal .chat-uploaded-files {
  margin: 0;
  max-width: none;
  /* width: 200px; */
  width: 100%;
}

.chat-uploaded-files .files-icon {
  padding: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--cbTextGray);
}

.chat-uploaded-files .files-images {
  padding: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-uploaded-files .files-info {
  display: flex;
  flex-direction: column;
}

.chat-uploaded-files .files-info p {
  font-weight: 500;
}

.chat-uploaded-files .files-info span {
  color: #677788;
  font-size: 13px !important;
}
/*

.thread-input .chat-uploaded-files {
  position: absolute;
  width: 350px;
  right: 0px;
  height: 45px;
  top: -60px;
}
*/

.thread-chat-container {
  border-radius: 20px;
}
.thread-chat-container:has(.thread-input) {
  background-color: var(--table-hover);
  padding: 6px;
  border-radius: 50rem !important;
}

.thread-chat-container:has(.thread-input) .chat-input-container {
  border-color: #d4d1d1 !important;
}

.home-chat-container {
  background-color: var(--table-hover);
  padding: 6px;
  border-radius: 22px !important;
}

.home-chat-container .chat-input-container {
  border-color: #d4d1d1 !important;
}

.chat-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  border: 1px solid var(--cbBorderColor);
  border-radius: 1rem;
  padding-bottom: 45px;
  background-color: #fff;
  /*--tw-shadow-color: rgba(0,0,0,.05);
  --tw-shadow: 0 9px 9px 0px rgba(0, 0, 0, .01), 0 2px 5px 0px rgba(0, 0, 0, .06);
  --tw-shadow-colored: 0 9px 9px 0px var(--tw-shadow-color), 0 2px 5px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);*/
}

.chat-input-container.focused {
  border-color: var(--primaryColor) !important;
}

.chat-input-container textarea {
  width: 100%;
  padding: 16px 16px 8px;
  resize: none;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 1rem;
  color: var(--black);
}
.chat-input-container.thread-input {
  border-radius: 50rem !important;
  height: 50px !important;
}
.chat-input-container .controls {
  position: absolute;
  bottom: 10px;
  left: 8px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-input-container .controls div:first-child button:hover {
  background-color: #f5f5f5;
}
.chat-input-container .controls button[type='submit'] {
  color: white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.chat-input-container.thread-input .controls {
  bottom: 3.5px !important;
}
.chat-input-container.thread-input input,
.chat-input-container.thread-input textarea {
  border-radius: 50rem !important;
}

.chat-suggestions {
  position: absolute;
  background: white;
  border-top: unset;
  padding-top: 1px;
  width: calc(100% + 2px);
  left: -1px;
  top: 121px;
}

.chat-input-container.chat-with-files .chat-suggestions {
  top: 196px;
}

.chat-input-container.disabled,
.chat-input-container.disabled textarea {
  background-color: #fdfdfd !important;
}

.chat-suggestions .chat-suggestion-item {
  cursor: pointer;
  padding: 8px;
}

.chat-suggestions .chat-suggestion-item:last-child {
  border-bottom: unset !important;
}

.chat-suggestions .chat-suggestion-item:hover,
.chat-suggestions .chat-suggestion-item.highlighted-item {
  background-color: var(--table-hover);
  border-radius: 0.5rem;
}

.chat-suggestions .chat-suggestion-item:hover,
.chat-suggestions .chat-suggestion-item.previous-hover {
  border-color: transparent !important;
}

.text-muted-100 {
  color: var(--text-muted);
}

.loading-circle {
  display: inline-block;
  animation: blink 1s infinite;
  font-size: 28px;
  color: grey;
  margin-right: 5px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.knowledge-item {
  position: relative;
  background-color: var(--setting-menu-color);
  padding: 8px;
  transition: background-color 0.3s;
  border: 1px solid var(--cbBorderColor);
  width: 200px;
  height: 130px;
}
/*
.knowledge-item:hover {
  background-color: #e9ecef;
}*/

.knowledge-item::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: -4px;
  right: -4px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-right: 1px solid var(--cbBorderColor);
  border-bottom: 1px solid var(--cbBorderColor);
  pointer-events: none;
  border-radius: 8px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.knowledge-item:hover::after {
  right: -5px;
  height: calc(100% + 5px);
}
.knowledge-item.knowledge-item-premium,
.knowledge-item.knowledge-item-premium::after {
  border-color: var(--status-premium);
}

.content-item:hover {
  background-color: #e9ecef;
  border-radius: 4px;
}

.lesson-card {
  box-shadow: unset !important;
  border: 1px solid #d9d9d9 !important;
}
.lesson-card:hover {
  border-color: var(--primaryColor) !important;
}
.lesson-card,
.lesson-card .card-body,
.lesson-card .card-footer {
  border-radius: var(--rounded) !important;
}
.lesson-card .card-body {
  height: 170px;
  background-color: var(--setting-menu-color) !important;
  border-bottom-left-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
.lesson-card .card-body .course-title {
  color: #444 !important;
}
.lesson-card:hover .card-body .course-title {
  color: var(--primaryColor) !important;
}
.lesson-card .card-footer {
  height: 45px;
  border-top: unset !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}

.course-card-parent {
  z-index: 1;
}
.lesson-card-resize .card-body,
.course-card .card-body {
  height: 200px !important;
}

.course-card .card-body::before,
.course-card .card-body::after {
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: var(--rounded) !important;
  content: '';
  display: block;
  height: 10px;
  position: absolute;
}

.course-card .card-body::before {
  left: 12px;
  top: -7px;
  width: calc(100% - 24px);
  z-index: -1;
}

.course-card .card-body::after {
  left: 24px;
  top: -13px;
  width: calc(100% - 48px);
  z-index: -2;
}

.lesson-layout-card,
.lesson-learn-card {
  box-shadow: unset !important;
  border: 1px solid #d9d9d9 !important;
}
.lesson-layout-card,
.lesson-layout-card .card-body,
.lesson-layout-card .card-header,
.lesson-learn-card,
.lesson-learn-card .card-body {
  border-radius: var(--rounded) !important;
}

.lesson-layout-card .card-header {
  background-color: #f7f7f7 !important;
}
.lesson-layout-card p {
  color: #444 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  margin-bottom: 0 !important;
}
.lesson-layout-card p.lesson-name {
  font-size: 14px !important;
  color: #4a4a4a !important;
}
.lesson-items .lesson-item:not(:last-child) {
  border-bottom: 1px solid #d9d9d9 !important;
}
.disabled-lesson {
  pointer-events: none !important;
  cursor: not-allowed !important;
  /* opacity: 0.4; */
}
.disabled-lesson .lesson-name,
.disabled-lesson .lesson-actions,
.disabled-lesson .cursor-pointer-important {
  opacity: 0.4;
}
.lesson-learn-card {
  height: 440px;
}
.lesson-learn-card .card-body .card-title {
  color: #444 !important;
  font-weight: 500 !important;
}

.modal-content .hide-heading {
  display: none !important;
}

.assess-back-arrow {
  position: absolute;
  left: 6px;
  top: 12px;
  z-index: 999;
}
.content-create-page {
  width: 100%;
  min-height: calc(100vh - 107px);
  overflow-y: auto;
  background-color: #fff;
}

.content-create-page .purchase-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-weight: 500;
}
.content-create-page .purchase-card img {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
}
.content-create-page .purchase-card:hover {
  border-color: var(--primaryColor);
}
.content-create-page .purchase-card.active svg path {
  stroke: var(--primaryColor);
}
/* in case of free icon for last path  */
.content-create-page .purchase-card:last-child.active svg path:last-child {
  stroke: none;
  fill: var(--primaryColor);
}

/* ========ag-grid-react============  */

.ag-cell-label-container .dropdown-toggle::after {
  display: none !important;
}

.ag-grid-header-menu {
  margin: 8px 0px 0px 8px !important;
  position: fixed !important;
  z-index: 9999 !important;
}

.ag-grid-header-toggle:hover {
  background-color: unset !important;
  box-shadow: none !important;
}

.ag-grid-header-toggle i {
  display: block !important;
  background-color: rgba(140, 152, 164, 0.1) !important;
}

.dropdown.show .ag-grid-header-toggle i,
.ag-grid-header-toggle:hover i {
  background-color: rgba(140, 152, 164, 0.2) !important;
}

.ag-header-cell:hover .ag-grid-header-toggle i,
.dropdown.show .ag-grid-header-toggle i {
  display: block !important;
}

.ag-grid-header-menu .dropdown-item {
  padding: 8px 12px !important;
}
.ag-grid-header-menu .dropdown-item:hover {
  background-color: rgba(140, 152, 164, 0.05) !important;
}
.ag-grid-header-menu .dropdown-divider {
  margin: 0.2rem 0 !important;
}
.ag-grid-header-menu .ag-menu-sort-icon {
  margin-top: -4px;
  padding-right: 2px;
}
.ag-grid-header-menu .ag-menu-sort-icon i {
  width: 8px !important;
  height: 18px !important;
  font-size: 18px !important;
}
:where(.ag-icon):before {
  background-color: gray !important;
  width: 14px !important;
}
:where(.ag-icon.ag-icon-active):before {
  background-color: var(--primaryColor) !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.ag-header-cell-text {
  text-transform: uppercase !important;
}

.ag-header-row.ag-header-row-column {
  border-top: 1px solid var(--borderLight);
}

.ag-header-cell.ag-column-first {
  padding-left: 21px !important;
}

.ag-cell.ag-column-first {
  padding-left: 20px !important;
}

.ag-row .ag-cell {
  font-family: Inter, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.ag-header-select-all .ag-checkbox-input-wrapper,
.ag-selection-checkbox .ag-checkbox-input-wrapper {
  border: #d6dbeb solid 0.0625rem !important;
  width: 16px !important;
  height: 16px !important;
}

.ag-header-select-all .ag-checkbox-input-wrapper.ag-checked,
.ag-selection-checkbox .ag-checkbox-input-wrapper.ag-checked {
  border-color: var(--primaryColor) !important;
}

.ag-header-select-all .ag-checkbox-input-wrapper input:focus-within,
.ag-selection-checkbox .ag-checkbox-input-wrapper input:focus-within,
.ag-checkbox-input-wrapper input[type='checkbox']:focus-visible,
.ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-checkbox-input:focus-visible {
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
:where(.ag-theme-checkboxStyle-1) {
  & .ag-checkbox-input-wrapper {
    &:where(.ag-checked) {
      &::after {
        mask-image: none !important;
        border-radius: 2px !important;
        background-color: var(--secondaryColor) !important;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 9px;
        height: 9px;
        z-index: 2;
        pointer-events: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='110' height='110' viewBox='0 0 110 110'%3e%3cpath fill='%23fff' d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1  l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3e%3c/svg%3e");
      }
    }
  }
}
:where(.ag-theme-checkboxStyle-1) {
  & .ag-checkbox-input-wrapper,
  & .ag-radio-button-input-wrapper {
    &:where(:focus-within, :active) {
      box-shadow: none !important;
    }
  }
}

.ag-cell .activities-menu-actions {
  position: fixed !important;
  transform: translate3d(-67px, 6px, 0px) !important;
}

.opportunity-list-view-table
  .ag-cell
  .activities-btn
  .activities-without-activities
  .icon-hover-bg {
  top: unset !important;
}

.opportunity-list-view-table .ag-cell .deal-add-circle {
  display: none;
  z-index: 9999 !important;
}
.opportunity-list-view-table .ag-cell .deal-add-circle.active,
.opportunity-list-view-table .ag-cell:hover .deal-add-circle {
  display: block;
}
.ag-header-cell:hover {
  background: var(--rpt-inner-section-border-color);
}
.ag-header-cell-resize {
  width: 8px;
  transition: all 0.2s linear;
}

.ag-header-cell-resize::after {
  transition: all 0.2s linear;
}
.ag-header-cell:hover .ag-header-cell-resize::after {
  background: var(--primaryColor);
  height: 100% !important;
  top: 0 !important;
}

.ag-menu-sort-right.show.dropdown .dropdown-menu {
  transform: translate3d(138px, -11px, 0px) !important;
}

.ag-menu-sort-left.show.dropdown .dropdown-menu {
  transform: translate3d(-149px, -11px, 0px) !important;
}

@keyframes highlight-animation {
  0% {
    background-color: #fff8eb;
  }
  100% {
    background-color: transparent;
  }
}
.ag-moved-column {
  animation: highlight-animation 2s ease-in-out;
}
.ag-pinned-left-header .ag-header-cell-resize {
  right: 2px !important;
}

.ag-sticky-column
  .ag-pinned-left-header:has(
    .ag-header-row
      > .ag-header-cell:only-child[col-id='ag-Grid-SelectionColumn']
  ),
.ag-sticky-column
  .ag-pinned-left-cols-container
  .ag-row:has(> .ag-cell:only-child[col-id='ag-Grid-SelectionColumn'])
  > .ag-cell {
  border-right: unset !important;
}

.ag-pinned-left-cols-container,
.ag-pinned-left-header {
  --ag-pinned-column-border: var(
    --ag-inherited-pinned-column-border,
    solid 2px var(--ag-border-color)
  );
}

.time-now p {
  line-height: 22px !important;
}

.stats-icon {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsl(var(--primaryColorHsl), 100%, 97%) !important;
  i {
    color: var(--secondaryColor) !important;
  }
}

.more-options button {
  height: 38px;
  border-radius: 9999px;
  width: max-content;
  position: relative;
}

.canva-section button {
  height: 38px;
  border-radius: 9999px;
  transition: background-color 0.15s ease-in-out, filter 0.15s ease-in-out;
  width: max-content;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.canva-section button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: opacity 0.15s ease-in-out;
  background: linear-gradient(
    101deg,
    rgba(201, 240, 255, 0.6) -38.66%,
    rgba(225, 206, 255, 0.8) 129.48%
  );
}

.canva-section button:not(.no-hover):hover::before {
  opacity: 0.6;
  background: rgba(165, 112, 255, 0.4);
}

.canva-section button i {
  color: var(--rpt-tile-as-icon) !important;
}

.text-gradient {
  --bard-color-gallery-image-card-text-rgb: 255, 255, 255;
  --bard-color-brand-text-gradient-stop-1: #4285f4;
  --bard-color-brand-text-gradient-stop-1-rgb: 66, 133, 244;
  --bard-color-brand-text-gradient-stop-2: #9b72cb;
  --bard-color-brand-text-gradient-stop-2-rgb: 155, 114, 203;
  --bard-color-brand-text-gradient-stop-3: #d96570;
  --bard-color-brand-text-gradient-stop-3-rgb: 217, 101, 112;
  --gem-sys-color--on-surface: #fff;
  --gem-sys-color--surface: #1b1c1d;
  color: transparent;
  background: -webkit-linear-gradient(
    16deg,
    var(--bard-color-brand-text-gradient-stop-1) 0,
    var(--bard-color-brand-text-gradient-stop-2) 9%,
    var(--bard-color-brand-text-gradient-stop-3) 20%,
    var(--bard-color-brand-text-gradient-stop-3) 24%,
    var(--bard-color-brand-text-gradient-stop-2) 35%,
    var(--bard-color-brand-text-gradient-stop-1) 44%,
    var(--bard-color-brand-text-gradient-stop-2) 50%,
    var(--bard-color-brand-text-gradient-stop-3) 56%,
    var(--gem-sys-color--surface) 75%,
    var(--gem-sys-color--surface) 100%
  );
  background: linear-gradient(
    74deg,
    var(--bard-color-brand-text-gradient-stop-1) 0,
    var(--bard-color-brand-text-gradient-stop-2) 9%,
    var(--bard-color-brand-text-gradient-stop-3) 20%,
    var(--bard-color-brand-text-gradient-stop-3) 24%,
    var(--bard-color-brand-text-gradient-stop-2) 35%,
    var(--bard-color-brand-text-gradient-stop-1) 44%,
    var(--bard-color-brand-text-gradient-stop-2) 50%,
    var(--bard-color-brand-text-gradient-stop-3) 56%,
    var(--gem-sys-color--surface) 75%,
    var(--gem-sys-color--surface) 100%
  );

  background-size: 400% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ida-bot-img svg.text-gradient path {
  fill: #d96570;
  stroke: #d96570;
}

.ida-bot-img svg.text-gradient path:first-child {
  fill: #9b72cb;
  stroke: #9b72cb;
}

@keyframes shine {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.ask-ida-modal {
  .markdown-body {
    border: 0 !important;
  }
}

.ida-suggestions.bg-gray-table-head:hover {
  background-color: var(--table-hover) !important;
}

.duration-500 {
  transition-duration: 1.5s;
}
.leading-relaxed {
  line-height: 1.625;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.opacity-100 {
  opacity: 1;
}
.translate-y-0 {
  --tw-translate-y: 0px;
}
.translate-x-px,
.translate-y-0 {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-1 {
  --tw-translate-y: 0.25rem;
}

.opacity-zero {
  opacity: 0;
}

.tab-content.custom-scrollbar .no-db-pagination {
  height: 0 !important;
}

.chat-attach-more-menu {
  position: absolute;
  left: 0;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.chat-attach-more-menu.menu-open {
  left: -30px;
  opacity: 1;
  visibility: visible;
}

.chat-img-cross {
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: var(--cbTextGray);
  & svg {
    color: white;
  }
  &:hover {
    background-color: var(--cbTextBlack) !important;
  }
}

.files-dropdown .dropdown-toggle::after {
  display: none !important;
}

.files-dropdown .dropdown-toggle .avatar {
  width: 30px !important;
  height: 30px !important;
}

.files-dropdown .dropdown-toggle .avatar img {
  width: 28px !important;
  height: 28px !important;
}

.files-dropdown .dropdown-menu .button-icon.border-0:hover {
  background: unset !important;
}

.files-dropdown-align-top {
  transform: translate3d(110px, 42px, 0px) !important;
}

.object-cover {
  object-fit: cover;
}
.text-purple {
  color: #8d32e3 !important;
}
.gradient-border {
  border: 2px solid transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #8d32e3, #4b0082);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.takeaways-list {
  list-style-type: disc;
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-height: 500px;
  transition: max-height 0.5s ease;
}

.bullet-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #8d32e3;
}

.bullet-item {
  position: relative;
  padding-left: 20px;
  line-height: 1.5;
  color: #333;
  font-size: 12px;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.bullet-item:nth-child(1) {
  transition-delay: 0.1s;
}

.bullet-item:nth-child(2) {
  transition-delay: 0.2s;
}

.bullet-item:nth-child(3) {
  transition-delay: 0.3s;
}

.detail-page-takeaways .takeaways-list .bullet-item::before {
  background-color: #8d32e3 !important;
}
.detail-page-takeaways .accordian-label,
.detail-page-takeaways .accordian-arrow-icon {
  color: #8d32e3 !important;
}

.advanced-filter-item .clear-btn {
  display: none;
}

.advanced-filter-item:hover .clear-btn {
  display: block;
}

.animate-hover .avatar-hidden {
  height: 0;
  width: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.animate-hover:hover .avatar-hidden {
  height: 32px;
  width: 32px;
  opacity: 1;
  display: inline-flex !important;
}

.animate-hover .avatar-item > div {
  transition: all 0.2s ease-in-out;
}
.animate-hover:hover .avatar-item > div {
  margin-left: -1px !important;
}
.animate-hover:hover .more-dropdown {
  display: none !important;
}

.dashboard {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

/* Header styles (Used on Overview/Conv Log) */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 20px;
}

.dashboard-header h1 {
  font-size: 24px;
  font-weight: 600;
  color: #2c3e50;
  white-space: nowrap;
}

/* Filter styles (Standard - Used on Overview/Conv Log/Tenant Log) */
.filters {
  /* Container for multiple standard filters */
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.filter-group {
  /* Grouping standard filters */
  display: flex;
  gap: 15px;
  flex-wrap: nowrap;
}

.filter i {
  color: #7f8c9d;
  font-size: 14px;
  margin-right: 8px;
  line-height: 1;
}

.filter i.fa-chevron-down {
  position: absolute;
  right: 15px;
  font-size: 12px;
  pointer-events: none;
  margin-right: 0;
}

.filter select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: transparent;
  font-size: 14px;
  padding-right: 25px;
  width: 100%;
  cursor: pointer;
  color: #2c3e50;
  position: relative;
  z-index: 1;
  background-image: none;
}

.filter select:focus {
  outline: none;
}

/* Export button styles (Standard - Used on Overview/Conv Log) */
.action-buttons {
  position: relative;
  display: flex;
}

.export-btn {
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 0 20px;
  height: 40px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;
  white-space: nowrap;
}

.export-btn i {
  font-size: 14px;
}

.export-btn:hover {
  background-color: #2980b9;
}

/* Metrics container styles (Standard - Used on ALL pages) */
.metrics-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.metric-card {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  padding: 0;
  display: flex;
  align-items: center;
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden;
  position: relative;
}

.metric-card::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
}

/* === Assign bar colors (Overview) === */
.metric-card.companies::before {
  background-color: #9b59b6;
}
.metric-card.users::before {
  background-color: #1abc9c;
}
.metric-card.questions::before {
  background-color: #f39c12;
}
.metric-card.feedback::before {
  background-color: #2ecc71;
}

/* === Assign bar colors (Conversation Log) === */
.metric-card.total-conversations::before {
  background-color: #5dade2;
}
.metric-card.positive-feedback-rate::before {
  background-color: #2ecc71;
}
.metric-card.negative-feedback-count::before {
  background-color: #e74c3c;
}
.metric-card.avg-daily-conversations::before {
  background-color: #f39c12;
}

/* === Assign bar colors (Tenant Log) === */
.metric-card.total-tenants::before {
  background-color: #1c5ff5;
}
/* .metric-card.total-users::before defined with .users::before */
.metric-card.total-revenue::before {
  background-color: #2ecc71;
}
.metric-card.avg-users-tenant::before {
  background-color: #1abc9c;
}

/* Metric Icon Container (Standard) */
.metric-icon {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  flex-shrink: 0;
}

.metric-icon i {
  font-size: 22px;
  color: #444; /* Default icon color */
  line-height: 1;
}

/* === Light background colors for icons (Overview) === */
.metric-icon.companies {
  background-color: #f4ecf7;
}
.metric-icon.users {
  background-color: #e8f8f5;
}
.metric-icon.questions {
  background-color: #fef5e7;
}
.metric-icon.feedback {
  background-color: #eafaf1;
}

/* === Light background colors for icons (Conversation Log) === */
.metric-icon.total-conversations {
  background-color: #eaf2f8;
}
.metric-icon.positive-feedback-rate {
  background-color: #eafaf1;
}
.metric-icon.negative-feedback-count {
  background-color: #fadbd8;
}
.metric-icon.avg-daily-conversations {
  background-color: #fef5e7;
}

/* === Light background colors for icons (Tenant Log) === */
.metric-icon.total-tenants {
  background-color: #eaf1ff;
}
/* .metric-icon.total-users background defined by .users */
.metric-icon.total-revenue {
  background-color: #eafaf1;
}
.metric-icon.avg-users-tenant {
  background-color: #e8f8f5;
}

/* === Specific Icon Color Overrides === */
.metric-icon.negative-feedback-count i {
  color: #e74c3c;
}
.metric-icon.total-tenants i {
  color: #1c5ff5;
}
/* .metric-icon.total-users icon color defined by .users */
.metric-icon.total-revenue i {
  color: #2ecc71;
}
.metric-icon.avg-users-tenant i {
  color: #1abc9c;
}

/* Metric Content Text (Standard) */
.metric-content {
  padding: 20px 20px 20px 0;
  overflow: hidden;
  min-width: 0;
}

.metric-content h3 {
  font-size: 14px;
  font-weight: 500;
  color: #7f8c9d;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.metric-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.percentage {
  font-size: 18px;
  color: #7f8c9d;
  margin-left: 2px;
}

/* === Styles Specific to Tenant User Log Header === */
.tenant-log-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 20px;
}

.tenant-log-header h1 {
  font-size: 24px;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
}

.tenant-actions {
  /* Container for filters + export */
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

/* Simple Filter Style (Tenant Log Month Selector) */
.simple-filter {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ecf0f1; /* Has border */
  border-radius: 6px;
  padding: 0 15px;
  height: 40px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  min-width: 180px;
  position: relative;
}
.filter {
  /* Individual standard filter box */
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 0 15px;
  height: 40px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  min-width: 150px;
  border: 1px solid transparent; /* Base border */
}
.simple-filter i.fa-calendar {
  color: #7f8c9d;
  font-size: 14px;
  margin-right: 8px;
}

.simple-filter select {
  flex-grow: 1;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: transparent;
  font-size: 14px;
  padding-right: 25px;
  cursor: pointer;
  color: #2c3e50;
  background-image: none;
}

.simple-filter select:focus {
  outline: none;
}

.simple-filter i.fa-chevron-down {
  position: absolute;
  right: 15px;
  font-size: 12px;
  color: #7f8c9d;
  pointer-events: none;
}

/* Simple Export Button Style (Tenant Log - Blue) */
.simple-export-btn {
  background-color: #1c5ff5;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0 15px;
  height: 40px;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;
  text-decoration: none;
  white-space: nowrap;
}

.simple-export-btn i {
  font-size: 14px;
}

.simple-export-btn:hover {
  background-color: #114bcd;
}

/* === Styles for Overview Page Data Cards === */
.data-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.data-card {
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  border-bottom: 1px solid #ecf0f1;
  flex-shrink: 0;
}

.card-header h2 {
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-actions {
  display: flex;
  gap: 10px;
  flex-shrink: 0;
}

.card-actions button {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: none;
  background-color: #f5f7fa;
  color: #7f8c9d;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 0;
}

.card-actions button:hover {
  background-color: #ecf0f1;
  color: #2c3e50;
}

.card-actions button i {
  line-height: 1;
}

.card-content {
  padding: 10px 0;
  overflow-y: auto;
  flex-grow: 1;
}

.ranked-list {
  list-style: none;
}

.ranked-list li {
  display: flex;
  align-items: center;
  padding: 12px 25px;
  transition: background-color 0.2s;
  min-height: 40px;
}

.ranked-list li:hover {
  background-color: #f5f7fa;
}

.rank {
  width: 28px;
  height: 28px;
  border-radius: 6px;
  background-color: #f5f7fa;
  color: #7f8c9d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin-right: 15px;
  flex-shrink: 0;
}

.list-item {
  font-size: 14px;
  color: #2c3e50;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  min-width: 0;
}

/* === Styles for Conversation Log & Tenant Log Tables === */


.log-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px; /* Prevent extreme squishing */
}

.log-table th,
.log-table td {
  padding: 15px 20px;
  text-align: left;
  border-bottom: 1px solid #ecf0f1;
  font-size: 14px;
  white-space: nowrap;
  vertical-align: middle;
}

.log-table th {
  font-weight: 600;
  color: #7f8c9d;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #f5f7fa;
  border-top: 1px solid #ecf0f1;
}

.log-table tbody tr:last-child td {
  border-bottom: none;
}

.log-table tbody tr:hover {
  background-color: #f5f7fa;
}

/* Feedback Cell Styling (Conversation Log) */
.feedback-cell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.feedback-icon {
  font-size: 18px;
  color: #7f8c9d; /* Default color */
  flex-shrink: 0;
  width: 20px;
  text-align: center;
}

.feedback-bar {
  display: flex;
  width: 80px;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #eee;
}

.feedback-bar-segment {
  height: 100%;
  transition: flex-basis 0.3s ease;
}

.feedback-bar-segment.green {
  background-color: #a5d6a7;
}
.feedback-bar-segment.red {
  background-color: #ef9a9a;
}
.feedback-cell.positive .feedback-icon {
  color: #2ecc71;
}
.feedback-cell.negative .feedback-icon {
  color: #e74c3c;
}

/* === Styles for Conversation Detail Page === */

.conversation-detail-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin-top: 20px;
  overflow: hidden;
}

.conversation-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid #ecf0f1;
}

.conversation-detail-header h2 {
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
}

.close-button {
  display: inline-block;
  padding: 8px 15px;
  background-color: #f5f7fa;
  color: #2c3e50;
  border-radius: 6px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #ecf0f1;
  transition: background-color 0.2s, color 0.2s;
}

.close-button:hover {
  background-color: #ecf0f1;
}

.chat-log-container {
  padding: 25px;
  max-height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chat-marker {
  text-align: center;
  color: #7f8c9d;
  font-size: 12px;
  text-transform: uppercase;
  margin: 15px 0;
  font-weight: 500;
}

.chat-message {
  max-width: 80%;
  padding: 15px;
  border-radius: 8px;
  line-height: 1.5;
  position: relative;
}

.chat-message.user {
  background-color: #eaf1ff;
  color: #2c3e50;
  align-self: flex-end;
  border-radius: 8px 8px 0 8px;
}

.chat-message.ai {
  background-color: #f5f7fa;
  color: #333;
  align-self: flex-start;
  display: flex;
  gap: 15px;
  border: 1px solid #ecf0f1;
  border-radius: 8px 8px 8px 0;
}

.ai-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #2c3e50;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.message-content {
  flex-grow: 1;
}
.message-content p {
  margin-bottom: 0.5em;
}
.message-content p:last-child {
  margin-bottom: 0;
}
.message-content ol,
.message-content ul {
  padding-left: 20px;
  margin: 0.5em 0;
}
.message-content strong {
  font-weight: 600;
  color: #2c3e50;
}

.message-feedback {
  margin-top: 15px;
  padding-top: 8px;
  border-top: 1px solid #ecf0f1;
  display: flex;
  gap: 15px;
  align-items: center;
}

.feedback-control {
  color: #7f8c9d;
  cursor: pointer;
  font-size: 18px;
  transition: color 0.2s;
}
.feedback-control:hover {
  color: #2c3e50;
}
.feedback-control.positive {
  color: #2ecc71;
}
.feedback-control.positive:hover {
  color: #27ae60;
}
.feedback-control.negative {
  color: #e74c3c;
}
.feedback-control.negative:hover {
  color: #c0392b;
}

.message-feedback.feedback-given .feedback-control {
  cursor: default;
}
.message-feedback.feedback-given
  .feedback-control:not(.positive):not(.negative) {
  opacity: 0.5;
  pointer-events: none;
}

/* === Responsive styles === */
@media (max-width: 768px) {
  /* Adjust headers */
  .dashboard-header,
  .tenant-log-header {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 20px;
  }
  /* Tenant header specific alignment */
  .tenant-log-header .tenant-actions {
    width: 100%;
    justify-content: space-between; /* Space out filter/button */
  }
  /* Allow tenant filter to grow */
  .tenant-log-header .tenant-actions .simple-filter,
  .tenant-log-header .tenant-actions .filter {
    /* Target both filter types */
    flex-grow: 1;
  }

  /* Adjust standard filters (Overview/ConvLog) */
  .filters {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
    padding: 0;
  }

  .filter-group {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  /* Standard filter responsive sizing */
  .filter {
    flex-grow: 1;
    min-width: calc(50% - 7.5px);
  }

  /* Stacking filters on very small screens */
  @media (max-width: 540px) {
    .filter-group {
      flex-direction: column;
      gap: 15px;
    }
    .filter {
      min-width: 100%;
    }
    /* Stack tenant actions */
    .tenant-log-header .tenant-actions {
      flex-direction: column;
      align-items: stretch;
    }
    .tenant-log-header .tenant-actions .simple-filter,
    .tenant-log-header .tenant-actions .filter,
    .tenant-log-header .tenant-actions .simple-export-btn {
      width: 100%;
      margin-top: 0; /* Reset margin if stacked */
    }
    /* Add gap between stacked tenant actions */
    .tenant-log-header .tenant-actions > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  /* Adjust export buttons */
  .action-buttons, /* Standard Export Container */
  .tenant-log-header .tenant-actions .simple-export-btn {
    /* Specific Tenant Export Button */
    width: 100%;
    margin-top: 15px; /* Add margin only if it wraps */
  }
  .export-btn, /* Standard Export Button */
   .tenant-log-header .tenant-actions .simple-export-btn {
    justify-content: center;
    width: 100%; /* Ensure full width when wrapped/stacked */
  }
  /* Remove margin-top if actions didn't wrap initially */
  .tenant-log-header:not(:has(.tenant-actions:only-child))
    .tenant-actions
    .simple-export-btn {
    /* More complex logic might be needed here if wrapping behavior is inconsistent */
    /* Assuming if header wraps, button might need margin */
  }

  /* Adjust metrics */
  .metrics-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
  }
  .metric-content h3 {
    font-size: 13px;
  }
  .metric-value {
    font-size: 24px;
  }

  /* Adjust Overview Cards */
  .data-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  /* Adjust Tables */
  .table-container {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
    box-shadow: none;
    margin-top: 20px;
  }
  .log-table th,
  .log-table td {
    padding: 8px 15px;
  }

  /* Adjust Card Headers/List Items */
  .card-header h2 {
    font-size: 16px;
  }
  .list-item {
    font-size: 13px;
  }

  /* Adjust Chat Message Width */
  .chat-message {
    max-width: 90%;
  }
}

/* Even smaller screens adjustments */
@media (max-width: 400px) {
  .metrics-container {
    grid-template-columns: 1fr;
  }
  .metric-value {
    font-size: 22px;
  }
  .percentage {
    font-size: 16px;
  }
  .dashboard {
    padding: 15px;
  }
  /* Adjust table container margins for smaller padding */
  .table-container {
    margin-left: -15px;
    margin-right: -15px;
  }
}
